@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ExtraBold.otf");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: Inter, sans-serif !important;
  height: 100vh;
  background-color: rgb(245, 245, 245) !important;
}

html {
  background-color: #f5f5f5 !important;
}

#app {
  /* height: 100%; */
  background-color: #f5f5f5 !important;
}
