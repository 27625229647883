@import "https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Bungee&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap";
*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 900px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 976px) {
  .container {
    max-width: 976px;
  }
}

@media (min-width: 1080px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1564px) {
  .container {
    max-width: 1564px;
  }
}

#app .pointer-events-none {
  pointer-events: none;
}

#app .visible {
  visibility: visible;
}

#app .fixed {
  position: fixed;
}

#app .absolute {
  position: absolute;
}

#app .relative {
  position: relative;
}

#app .inset-0 {
  inset: 0;
}

#app .bottom-1 {
  bottom: .25rem;
}

#app .right-1 {
  right: .25rem;
}

#app .right-11 {
  right: 2.75rem;
}

#app .top-1 {
  top: .25rem;
}

#app .left-0 {
  left: 0;
}

#app .right-\[\.100rem\] {
  right: .1rem;
}

#app .-top-1 {
  top: -.25rem;
}

#app .-left-1 {
  left: -.25rem;
}

#app .bottom-0 {
  bottom: 0;
}

#app .left-\[10\] {
  left: 10px;
}

#app .top-\[8\] {
  top: 8px;
}

#app .top-2\.5 {
  top: .625rem;
}

#app .top-2 {
  top: .5rem;
}

#app .bottom-3 {
  bottom: .75rem;
}

#app .right-10 {
  right: 2.5rem;
}

#app .top-0 {
  top: 0;
}

#app .right-0 {
  right: 0;
}

#app .left-8 {
  left: 2rem;
}

#app .-right-2 {
  right: -.5rem;
}

#app .-top-2 {
  top: -.5rem;
}

#app .left-1\/2 {
  left: 50%;
}

#app .z-50 {
  z-index: 50;
}

#app .z-10 {
  z-index: 10;
}

#app .order-5 {
  order: 5;
}

#app .row-auto {
  grid-row: auto;
}

#app .row-span-1 {
  grid-row: span 1 / span 1;
}

#app .row-span-2 {
  grid-row: span 2 / span 2;
}

#app .row-span-3 {
  grid-row: span 3 / span 3;
}

#app .row-span-4 {
  grid-row: span 4 / span 4;
}

#app .row-span-5 {
  grid-row: span 5 / span 5;
}

#app .row-span-6 {
  grid-row: span 6 / span 6;
}

#app .row-span-full {
  grid-row: 1 / -1;
}

#app .row-start-1 {
  grid-row-start: 1;
}

#app .row-start-2 {
  grid-row-start: 2;
}

#app .row-start-3 {
  grid-row-start: 3;
}

#app .row-start-4 {
  grid-row-start: 4;
}

#app .row-start-5 {
  grid-row-start: 5;
}

#app .row-start-6 {
  grid-row-start: 6;
}

#app .row-start-7 {
  grid-row-start: 7;
}

#app .row-start-auto {
  grid-row-start: auto;
}

#app .row-end-1 {
  grid-row-end: 1;
}

#app .row-end-2 {
  grid-row-end: 2;
}

#app .row-end-3 {
  grid-row-end: 3;
}

#app .row-end-4 {
  grid-row-end: 4;
}

#app .row-end-5 {
  grid-row-end: 5;
}

#app .row-end-6 {
  grid-row-end: 6;
}

#app .row-end-7 {
  grid-row-end: 7;
}

#app .row-end-auto {
  grid-row-end: auto;
}

#app .float-left {
  float: left;
}

#app .m-auto {
  margin: auto;
}

#app .m-0 {
  margin: 0;
}

#app .m-4 {
  margin: 1rem;
}

#app .m-2 {
  margin: .5rem;
}

#app .m-8 {
  margin: 2rem;
}

#app .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#app .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#app .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

#app .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#app .mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

#app .mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

#app .mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#app .my-\[41px\] {
  margin-top: 41px;
  margin-bottom: 41px;
}

#app .mx-\[13\.7\%\] {
  margin-left: 13.7%;
  margin-right: 13.7%;
}

#app .mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

#app .mx-0 {
  margin-left: 0;
  margin-right: 0;
}

#app .mx-\[5\%\] {
  margin-left: 5%;
  margin-right: 5%;
}

#app .my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

#app .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

#app .mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

#app .my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

#app .mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

#app .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

#app .mt-1 {
  margin-top: .25rem;
}

#app .mr-2 {
  margin-right: .5rem;
}

#app .ml-1 {
  margin-left: .25rem;
}

#app .-ml-1 {
  margin-left: -.25rem;
}

#app .mr-3 {
  margin-right: .75rem;
}

#app .mt-2 {
  margin-top: .5rem;
}

#app .mb-1 {
  margin-bottom: .25rem;
}

#app .mt-\[1rem\] {
  margin-top: 1rem;
}

#app .ml-0 {
  margin-left: 0;
}

#app .mr-5 {
  margin-right: 1.25rem;
}

#app .ml-5 {
  margin-left: 1.25rem;
}

#app .ml-7 {
  margin-left: 1.75rem;
}

#app .ml-4 {
  margin-left: 1rem;
}

#app .mb-4 {
  margin-bottom: 1rem;
}

#app .-mt-2 {
  margin-top: -.5rem;
}

#app .-mr-2 {
  margin-right: -.5rem;
}

#app .mt-\[50px\] {
  margin-top: 50px;
}

#app .mt-3 {
  margin-top: .75rem;
}

#app .mb-2 {
  margin-bottom: .5rem;
}

#app .mt-10 {
  margin-top: 2.5rem;
}

#app .mt-\[19px\] {
  margin-top: 19px;
}

#app .mt-\[5\%\] {
  margin-top: 5%;
}

#app .-mt-\[5\] {
  margin-top: -5px;
}

#app .mb-3 {
  margin-bottom: .75rem;
}

#app .mr-\[5px\] {
  margin-right: 5px;
}

#app .mt-\[30\] {
  margin-top: 30px;
}

#app .mb-\[20\] {
  margin-bottom: 20px;
}

#app .mb-\[30\] {
  margin-bottom: 30px;
}

#app .mb-8 {
  margin-bottom: 2rem;
}

#app .mt-6 {
  margin-top: 1.5rem;
}

#app .mb-6 {
  margin-bottom: 1.5rem;
}

#app .mb-10 {
  margin-bottom: 2.5rem;
}

#app .mr-\[28px\] {
  margin-right: 28px;
}

#app .ml-\[15px\] {
  margin-left: 15px;
}

#app .mt-\[18px\] {
  margin-top: 18px;
}

#app .mt-4 {
  margin-top: 1rem;
}

#app .mb-0 {
  margin-bottom: 0;
}

#app .mt-0 {
  margin-top: 0;
}

#app .mb-\[20px\] {
  margin-bottom: 20px;
}

#app .mb-5 {
  margin-bottom: 1.25rem;
}

#app .mt-5 {
  margin-top: 1.25rem;
}

#app .ml-6 {
  margin-left: 1.5rem;
}

#app .ml-2 {
  margin-left: .5rem;
}

#app .ml-8 {
  margin-left: 2rem;
}

#app .mb-11 {
  margin-bottom: 2.75rem;
}

#app .mb-12 {
  margin-bottom: 3rem;
}

#app .mr-4 {
  margin-right: 1rem;
}

#app .mt-\[35px\] {
  margin-top: 35px;
}

#app .mt-\[32px\] {
  margin-top: 32px;
}

#app .mt-7 {
  margin-top: 1.75rem;
}

#app .mr-\[10\] {
  margin-right: 10px;
}

#app .mt-\[5px\] {
  margin-top: 5px;
}

#app .mt-\[75px\] {
  margin-top: 75px;
}

#app .mt-\[20px\] {
  margin-top: 20px;
}

#app .ml-10 {
  margin-left: 2.5rem;
}

#app .mb-20 {
  margin-bottom: 5rem;
}

#app .mt-\[38px\] {
  margin-top: 38px;
}

#app .mb-\[30px\] {
  margin-bottom: 30px;
}

#app .mt-9 {
  margin-top: 2.25rem;
}

#app .mb-7 {
  margin-bottom: 1.75rem;
}

#app .mb-14 {
  margin-bottom: 3.5rem;
}

#app .mb-\[60px\] {
  margin-bottom: 60px;
}

#app .ml-20 {
  margin-left: 5rem;
}

#app .mb-16 {
  margin-bottom: 4rem;
}

#app .mt-\[16px\] {
  margin-top: 16px;
}

#app .-mt-1 {
  margin-top: -.25rem;
}

#app .-mb-1 {
  margin-bottom: -.25rem;
}

#app .mt-\[12px\] {
  margin-top: 12px;
}

#app .mt-\[65px\] {
  margin-top: 65px;
}

#app .mb-\[50px\] {
  margin-bottom: 50px;
}

#app .mt-\[30px\] {
  margin-top: 30px;
}

#app .mr-\[30\] {
  margin-right: 30px;
}

#app .ml-\[40\] {
  margin-left: 40px;
}

#app .mr-\[40\] {
  margin-right: 40px;
}

#app .ml-\[7\%\] {
  margin-left: 7%;
}

#app .mt-\[7\%\] {
  margin-top: 7%;
}

#app .ml-\[8\%\] {
  margin-left: 8%;
}

#app .ml-3 {
  margin-left: .75rem;
}

#app .ml-\[30\] {
  margin-left: 30px;
}

#app .-mt-\[20\%\] {
  margin-top: -20%;
}

#app .ml-\[62\%\] {
  margin-left: 62%;
}

#app .ml-\[68\%\] {
  margin-left: 68%;
}

#app .ml-\[12px\] {
  margin-left: 12px;
}

#app .block {
  display: block;
}

#app .inline-block {
  display: inline-block;
}

#app .inline {
  display: inline;
}

#app .flex {
  display: flex;
}

#app .inline-flex {
  display: inline-flex;
}

#app .table {
  display: table;
}

#app .table-row-group {
  display: table-row-group;
}

#app .flow-root {
  display: flow-root;
}

#app .grid {
  display: grid;
}

#app .hidden {
  display: none;
}

#app .h-\[20px\] {
  height: 20px;
}

#app .h-4 {
  height: 1rem;
}

#app .h-6 {
  height: 1.5rem;
}

#app .h-5 {
  height: 1.25rem;
}

#app .h-full {
  height: 100%;
}

#app .h-\[190px\] {
  height: 190px;
}

#app .h-32 {
  height: 8rem;
}

#app .h-10 {
  height: 2.5rem;
}

#app .h-1 {
  height: .25rem;
}

#app .h-\[calc\(100\%-64px\)\] {
  height: calc(100% - 64px);
}

#app .h-8 {
  height: 2rem;
}

#app .h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

#app .h-\[35\] {
  height: 35px;
}

#app .h-\[14\] {
  height: 14px;
}

#app .h-12 {
  height: 3rem;
}

#app .h-px {
  height: 1px;
}

#app .h-\[415px\] {
  height: 415px;
}

#app .h-\[38px\] {
  height: 38px;
}

#app .h-\[70px\] {
  height: 70px;
}

#app .h-\[40px\] {
  height: 40px;
}

#app .h-\[400px\] {
  height: 400px;
}

#app .h-max {
  height: max-content;
}

#app .h-40 {
  height: 10rem;
}

#app .h-3 {
  height: .75rem;
}

#app .h-\[55\] {
  height: 55px;
}

#app .h-\[20\] {
  height: 20px;
}

#app .h-\[80\%\] {
  height: 80%;
}

#app .h-\[50px\] {
  height: 50px;
}

#app .h-\[24px\] {
  height: 24px;
}

#app .h-\[100\] {
  height: 100px;
}

#app .h-\[30px\] {
  height: 30px;
}

#app .h-\[300px\] {
  height: 300px;
}

#app .h-\[125\] {
  height: 125px;
}

#app .h-\[180\] {
  height: 180px;
}

#app .h-\[14px\] {
  height: 14px;
}

#app .max-h-\[360px\] {
  max-height: 360px;
}

#app .max-h-\[400px\] {
  max-height: 400px;
}

#app .min-h-\[78px\] {
  min-height: 78px;
}

#app .min-h-\[120\] {
  min-height: 120px;
}

#app .min-h-\[800px\] {
  min-height: 800px;
}

#app .min-h-\[600px\] {
  min-height: 600px;
}

#app .min-h-\[450\] {
  min-height: 450px;
}

#app .min-h-\[300px\] {
  min-height: 300px;
}

#app .min-h-\[66px\] {
  min-height: 66px;
}

#app .w-0 {
  width: 0;
}

#app .w-1 {
  width: .25rem;
}

#app .w-2 {
  width: .5rem;
}

#app .w-3 {
  width: .75rem;
}

#app .w-4 {
  width: 1rem;
}

#app .w-5 {
  width: 1.25rem;
}

#app .w-6 {
  width: 1.5rem;
}

#app .w-7 {
  width: 1.75rem;
}

#app .w-8 {
  width: 2rem;
}

#app .w-9 {
  width: 2.25rem;
}

#app .w-10 {
  width: 2.5rem;
}

#app .w-11 {
  width: 2.75rem;
}

#app .w-12 {
  width: 3rem;
}

#app .w-14 {
  width: 3.5rem;
}

#app .w-16 {
  width: 4rem;
}

#app .w-20 {
  width: 5rem;
}

#app .w-24 {
  width: 6rem;
}

#app .w-28 {
  width: 7rem;
}

#app .w-32 {
  width: 8rem;
}

#app .w-36 {
  width: 9rem;
}

#app .w-40 {
  width: 10rem;
}

#app .w-44 {
  width: 11rem;
}

#app .w-48 {
  width: 12rem;
}

#app .w-52 {
  width: 13rem;
}

#app .w-56 {
  width: 14rem;
}

#app .w-60 {
  width: 15rem;
}

#app .w-64 {
  width: 16rem;
}

#app .w-72 {
  width: 18rem;
}

#app .w-80 {
  width: 20rem;
}

#app .w-96 {
  width: 24rem;
}

#app .w-auto {
  width: auto;
}

#app .w-px {
  width: 1px;
}

#app .w-0\.5 {
  width: .125rem;
}

#app .w-1\.5 {
  width: .375rem;
}

#app .w-2\.5 {
  width: .625rem;
}

#app .w-3\.5 {
  width: .875rem;
}

#app .w-1\/2 {
  width: 50%;
}

#app .w-1\/3 {
  width: 33.3333%;
}

#app .w-2\/3 {
  width: 66.6667%;
}

#app .w-1\/4 {
  width: 25%;
}

#app .w-2\/4 {
  width: 50%;
}

#app .w-3\/4 {
  width: 75%;
}

#app .w-1\/5 {
  width: 20%;
}

#app .w-2\/5 {
  width: 40%;
}

#app .w-3\/5 {
  width: 60%;
}

#app .w-4\/5 {
  width: 80%;
}

#app .w-1\/6 {
  width: 16.6667%;
}

#app .w-2\/6 {
  width: 33.3333%;
}

#app .w-3\/6 {
  width: 50%;
}

#app .w-4\/6 {
  width: 66.6667%;
}

#app .w-5\/6 {
  width: 83.3333%;
}

#app .w-1\/12 {
  width: 8.33333%;
}

#app .w-2\/12 {
  width: 16.6667%;
}

#app .w-3\/12 {
  width: 25%;
}

#app .w-4\/12 {
  width: 33.3333%;
}

#app .w-5\/12 {
  width: 41.6667%;
}

#app .w-6\/12 {
  width: 50%;
}

#app .w-7\/12 {
  width: 58.3333%;
}

#app .w-8\/12 {
  width: 66.6667%;
}

#app .w-9\/12 {
  width: 75%;
}

#app .w-10\/12 {
  width: 83.3333%;
}

#app .w-11\/12 {
  width: 91.6667%;
}

#app .w-full {
  width: 100%;
}

#app .w-screen {
  width: 100vw;
}

#app .w-min {
  width: min-content;
}

#app .w-max {
  width: max-content;
}

#app .w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

#app .w-\[50\%\] {
  width: 50%;
}

#app .w-\[200px\] {
  width: 200px;
}

#app .w-\[20\%\] {
  width: 20%;
}

#app .w-\[378px\] {
  width: 378px;
}

#app .w-\[fit-content\] {
  width: -moz-fit-content;
  width: fit-content;
}

#app .w-\[155\] {
  width: 155px;
}

#app .w-\[14\] {
  width: 14px;
}

#app .w-\[1080\] {
  width: 1080px;
}

#app .w-\[88\%\] {
  width: 88%;
}

#app .w-\[413px\] {
  width: 413px;
}

#app .w-\[309px\] {
  width: 309px;
}

#app .w-\[90\%\] {
  width: 90%;
}

#app .w-\[423px\] {
  width: 423px;
}

#app .w-\[70px\] {
  width: 70px;
}

#app .w-\[100\%\] {
  width: 100%;
}

#app .w-\[55\] {
  width: 55px;
}

#app .w-\[20\] {
  width: 20px;
}

#app .w-\[75\%\] {
  width: 75%;
}

#app .w-\[300px\] {
  width: 300px;
}

#app .w-\[400px\] {
  width: 400px;
}

#app .w-\[380px\] {
  width: 380px;
}

#app .w-\[50px\] {
  width: 50px;
}

#app .w-\[100\] {
  width: 100px;
}

#app .w-\[215\] {
  width: 215px;
}

#app .w-\[130px\] {
  width: 130px;
}

#app .w-\[132px\] {
  width: 132px;
}

#app .w-\[100vw\] {
  width: 100vw;
}

#app .w-\[960\] {
  width: 960px;
}

#app .w-\[95\%\] {
  width: 95%;
}

#app .min-w-0 {
  min-width: 0;
}

#app .min-w-full {
  min-width: 100%;
}

#app .min-w-min {
  min-width: min-content;
}

#app .min-w-max {
  min-width: max-content;
}

#app .min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

#app .min-w-\[100px\] {
  min-width: 100px;
}

#app .min-w-\[120px\] {
  min-width: 120px;
}

#app .min-w-\[1100px\] {
  min-width: 1100px;
}

#app .max-w-0 {
  max-width: 0;
}

#app .max-w-none {
  max-width: none;
}

#app .max-w-xs {
  max-width: 20rem;
}

#app .max-w-sm {
  max-width: 24rem;
}

#app .max-w-md {
  max-width: 28rem;
}

#app .max-w-lg {
  max-width: 32rem;
}

#app .max-w-xl {
  max-width: 36rem;
}

#app .max-w-2xl {
  max-width: 42rem;
}

#app .max-w-3xl {
  max-width: 48rem;
}

#app .max-w-4xl {
  max-width: 56rem;
}

#app .max-w-5xl {
  max-width: 64rem;
}

#app .max-w-6xl {
  max-width: 72rem;
}

#app .max-w-7xl {
  max-width: 80rem;
}

#app .max-w-full {
  max-width: 100%;
}

#app .max-w-min {
  max-width: min-content;
}

#app .max-w-max {
  max-width: max-content;
}

#app .max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

#app .max-w-prose {
  max-width: 65ch;
}

#app .max-w-screen-sm {
  max-width: 480px;
}

#app .max-w-screen-ax {
  max-width: 600px;
}

#app .max-w-screen-md {
  max-width: 768px;
}

#app .max-w-screen-ac {
  max-width: 900px;
}

#app .max-w-screen-lg {
  max-width: 976px;
}

#app .max-w-screen-od {
  max-width: 1080px;
}

#app .max-w-screen-xl {
  max-width: 1440px;
}

#app .max-w-screen-xxl, #app .max-w-screen-xxlMax {
  max-width: 1564px;
}

#app .max-w-\[250px\] {
  max-width: 250px;
}

#app .max-w-\[200px\] {
  max-width: 200px;
}

#app .max-w-\[320px\] {
  max-width: 320px;
}

#app .max-w-\[75\%\] {
  max-width: 75%;
}

#app .max-w-\[378px\] {
  max-width: 378px;
}

#app .max-w-\[528\] {
  max-width: 528px;
}

#app .max-w-\[400px\] {
  max-width: 400px;
}

#app .flex-1 {
  flex: 1;
}

#app .flex-grow-0, #app .grow-0 {
  flex-grow: 0;
}

#app .grow {
  flex-grow: 1;
}

#app .-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-rotate-6 {
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-0 {
  --tw-skew-x: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-1 {
  --tw-skew-x: 1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-2 {
  --tw-skew-x: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-3 {
  --tw-skew-x: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-6 {
  --tw-skew-x: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-x-12 {
  --tw-skew-x: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-0 {
  --tw-skew-x: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-1 {
  --tw-skew-x: -1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-2 {
  --tw-skew-x: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-3 {
  --tw-skew-x: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-6 {
  --tw-skew-x: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-x-12 {
  --tw-skew-x: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-0 {
  --tw-skew-y: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-1 {
  --tw-skew-y: 1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-2 {
  --tw-skew-y: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-3 {
  --tw-skew-y: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-6 {
  --tw-skew-y: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .skew-y-12 {
  --tw-skew-y: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-0 {
  --tw-skew-y: -0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-1 {
  --tw-skew-y: -1deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-2 {
  --tw-skew-y: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-3 {
  --tw-skew-y: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-6 {
  --tw-skew-y: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .-skew-y-12 {
  --tw-skew-y: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .transform-none {
  transform: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#app .animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

#app .animate-ping {
  animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
}

#app .cursor-row-resize {
  cursor: row-resize;
}

#app .cursor-w-resize {
  cursor: w-resize;
}

#app .cursor-nw-resize {
  cursor: nw-resize;
}

#app .cursor-sw-resize {
  cursor: sw-resize;
}

#app .cursor-ew-resize {
  cursor: ew-resize;
}

#app .cursor-nesw-resize {
  cursor: nesw-resize;
}

#app .cursor-pointer {
  cursor: pointer;
}

#app .cursor-not-allowed {
  cursor: not-allowed;
}

#app .select-none {
  -webkit-user-select: none;
  user-select: none;
}

#app .appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

#app .grid-flow-row {
  grid-auto-flow: row;
}

#app .grid-flow-col {
  grid-auto-flow: column;
}

#app .grid-flow-dense {
  grid-auto-flow: dense;
}

#app .grid-flow-row-dense {
  grid-auto-flow: row dense;
}

#app .grid-flow-col-dense {
  grid-auto-flow: column dense;
}

#app .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

#app .grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

#app .flex-row {
  flex-direction: row;
}

#app .flex-row-reverse {
  flex-direction: row-reverse;
}

#app .flex-col {
  flex-direction: column;
}

#app .flex-col-reverse {
  flex-direction: column-reverse;
}

#app .flex-wrap {
  flex-wrap: wrap;
}

#app .place-items-center {
  place-items: center;
}

#app .content-center {
  align-content: center;
}

#app .items-start {
  align-items: flex-start;
}

#app .items-end {
  align-items: flex-end;
}

#app .items-center {
  align-items: center;
}

#app .justify-start {
  justify-content: flex-start;
}

#app .justify-end {
  justify-content: flex-end;
}

#app .justify-center {
  justify-content: center;
}

#app .justify-between {
  justify-content: space-between;
}

#app .justify-evenly {
  justify-content: space-evenly;
}

#app .justify-items-start {
  justify-items: start;
}

#app .justify-items-center {
  justify-items: center;
}

#app .gap-5 {
  gap: 1.25rem;
}

#app .gap-1 {
  gap: .25rem;
}

#app .gap-3 {
  gap: .75rem;
}

#app .gap-4 {
  gap: 1rem;
}

#app .gap-2 {
  gap: .5rem;
}

#app .gap-\[5\] {
  gap: 5px;
}

#app .gap-6 {
  gap: 1.5rem;
}

#app .gap-\[4px\] {
  gap: 4px;
}

#app .gap-x-3 {
  column-gap: .75rem;
}

#app .gap-y-2 {
  row-gap: .5rem;
}

#app .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

#app .space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

#app .space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

#app .space-y-\[35px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(35px * var(--tw-space-y-reverse));
}

#app .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

#app .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

#app .divide-success > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(0 163 82 / var(--tw-divide-opacity));
}

#app .divide-successBackground > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(234 250 255 / var(--tw-divide-opacity));
}

#app .divide-error > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(234 59 45 / var(--tw-divide-opacity));
}

#app .divide-errorBackground > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(255 208 205 / var(--tw-divide-opacity));
}

#app .divide-info > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(46 199 255 / var(--tw-divide-opacity));
}

#app .divide-infoBackground > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(234 250 255 / var(--tw-divide-opacity));
}

#app .divide-success\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35200;
}

#app .divide-success\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a3520d;
}

#app .divide-success\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a3521a;
}

#app .divide-success\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35233;
}

#app .divide-success\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35240;
}

#app .divide-success\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a3524d;
}

#app .divide-success\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35266;
}

#app .divide-success\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35280;
}

#app .divide-success\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a35299;
}

#app .divide-success\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352b3;
}

#app .divide-success\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352bf;
}

#app .divide-success\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352cc;
}

#app .divide-success\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352e6;
}

#app .divide-success\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352f2;
}

#app .divide-success\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #00a352;
}

#app .divide-successBackground\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff00;
}

#app .divide-successBackground\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff0d;
}

#app .divide-successBackground\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff1a;
}

#app .divide-successBackground\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff33;
}

#app .divide-successBackground\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff40;
}

#app .divide-successBackground\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff4d;
}

#app .divide-successBackground\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff66;
}

#app .divide-successBackground\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff80;
}

#app .divide-successBackground\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff99;
}

#app .divide-successBackground\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffb3;
}

#app .divide-successBackground\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffbf;
}

#app .divide-successBackground\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffcc;
}

#app .divide-successBackground\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffe6;
}

#app .divide-successBackground\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafafff2;
}

#app .divide-successBackground\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff;
}

#app .divide-error\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d00;
}

#app .divide-error\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d0d;
}

#app .divide-error\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d1a;
}

#app .divide-error\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d33;
}

#app .divide-error\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d40;
}

#app .divide-error\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d4d;
}

#app .divide-error\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d66;
}

#app .divide-error\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d80;
}

#app .divide-error\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d99;
}

#app .divide-error\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2db3;
}

#app .divide-error\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2dbf;
}

#app .divide-error\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2dcc;
}

#app .divide-error\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2de6;
}

#app .divide-error\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2df2;
}

#app .divide-error\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ea3b2d;
}

#app .divide-errorBackground\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd00;
}

#app .divide-errorBackground\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd0d;
}

#app .divide-errorBackground\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd1a;
}

#app .divide-errorBackground\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd33;
}

#app .divide-errorBackground\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd40;
}

#app .divide-errorBackground\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd4d;
}

#app .divide-errorBackground\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd66;
}

#app .divide-errorBackground\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd80;
}

#app .divide-errorBackground\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd99;
}

#app .divide-errorBackground\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cdb3;
}

#app .divide-errorBackground\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cdbf;
}

#app .divide-errorBackground\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cdcc;
}

#app .divide-errorBackground\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cde6;
}

#app .divide-errorBackground\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cdf2;
}

#app .divide-errorBackground\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #ffd0cd;
}

#app .divide-info\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff00;
}

#app .divide-info\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff0d;
}

#app .divide-info\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff1a;
}

#app .divide-info\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff33;
}

#app .divide-info\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff40;
}

#app .divide-info\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff4d;
}

#app .divide-info\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff66;
}

#app .divide-info\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff80;
}

#app .divide-info\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff99;
}

#app .divide-info\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ffb3;
}

#app .divide-info\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ffbf;
}

#app .divide-info\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ffcc;
}

#app .divide-info\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ffe6;
}

#app .divide-info\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7fff2;
}

#app .divide-info\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #2ec7ff;
}

#app .divide-infoBackground\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff00;
}

#app .divide-infoBackground\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff0d;
}

#app .divide-infoBackground\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff1a;
}

#app .divide-infoBackground\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff33;
}

#app .divide-infoBackground\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff40;
}

#app .divide-infoBackground\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff4d;
}

#app .divide-infoBackground\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff66;
}

#app .divide-infoBackground\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff80;
}

#app .divide-infoBackground\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff99;
}

#app .divide-infoBackground\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffb3;
}

#app .divide-infoBackground\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffbf;
}

#app .divide-infoBackground\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffcc;
}

#app .divide-infoBackground\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaffe6;
}

#app .divide-infoBackground\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafafff2;
}

#app .divide-infoBackground\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: #eafaff;
}

#app .place-self-center {
  place-self: center;
}

#app .self-start {
  align-self: flex-start;
}

#app .self-end {
  align-self: flex-end;
}

#app .self-center {
  align-self: center;
}

#app .overflow-auto {
  overflow: auto;
}

#app .overflow-hidden {
  overflow: hidden;
}

#app .overflow-clip {
  overflow: clip;
}

#app .overflow-visible {
  overflow: visible;
}

#app .overflow-scroll {
  overflow: scroll;
}

#app .overflow-x-auto {
  overflow-x: auto;
}

#app .overflow-y-auto {
  overflow-y: auto;
}

#app .overflow-x-hidden {
  overflow-x: hidden;
}

#app .overflow-y-hidden {
  overflow-y: hidden;
}

#app .overflow-x-clip {
  overflow-x: clip;
}

#app .overflow-y-clip {
  overflow-y: clip;
}

#app .overflow-x-visible {
  overflow-x: visible;
}

#app .overflow-y-visible {
  overflow-y: visible;
}

#app .overflow-x-scroll {
  overflow-x: scroll;
}

#app .overflow-y-scroll {
  overflow-y: scroll;
}

#app .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#app .overflow-ellipsis, #app .text-ellipsis {
  text-overflow: ellipsis;
}

#app .whitespace-normal {
  white-space: normal;
}

#app .whitespace-nowrap {
  white-space: nowrap;
}

#app .whitespace-pre-line {
  white-space: pre-line;
}

#app .break-all {
  word-break: break-all;
}

#app .rounded-full {
  border-radius: 9999px;
}

#app .rounded-md {
  border-radius: .375rem;
}

#app .rounded {
  border-radius: .25rem;
}

#app .rounded-lg {
  border-radius: .5rem;
}

#app .rounded-xl {
  border-radius: .75rem;
}

#app .rounded-\[5px\] {
  border-radius: 5px;
}

#app .rounded-\[10px\], #app .rounded-\[10\] {
  border-radius: 10px;
}

#app .rounded-\[50\%\] {
  border-radius: 50%;
}

#app .rounded-3xl {
  border-radius: 1.5rem;
}

#app .rounded-\[50px\] {
  border-radius: 50px;
}

#app .rounded-\[8px\] {
  border-radius: 8px;
}

#app .rounded-b-\[5px\] {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#app .rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

#app .rounded-tr-xl {
  border-top-right-radius: .75rem;
}

#app .border {
  border-width: 1px;
}

#app .border-2 {
  border-width: 2px;
}

#app .border-0 {
  border-width: 0;
}

#app .border-l-4 {
  border-left-width: 4px;
}

#app .border-b-2 {
  border-bottom-width: 2px;
}

#app .border-t-\[1px\] {
  border-top-width: 1px;
}

#app .border-solid {
  border-style: solid;
}

#app .border-dashed {
  border-style: dashed;
}

#app .border-none {
  border-style: none;
}

#app .border-success {
  --tw-border-opacity: 1;
  border-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-successBackground {
  --tw-border-opacity: 1;
  border-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-error {
  --tw-border-opacity: 1;
  border-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-errorBackground {
  --tw-border-opacity: 1;
  border-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-info {
  --tw-border-opacity: 1;
  border-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-infoBackground {
  --tw-border-opacity: 1;
  border-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-success\/0 {
  border-color: #00a35200;
}

#app .border-success\/5 {
  border-color: #00a3520d;
}

#app .border-success\/10 {
  border-color: #00a3521a;
}

#app .border-success\/20 {
  border-color: #00a35233;
}

#app .border-success\/25 {
  border-color: #00a35240;
}

#app .border-success\/30 {
  border-color: #00a3524d;
}

#app .border-success\/40 {
  border-color: #00a35266;
}

#app .border-success\/50 {
  border-color: #00a35280;
}

#app .border-success\/60 {
  border-color: #00a35299;
}

#app .border-success\/70 {
  border-color: #00a352b3;
}

#app .border-success\/75 {
  border-color: #00a352bf;
}

#app .border-success\/80 {
  border-color: #00a352cc;
}

#app .border-success\/90 {
  border-color: #00a352e6;
}

#app .border-success\/95 {
  border-color: #00a352f2;
}

#app .border-success\/100 {
  border-color: #00a352;
}

#app .border-successBackground\/0 {
  border-color: #eafaff00;
}

#app .border-successBackground\/5 {
  border-color: #eafaff0d;
}

#app .border-successBackground\/10 {
  border-color: #eafaff1a;
}

#app .border-successBackground\/20 {
  border-color: #eafaff33;
}

#app .border-successBackground\/25 {
  border-color: #eafaff40;
}

#app .border-successBackground\/30 {
  border-color: #eafaff4d;
}

#app .border-successBackground\/40 {
  border-color: #eafaff66;
}

#app .border-successBackground\/50 {
  border-color: #eafaff80;
}

#app .border-successBackground\/60 {
  border-color: #eafaff99;
}

#app .border-successBackground\/70 {
  border-color: #eafaffb3;
}

#app .border-successBackground\/75 {
  border-color: #eafaffbf;
}

#app .border-successBackground\/80 {
  border-color: #eafaffcc;
}

#app .border-successBackground\/90 {
  border-color: #eafaffe6;
}

#app .border-successBackground\/95 {
  border-color: #eafafff2;
}

#app .border-successBackground\/100 {
  border-color: #eafaff;
}

#app .border-error\/0 {
  border-color: #ea3b2d00;
}

#app .border-error\/5 {
  border-color: #ea3b2d0d;
}

#app .border-error\/10 {
  border-color: #ea3b2d1a;
}

#app .border-error\/20 {
  border-color: #ea3b2d33;
}

#app .border-error\/25 {
  border-color: #ea3b2d40;
}

#app .border-error\/30 {
  border-color: #ea3b2d4d;
}

#app .border-error\/40 {
  border-color: #ea3b2d66;
}

#app .border-error\/50 {
  border-color: #ea3b2d80;
}

#app .border-error\/60 {
  border-color: #ea3b2d99;
}

#app .border-error\/70 {
  border-color: #ea3b2db3;
}

#app .border-error\/75 {
  border-color: #ea3b2dbf;
}

#app .border-error\/80 {
  border-color: #ea3b2dcc;
}

#app .border-error\/90 {
  border-color: #ea3b2de6;
}

#app .border-error\/95 {
  border-color: #ea3b2df2;
}

#app .border-error\/100 {
  border-color: #ea3b2d;
}

#app .border-errorBackground\/0 {
  border-color: #ffd0cd00;
}

#app .border-errorBackground\/5 {
  border-color: #ffd0cd0d;
}

#app .border-errorBackground\/10 {
  border-color: #ffd0cd1a;
}

#app .border-errorBackground\/20 {
  border-color: #ffd0cd33;
}

#app .border-errorBackground\/25 {
  border-color: #ffd0cd40;
}

#app .border-errorBackground\/30 {
  border-color: #ffd0cd4d;
}

#app .border-errorBackground\/40 {
  border-color: #ffd0cd66;
}

#app .border-errorBackground\/50 {
  border-color: #ffd0cd80;
}

#app .border-errorBackground\/60 {
  border-color: #ffd0cd99;
}

#app .border-errorBackground\/70 {
  border-color: #ffd0cdb3;
}

#app .border-errorBackground\/75 {
  border-color: #ffd0cdbf;
}

#app .border-errorBackground\/80 {
  border-color: #ffd0cdcc;
}

#app .border-errorBackground\/90 {
  border-color: #ffd0cde6;
}

#app .border-errorBackground\/95 {
  border-color: #ffd0cdf2;
}

#app .border-errorBackground\/100 {
  border-color: #ffd0cd;
}

#app .border-info\/0 {
  border-color: #2ec7ff00;
}

#app .border-info\/5 {
  border-color: #2ec7ff0d;
}

#app .border-info\/10 {
  border-color: #2ec7ff1a;
}

#app .border-info\/20 {
  border-color: #2ec7ff33;
}

#app .border-info\/25 {
  border-color: #2ec7ff40;
}

#app .border-info\/30 {
  border-color: #2ec7ff4d;
}

#app .border-info\/40 {
  border-color: #2ec7ff66;
}

#app .border-info\/50 {
  border-color: #2ec7ff80;
}

#app .border-info\/60 {
  border-color: #2ec7ff99;
}

#app .border-info\/70 {
  border-color: #2ec7ffb3;
}

#app .border-info\/75 {
  border-color: #2ec7ffbf;
}

#app .border-info\/80 {
  border-color: #2ec7ffcc;
}

#app .border-info\/90 {
  border-color: #2ec7ffe6;
}

#app .border-info\/95 {
  border-color: #2ec7fff2;
}

#app .border-info\/100 {
  border-color: #2ec7ff;
}

#app .border-infoBackground\/0 {
  border-color: #eafaff00;
}

#app .border-infoBackground\/5 {
  border-color: #eafaff0d;
}

#app .border-infoBackground\/10 {
  border-color: #eafaff1a;
}

#app .border-infoBackground\/20 {
  border-color: #eafaff33;
}

#app .border-infoBackground\/25 {
  border-color: #eafaff40;
}

#app .border-infoBackground\/30 {
  border-color: #eafaff4d;
}

#app .border-infoBackground\/40 {
  border-color: #eafaff66;
}

#app .border-infoBackground\/50 {
  border-color: #eafaff80;
}

#app .border-infoBackground\/60 {
  border-color: #eafaff99;
}

#app .border-infoBackground\/70 {
  border-color: #eafaffb3;
}

#app .border-infoBackground\/75 {
  border-color: #eafaffbf;
}

#app .border-infoBackground\/80 {
  border-color: #eafaffcc;
}

#app .border-infoBackground\/90 {
  border-color: #eafaffe6;
}

#app .border-infoBackground\/95 {
  border-color: #eafafff2;
}

#app .border-infoBackground\/100 {
  border-color: #eafaff;
}

#app .border-\[transparent\] {
  border-color: #0000;
}

#app .border-adminAssistantBorder {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

#app .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

#app .border-buttonBorder {
  --tw-border-opacity: 1;
  border-color: rgb(128 106 210 / var(--tw-border-opacity));
}

#app .border-onboardingButtonColor {
  --tw-border-opacity: 1;
  border-color: rgb(224 132 141 / var(--tw-border-opacity));
}

#app .border-x-success {
  --tw-border-opacity: 1;
  border-left-color: rgb(0 163 82 / var(--tw-border-opacity));
  border-right-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-x-successBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 250 255 / var(--tw-border-opacity));
  border-right-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-x-error {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 59 45 / var(--tw-border-opacity));
  border-right-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-x-errorBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 208 205 / var(--tw-border-opacity));
  border-right-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-x-info {
  --tw-border-opacity: 1;
  border-left-color: rgb(46 199 255 / var(--tw-border-opacity));
  border-right-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-x-infoBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 250 255 / var(--tw-border-opacity));
  border-right-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-x-success\/0 {
  border-left-color: #00a35200;
  border-right-color: #00a35200;
}

#app .border-x-success\/5 {
  border-left-color: #00a3520d;
  border-right-color: #00a3520d;
}

#app .border-x-success\/10 {
  border-left-color: #00a3521a;
  border-right-color: #00a3521a;
}

#app .border-x-success\/20 {
  border-left-color: #00a35233;
  border-right-color: #00a35233;
}

#app .border-x-success\/25 {
  border-left-color: #00a35240;
  border-right-color: #00a35240;
}

#app .border-x-success\/30 {
  border-left-color: #00a3524d;
  border-right-color: #00a3524d;
}

#app .border-x-success\/40 {
  border-left-color: #00a35266;
  border-right-color: #00a35266;
}

#app .border-x-success\/50 {
  border-left-color: #00a35280;
  border-right-color: #00a35280;
}

#app .border-x-success\/60 {
  border-left-color: #00a35299;
  border-right-color: #00a35299;
}

#app .border-x-success\/70 {
  border-left-color: #00a352b3;
  border-right-color: #00a352b3;
}

#app .border-x-success\/75 {
  border-left-color: #00a352bf;
  border-right-color: #00a352bf;
}

#app .border-x-success\/80 {
  border-left-color: #00a352cc;
  border-right-color: #00a352cc;
}

#app .border-x-success\/90 {
  border-left-color: #00a352e6;
  border-right-color: #00a352e6;
}

#app .border-x-success\/95 {
  border-left-color: #00a352f2;
  border-right-color: #00a352f2;
}

#app .border-x-success\/100 {
  border-left-color: #00a352;
  border-right-color: #00a352;
}

#app .border-x-successBackground\/0 {
  border-left-color: #eafaff00;
  border-right-color: #eafaff00;
}

#app .border-x-successBackground\/5 {
  border-left-color: #eafaff0d;
  border-right-color: #eafaff0d;
}

#app .border-x-successBackground\/10 {
  border-left-color: #eafaff1a;
  border-right-color: #eafaff1a;
}

#app .border-x-successBackground\/20 {
  border-left-color: #eafaff33;
  border-right-color: #eafaff33;
}

#app .border-x-successBackground\/25 {
  border-left-color: #eafaff40;
  border-right-color: #eafaff40;
}

#app .border-x-successBackground\/30 {
  border-left-color: #eafaff4d;
  border-right-color: #eafaff4d;
}

#app .border-x-successBackground\/40 {
  border-left-color: #eafaff66;
  border-right-color: #eafaff66;
}

#app .border-x-successBackground\/50 {
  border-left-color: #eafaff80;
  border-right-color: #eafaff80;
}

#app .border-x-successBackground\/60 {
  border-left-color: #eafaff99;
  border-right-color: #eafaff99;
}

#app .border-x-successBackground\/70 {
  border-left-color: #eafaffb3;
  border-right-color: #eafaffb3;
}

#app .border-x-successBackground\/75 {
  border-left-color: #eafaffbf;
  border-right-color: #eafaffbf;
}

#app .border-x-successBackground\/80 {
  border-left-color: #eafaffcc;
  border-right-color: #eafaffcc;
}

#app .border-x-successBackground\/90 {
  border-left-color: #eafaffe6;
  border-right-color: #eafaffe6;
}

#app .border-x-successBackground\/95 {
  border-left-color: #eafafff2;
  border-right-color: #eafafff2;
}

#app .border-x-successBackground\/100 {
  border-left-color: #eafaff;
  border-right-color: #eafaff;
}

#app .border-x-error\/0 {
  border-left-color: #ea3b2d00;
  border-right-color: #ea3b2d00;
}

#app .border-x-error\/5 {
  border-left-color: #ea3b2d0d;
  border-right-color: #ea3b2d0d;
}

#app .border-x-error\/10 {
  border-left-color: #ea3b2d1a;
  border-right-color: #ea3b2d1a;
}

#app .border-x-error\/20 {
  border-left-color: #ea3b2d33;
  border-right-color: #ea3b2d33;
}

#app .border-x-error\/25 {
  border-left-color: #ea3b2d40;
  border-right-color: #ea3b2d40;
}

#app .border-x-error\/30 {
  border-left-color: #ea3b2d4d;
  border-right-color: #ea3b2d4d;
}

#app .border-x-error\/40 {
  border-left-color: #ea3b2d66;
  border-right-color: #ea3b2d66;
}

#app .border-x-error\/50 {
  border-left-color: #ea3b2d80;
  border-right-color: #ea3b2d80;
}

#app .border-x-error\/60 {
  border-left-color: #ea3b2d99;
  border-right-color: #ea3b2d99;
}

#app .border-x-error\/70 {
  border-left-color: #ea3b2db3;
  border-right-color: #ea3b2db3;
}

#app .border-x-error\/75 {
  border-left-color: #ea3b2dbf;
  border-right-color: #ea3b2dbf;
}

#app .border-x-error\/80 {
  border-left-color: #ea3b2dcc;
  border-right-color: #ea3b2dcc;
}

#app .border-x-error\/90 {
  border-left-color: #ea3b2de6;
  border-right-color: #ea3b2de6;
}

#app .border-x-error\/95 {
  border-left-color: #ea3b2df2;
  border-right-color: #ea3b2df2;
}

#app .border-x-error\/100 {
  border-left-color: #ea3b2d;
  border-right-color: #ea3b2d;
}

#app .border-x-errorBackground\/0 {
  border-left-color: #ffd0cd00;
  border-right-color: #ffd0cd00;
}

#app .border-x-errorBackground\/5 {
  border-left-color: #ffd0cd0d;
  border-right-color: #ffd0cd0d;
}

#app .border-x-errorBackground\/10 {
  border-left-color: #ffd0cd1a;
  border-right-color: #ffd0cd1a;
}

#app .border-x-errorBackground\/20 {
  border-left-color: #ffd0cd33;
  border-right-color: #ffd0cd33;
}

#app .border-x-errorBackground\/25 {
  border-left-color: #ffd0cd40;
  border-right-color: #ffd0cd40;
}

#app .border-x-errorBackground\/30 {
  border-left-color: #ffd0cd4d;
  border-right-color: #ffd0cd4d;
}

#app .border-x-errorBackground\/40 {
  border-left-color: #ffd0cd66;
  border-right-color: #ffd0cd66;
}

#app .border-x-errorBackground\/50 {
  border-left-color: #ffd0cd80;
  border-right-color: #ffd0cd80;
}

#app .border-x-errorBackground\/60 {
  border-left-color: #ffd0cd99;
  border-right-color: #ffd0cd99;
}

#app .border-x-errorBackground\/70 {
  border-left-color: #ffd0cdb3;
  border-right-color: #ffd0cdb3;
}

#app .border-x-errorBackground\/75 {
  border-left-color: #ffd0cdbf;
  border-right-color: #ffd0cdbf;
}

#app .border-x-errorBackground\/80 {
  border-left-color: #ffd0cdcc;
  border-right-color: #ffd0cdcc;
}

#app .border-x-errorBackground\/90 {
  border-left-color: #ffd0cde6;
  border-right-color: #ffd0cde6;
}

#app .border-x-errorBackground\/95 {
  border-left-color: #ffd0cdf2;
  border-right-color: #ffd0cdf2;
}

#app .border-x-errorBackground\/100 {
  border-left-color: #ffd0cd;
  border-right-color: #ffd0cd;
}

#app .border-x-info\/0 {
  border-left-color: #2ec7ff00;
  border-right-color: #2ec7ff00;
}

#app .border-x-info\/5 {
  border-left-color: #2ec7ff0d;
  border-right-color: #2ec7ff0d;
}

#app .border-x-info\/10 {
  border-left-color: #2ec7ff1a;
  border-right-color: #2ec7ff1a;
}

#app .border-x-info\/20 {
  border-left-color: #2ec7ff33;
  border-right-color: #2ec7ff33;
}

#app .border-x-info\/25 {
  border-left-color: #2ec7ff40;
  border-right-color: #2ec7ff40;
}

#app .border-x-info\/30 {
  border-left-color: #2ec7ff4d;
  border-right-color: #2ec7ff4d;
}

#app .border-x-info\/40 {
  border-left-color: #2ec7ff66;
  border-right-color: #2ec7ff66;
}

#app .border-x-info\/50 {
  border-left-color: #2ec7ff80;
  border-right-color: #2ec7ff80;
}

#app .border-x-info\/60 {
  border-left-color: #2ec7ff99;
  border-right-color: #2ec7ff99;
}

#app .border-x-info\/70 {
  border-left-color: #2ec7ffb3;
  border-right-color: #2ec7ffb3;
}

#app .border-x-info\/75 {
  border-left-color: #2ec7ffbf;
  border-right-color: #2ec7ffbf;
}

#app .border-x-info\/80 {
  border-left-color: #2ec7ffcc;
  border-right-color: #2ec7ffcc;
}

#app .border-x-info\/90 {
  border-left-color: #2ec7ffe6;
  border-right-color: #2ec7ffe6;
}

#app .border-x-info\/95 {
  border-left-color: #2ec7fff2;
  border-right-color: #2ec7fff2;
}

#app .border-x-info\/100 {
  border-left-color: #2ec7ff;
  border-right-color: #2ec7ff;
}

#app .border-x-infoBackground\/0 {
  border-left-color: #eafaff00;
  border-right-color: #eafaff00;
}

#app .border-x-infoBackground\/5 {
  border-left-color: #eafaff0d;
  border-right-color: #eafaff0d;
}

#app .border-x-infoBackground\/10 {
  border-left-color: #eafaff1a;
  border-right-color: #eafaff1a;
}

#app .border-x-infoBackground\/20 {
  border-left-color: #eafaff33;
  border-right-color: #eafaff33;
}

#app .border-x-infoBackground\/25 {
  border-left-color: #eafaff40;
  border-right-color: #eafaff40;
}

#app .border-x-infoBackground\/30 {
  border-left-color: #eafaff4d;
  border-right-color: #eafaff4d;
}

#app .border-x-infoBackground\/40 {
  border-left-color: #eafaff66;
  border-right-color: #eafaff66;
}

#app .border-x-infoBackground\/50 {
  border-left-color: #eafaff80;
  border-right-color: #eafaff80;
}

#app .border-x-infoBackground\/60 {
  border-left-color: #eafaff99;
  border-right-color: #eafaff99;
}

#app .border-x-infoBackground\/70 {
  border-left-color: #eafaffb3;
  border-right-color: #eafaffb3;
}

#app .border-x-infoBackground\/75 {
  border-left-color: #eafaffbf;
  border-right-color: #eafaffbf;
}

#app .border-x-infoBackground\/80 {
  border-left-color: #eafaffcc;
  border-right-color: #eafaffcc;
}

#app .border-x-infoBackground\/90 {
  border-left-color: #eafaffe6;
  border-right-color: #eafaffe6;
}

#app .border-x-infoBackground\/95 {
  border-left-color: #eafafff2;
  border-right-color: #eafafff2;
}

#app .border-x-infoBackground\/100 {
  border-left-color: #eafaff;
  border-right-color: #eafaff;
}

#app .border-y-success {
  --tw-border-opacity: 1;
  border-top-color: rgb(0 163 82 / var(--tw-border-opacity));
  border-bottom-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-y-successBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 250 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-y-error {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 59 45 / var(--tw-border-opacity));
  border-bottom-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-y-errorBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 208 205 / var(--tw-border-opacity));
  border-bottom-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-y-info {
  --tw-border-opacity: 1;
  border-top-color: rgb(46 199 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-y-infoBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 250 255 / var(--tw-border-opacity));
  border-bottom-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-y-success\/0 {
  border-top-color: #00a35200;
  border-bottom-color: #00a35200;
}

#app .border-y-success\/5 {
  border-top-color: #00a3520d;
  border-bottom-color: #00a3520d;
}

#app .border-y-success\/10 {
  border-top-color: #00a3521a;
  border-bottom-color: #00a3521a;
}

#app .border-y-success\/20 {
  border-top-color: #00a35233;
  border-bottom-color: #00a35233;
}

#app .border-y-success\/25 {
  border-top-color: #00a35240;
  border-bottom-color: #00a35240;
}

#app .border-y-success\/30 {
  border-top-color: #00a3524d;
  border-bottom-color: #00a3524d;
}

#app .border-y-success\/40 {
  border-top-color: #00a35266;
  border-bottom-color: #00a35266;
}

#app .border-y-success\/50 {
  border-top-color: #00a35280;
  border-bottom-color: #00a35280;
}

#app .border-y-success\/60 {
  border-top-color: #00a35299;
  border-bottom-color: #00a35299;
}

#app .border-y-success\/70 {
  border-top-color: #00a352b3;
  border-bottom-color: #00a352b3;
}

#app .border-y-success\/75 {
  border-top-color: #00a352bf;
  border-bottom-color: #00a352bf;
}

#app .border-y-success\/80 {
  border-top-color: #00a352cc;
  border-bottom-color: #00a352cc;
}

#app .border-y-success\/90 {
  border-top-color: #00a352e6;
  border-bottom-color: #00a352e6;
}

#app .border-y-success\/95 {
  border-top-color: #00a352f2;
  border-bottom-color: #00a352f2;
}

#app .border-y-success\/100 {
  border-top-color: #00a352;
  border-bottom-color: #00a352;
}

#app .border-y-successBackground\/0 {
  border-top-color: #eafaff00;
  border-bottom-color: #eafaff00;
}

#app .border-y-successBackground\/5 {
  border-top-color: #eafaff0d;
  border-bottom-color: #eafaff0d;
}

#app .border-y-successBackground\/10 {
  border-top-color: #eafaff1a;
  border-bottom-color: #eafaff1a;
}

#app .border-y-successBackground\/20 {
  border-top-color: #eafaff33;
  border-bottom-color: #eafaff33;
}

#app .border-y-successBackground\/25 {
  border-top-color: #eafaff40;
  border-bottom-color: #eafaff40;
}

#app .border-y-successBackground\/30 {
  border-top-color: #eafaff4d;
  border-bottom-color: #eafaff4d;
}

#app .border-y-successBackground\/40 {
  border-top-color: #eafaff66;
  border-bottom-color: #eafaff66;
}

#app .border-y-successBackground\/50 {
  border-top-color: #eafaff80;
  border-bottom-color: #eafaff80;
}

#app .border-y-successBackground\/60 {
  border-top-color: #eafaff99;
  border-bottom-color: #eafaff99;
}

#app .border-y-successBackground\/70 {
  border-top-color: #eafaffb3;
  border-bottom-color: #eafaffb3;
}

#app .border-y-successBackground\/75 {
  border-top-color: #eafaffbf;
  border-bottom-color: #eafaffbf;
}

#app .border-y-successBackground\/80 {
  border-top-color: #eafaffcc;
  border-bottom-color: #eafaffcc;
}

#app .border-y-successBackground\/90 {
  border-top-color: #eafaffe6;
  border-bottom-color: #eafaffe6;
}

#app .border-y-successBackground\/95 {
  border-top-color: #eafafff2;
  border-bottom-color: #eafafff2;
}

#app .border-y-successBackground\/100 {
  border-top-color: #eafaff;
  border-bottom-color: #eafaff;
}

#app .border-y-error\/0 {
  border-top-color: #ea3b2d00;
  border-bottom-color: #ea3b2d00;
}

#app .border-y-error\/5 {
  border-top-color: #ea3b2d0d;
  border-bottom-color: #ea3b2d0d;
}

#app .border-y-error\/10 {
  border-top-color: #ea3b2d1a;
  border-bottom-color: #ea3b2d1a;
}

#app .border-y-error\/20 {
  border-top-color: #ea3b2d33;
  border-bottom-color: #ea3b2d33;
}

#app .border-y-error\/25 {
  border-top-color: #ea3b2d40;
  border-bottom-color: #ea3b2d40;
}

#app .border-y-error\/30 {
  border-top-color: #ea3b2d4d;
  border-bottom-color: #ea3b2d4d;
}

#app .border-y-error\/40 {
  border-top-color: #ea3b2d66;
  border-bottom-color: #ea3b2d66;
}

#app .border-y-error\/50 {
  border-top-color: #ea3b2d80;
  border-bottom-color: #ea3b2d80;
}

#app .border-y-error\/60 {
  border-top-color: #ea3b2d99;
  border-bottom-color: #ea3b2d99;
}

#app .border-y-error\/70 {
  border-top-color: #ea3b2db3;
  border-bottom-color: #ea3b2db3;
}

#app .border-y-error\/75 {
  border-top-color: #ea3b2dbf;
  border-bottom-color: #ea3b2dbf;
}

#app .border-y-error\/80 {
  border-top-color: #ea3b2dcc;
  border-bottom-color: #ea3b2dcc;
}

#app .border-y-error\/90 {
  border-top-color: #ea3b2de6;
  border-bottom-color: #ea3b2de6;
}

#app .border-y-error\/95 {
  border-top-color: #ea3b2df2;
  border-bottom-color: #ea3b2df2;
}

#app .border-y-error\/100 {
  border-top-color: #ea3b2d;
  border-bottom-color: #ea3b2d;
}

#app .border-y-errorBackground\/0 {
  border-top-color: #ffd0cd00;
  border-bottom-color: #ffd0cd00;
}

#app .border-y-errorBackground\/5 {
  border-top-color: #ffd0cd0d;
  border-bottom-color: #ffd0cd0d;
}

#app .border-y-errorBackground\/10 {
  border-top-color: #ffd0cd1a;
  border-bottom-color: #ffd0cd1a;
}

#app .border-y-errorBackground\/20 {
  border-top-color: #ffd0cd33;
  border-bottom-color: #ffd0cd33;
}

#app .border-y-errorBackground\/25 {
  border-top-color: #ffd0cd40;
  border-bottom-color: #ffd0cd40;
}

#app .border-y-errorBackground\/30 {
  border-top-color: #ffd0cd4d;
  border-bottom-color: #ffd0cd4d;
}

#app .border-y-errorBackground\/40 {
  border-top-color: #ffd0cd66;
  border-bottom-color: #ffd0cd66;
}

#app .border-y-errorBackground\/50 {
  border-top-color: #ffd0cd80;
  border-bottom-color: #ffd0cd80;
}

#app .border-y-errorBackground\/60 {
  border-top-color: #ffd0cd99;
  border-bottom-color: #ffd0cd99;
}

#app .border-y-errorBackground\/70 {
  border-top-color: #ffd0cdb3;
  border-bottom-color: #ffd0cdb3;
}

#app .border-y-errorBackground\/75 {
  border-top-color: #ffd0cdbf;
  border-bottom-color: #ffd0cdbf;
}

#app .border-y-errorBackground\/80 {
  border-top-color: #ffd0cdcc;
  border-bottom-color: #ffd0cdcc;
}

#app .border-y-errorBackground\/90 {
  border-top-color: #ffd0cde6;
  border-bottom-color: #ffd0cde6;
}

#app .border-y-errorBackground\/95 {
  border-top-color: #ffd0cdf2;
  border-bottom-color: #ffd0cdf2;
}

#app .border-y-errorBackground\/100 {
  border-top-color: #ffd0cd;
  border-bottom-color: #ffd0cd;
}

#app .border-y-info\/0 {
  border-top-color: #2ec7ff00;
  border-bottom-color: #2ec7ff00;
}

#app .border-y-info\/5 {
  border-top-color: #2ec7ff0d;
  border-bottom-color: #2ec7ff0d;
}

#app .border-y-info\/10 {
  border-top-color: #2ec7ff1a;
  border-bottom-color: #2ec7ff1a;
}

#app .border-y-info\/20 {
  border-top-color: #2ec7ff33;
  border-bottom-color: #2ec7ff33;
}

#app .border-y-info\/25 {
  border-top-color: #2ec7ff40;
  border-bottom-color: #2ec7ff40;
}

#app .border-y-info\/30 {
  border-top-color: #2ec7ff4d;
  border-bottom-color: #2ec7ff4d;
}

#app .border-y-info\/40 {
  border-top-color: #2ec7ff66;
  border-bottom-color: #2ec7ff66;
}

#app .border-y-info\/50 {
  border-top-color: #2ec7ff80;
  border-bottom-color: #2ec7ff80;
}

#app .border-y-info\/60 {
  border-top-color: #2ec7ff99;
  border-bottom-color: #2ec7ff99;
}

#app .border-y-info\/70 {
  border-top-color: #2ec7ffb3;
  border-bottom-color: #2ec7ffb3;
}

#app .border-y-info\/75 {
  border-top-color: #2ec7ffbf;
  border-bottom-color: #2ec7ffbf;
}

#app .border-y-info\/80 {
  border-top-color: #2ec7ffcc;
  border-bottom-color: #2ec7ffcc;
}

#app .border-y-info\/90 {
  border-top-color: #2ec7ffe6;
  border-bottom-color: #2ec7ffe6;
}

#app .border-y-info\/95 {
  border-top-color: #2ec7fff2;
  border-bottom-color: #2ec7fff2;
}

#app .border-y-info\/100 {
  border-top-color: #2ec7ff;
  border-bottom-color: #2ec7ff;
}

#app .border-y-infoBackground\/0 {
  border-top-color: #eafaff00;
  border-bottom-color: #eafaff00;
}

#app .border-y-infoBackground\/5 {
  border-top-color: #eafaff0d;
  border-bottom-color: #eafaff0d;
}

#app .border-y-infoBackground\/10 {
  border-top-color: #eafaff1a;
  border-bottom-color: #eafaff1a;
}

#app .border-y-infoBackground\/20 {
  border-top-color: #eafaff33;
  border-bottom-color: #eafaff33;
}

#app .border-y-infoBackground\/25 {
  border-top-color: #eafaff40;
  border-bottom-color: #eafaff40;
}

#app .border-y-infoBackground\/30 {
  border-top-color: #eafaff4d;
  border-bottom-color: #eafaff4d;
}

#app .border-y-infoBackground\/40 {
  border-top-color: #eafaff66;
  border-bottom-color: #eafaff66;
}

#app .border-y-infoBackground\/50 {
  border-top-color: #eafaff80;
  border-bottom-color: #eafaff80;
}

#app .border-y-infoBackground\/60 {
  border-top-color: #eafaff99;
  border-bottom-color: #eafaff99;
}

#app .border-y-infoBackground\/70 {
  border-top-color: #eafaffb3;
  border-bottom-color: #eafaffb3;
}

#app .border-y-infoBackground\/75 {
  border-top-color: #eafaffbf;
  border-bottom-color: #eafaffbf;
}

#app .border-y-infoBackground\/80 {
  border-top-color: #eafaffcc;
  border-bottom-color: #eafaffcc;
}

#app .border-y-infoBackground\/90 {
  border-top-color: #eafaffe6;
  border-bottom-color: #eafaffe6;
}

#app .border-y-infoBackground\/95 {
  border-top-color: #eafafff2;
  border-bottom-color: #eafafff2;
}

#app .border-y-infoBackground\/100 {
  border-top-color: #eafaff;
  border-bottom-color: #eafaff;
}

#app .border-t-success {
  --tw-border-opacity: 1;
  border-top-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-t-successBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-t-error {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-t-errorBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-t-info {
  --tw-border-opacity: 1;
  border-top-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-t-infoBackground {
  --tw-border-opacity: 1;
  border-top-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-t-success\/0 {
  border-top-color: #00a35200;
}

#app .border-t-success\/5 {
  border-top-color: #00a3520d;
}

#app .border-t-success\/10 {
  border-top-color: #00a3521a;
}

#app .border-t-success\/20 {
  border-top-color: #00a35233;
}

#app .border-t-success\/25 {
  border-top-color: #00a35240;
}

#app .border-t-success\/30 {
  border-top-color: #00a3524d;
}

#app .border-t-success\/40 {
  border-top-color: #00a35266;
}

#app .border-t-success\/50 {
  border-top-color: #00a35280;
}

#app .border-t-success\/60 {
  border-top-color: #00a35299;
}

#app .border-t-success\/70 {
  border-top-color: #00a352b3;
}

#app .border-t-success\/75 {
  border-top-color: #00a352bf;
}

#app .border-t-success\/80 {
  border-top-color: #00a352cc;
}

#app .border-t-success\/90 {
  border-top-color: #00a352e6;
}

#app .border-t-success\/95 {
  border-top-color: #00a352f2;
}

#app .border-t-success\/100 {
  border-top-color: #00a352;
}

#app .border-t-successBackground\/0 {
  border-top-color: #eafaff00;
}

#app .border-t-successBackground\/5 {
  border-top-color: #eafaff0d;
}

#app .border-t-successBackground\/10 {
  border-top-color: #eafaff1a;
}

#app .border-t-successBackground\/20 {
  border-top-color: #eafaff33;
}

#app .border-t-successBackground\/25 {
  border-top-color: #eafaff40;
}

#app .border-t-successBackground\/30 {
  border-top-color: #eafaff4d;
}

#app .border-t-successBackground\/40 {
  border-top-color: #eafaff66;
}

#app .border-t-successBackground\/50 {
  border-top-color: #eafaff80;
}

#app .border-t-successBackground\/60 {
  border-top-color: #eafaff99;
}

#app .border-t-successBackground\/70 {
  border-top-color: #eafaffb3;
}

#app .border-t-successBackground\/75 {
  border-top-color: #eafaffbf;
}

#app .border-t-successBackground\/80 {
  border-top-color: #eafaffcc;
}

#app .border-t-successBackground\/90 {
  border-top-color: #eafaffe6;
}

#app .border-t-successBackground\/95 {
  border-top-color: #eafafff2;
}

#app .border-t-successBackground\/100 {
  border-top-color: #eafaff;
}

#app .border-t-error\/0 {
  border-top-color: #ea3b2d00;
}

#app .border-t-error\/5 {
  border-top-color: #ea3b2d0d;
}

#app .border-t-error\/10 {
  border-top-color: #ea3b2d1a;
}

#app .border-t-error\/20 {
  border-top-color: #ea3b2d33;
}

#app .border-t-error\/25 {
  border-top-color: #ea3b2d40;
}

#app .border-t-error\/30 {
  border-top-color: #ea3b2d4d;
}

#app .border-t-error\/40 {
  border-top-color: #ea3b2d66;
}

#app .border-t-error\/50 {
  border-top-color: #ea3b2d80;
}

#app .border-t-error\/60 {
  border-top-color: #ea3b2d99;
}

#app .border-t-error\/70 {
  border-top-color: #ea3b2db3;
}

#app .border-t-error\/75 {
  border-top-color: #ea3b2dbf;
}

#app .border-t-error\/80 {
  border-top-color: #ea3b2dcc;
}

#app .border-t-error\/90 {
  border-top-color: #ea3b2de6;
}

#app .border-t-error\/95 {
  border-top-color: #ea3b2df2;
}

#app .border-t-error\/100 {
  border-top-color: #ea3b2d;
}

#app .border-t-errorBackground\/0 {
  border-top-color: #ffd0cd00;
}

#app .border-t-errorBackground\/5 {
  border-top-color: #ffd0cd0d;
}

#app .border-t-errorBackground\/10 {
  border-top-color: #ffd0cd1a;
}

#app .border-t-errorBackground\/20 {
  border-top-color: #ffd0cd33;
}

#app .border-t-errorBackground\/25 {
  border-top-color: #ffd0cd40;
}

#app .border-t-errorBackground\/30 {
  border-top-color: #ffd0cd4d;
}

#app .border-t-errorBackground\/40 {
  border-top-color: #ffd0cd66;
}

#app .border-t-errorBackground\/50 {
  border-top-color: #ffd0cd80;
}

#app .border-t-errorBackground\/60 {
  border-top-color: #ffd0cd99;
}

#app .border-t-errorBackground\/70 {
  border-top-color: #ffd0cdb3;
}

#app .border-t-errorBackground\/75 {
  border-top-color: #ffd0cdbf;
}

#app .border-t-errorBackground\/80 {
  border-top-color: #ffd0cdcc;
}

#app .border-t-errorBackground\/90 {
  border-top-color: #ffd0cde6;
}

#app .border-t-errorBackground\/95 {
  border-top-color: #ffd0cdf2;
}

#app .border-t-errorBackground\/100 {
  border-top-color: #ffd0cd;
}

#app .border-t-info\/0 {
  border-top-color: #2ec7ff00;
}

#app .border-t-info\/5 {
  border-top-color: #2ec7ff0d;
}

#app .border-t-info\/10 {
  border-top-color: #2ec7ff1a;
}

#app .border-t-info\/20 {
  border-top-color: #2ec7ff33;
}

#app .border-t-info\/25 {
  border-top-color: #2ec7ff40;
}

#app .border-t-info\/30 {
  border-top-color: #2ec7ff4d;
}

#app .border-t-info\/40 {
  border-top-color: #2ec7ff66;
}

#app .border-t-info\/50 {
  border-top-color: #2ec7ff80;
}

#app .border-t-info\/60 {
  border-top-color: #2ec7ff99;
}

#app .border-t-info\/70 {
  border-top-color: #2ec7ffb3;
}

#app .border-t-info\/75 {
  border-top-color: #2ec7ffbf;
}

#app .border-t-info\/80 {
  border-top-color: #2ec7ffcc;
}

#app .border-t-info\/90 {
  border-top-color: #2ec7ffe6;
}

#app .border-t-info\/95 {
  border-top-color: #2ec7fff2;
}

#app .border-t-info\/100 {
  border-top-color: #2ec7ff;
}

#app .border-t-infoBackground\/0 {
  border-top-color: #eafaff00;
}

#app .border-t-infoBackground\/5 {
  border-top-color: #eafaff0d;
}

#app .border-t-infoBackground\/10 {
  border-top-color: #eafaff1a;
}

#app .border-t-infoBackground\/20 {
  border-top-color: #eafaff33;
}

#app .border-t-infoBackground\/25 {
  border-top-color: #eafaff40;
}

#app .border-t-infoBackground\/30 {
  border-top-color: #eafaff4d;
}

#app .border-t-infoBackground\/40 {
  border-top-color: #eafaff66;
}

#app .border-t-infoBackground\/50 {
  border-top-color: #eafaff80;
}

#app .border-t-infoBackground\/60 {
  border-top-color: #eafaff99;
}

#app .border-t-infoBackground\/70 {
  border-top-color: #eafaffb3;
}

#app .border-t-infoBackground\/75 {
  border-top-color: #eafaffbf;
}

#app .border-t-infoBackground\/80 {
  border-top-color: #eafaffcc;
}

#app .border-t-infoBackground\/90 {
  border-top-color: #eafaffe6;
}

#app .border-t-infoBackground\/95 {
  border-top-color: #eafafff2;
}

#app .border-t-infoBackground\/100 {
  border-top-color: #eafaff;
}

#app .border-r-success {
  --tw-border-opacity: 1;
  border-right-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-r-successBackground {
  --tw-border-opacity: 1;
  border-right-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-r-error {
  --tw-border-opacity: 1;
  border-right-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-r-errorBackground {
  --tw-border-opacity: 1;
  border-right-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-r-info {
  --tw-border-opacity: 1;
  border-right-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-r-infoBackground {
  --tw-border-opacity: 1;
  border-right-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-r-success\/0 {
  border-right-color: #00a35200;
}

#app .border-r-success\/5 {
  border-right-color: #00a3520d;
}

#app .border-r-success\/10 {
  border-right-color: #00a3521a;
}

#app .border-r-success\/20 {
  border-right-color: #00a35233;
}

#app .border-r-success\/25 {
  border-right-color: #00a35240;
}

#app .border-r-success\/30 {
  border-right-color: #00a3524d;
}

#app .border-r-success\/40 {
  border-right-color: #00a35266;
}

#app .border-r-success\/50 {
  border-right-color: #00a35280;
}

#app .border-r-success\/60 {
  border-right-color: #00a35299;
}

#app .border-r-success\/70 {
  border-right-color: #00a352b3;
}

#app .border-r-success\/75 {
  border-right-color: #00a352bf;
}

#app .border-r-success\/80 {
  border-right-color: #00a352cc;
}

#app .border-r-success\/90 {
  border-right-color: #00a352e6;
}

#app .border-r-success\/95 {
  border-right-color: #00a352f2;
}

#app .border-r-success\/100 {
  border-right-color: #00a352;
}

#app .border-r-successBackground\/0 {
  border-right-color: #eafaff00;
}

#app .border-r-successBackground\/5 {
  border-right-color: #eafaff0d;
}

#app .border-r-successBackground\/10 {
  border-right-color: #eafaff1a;
}

#app .border-r-successBackground\/20 {
  border-right-color: #eafaff33;
}

#app .border-r-successBackground\/25 {
  border-right-color: #eafaff40;
}

#app .border-r-successBackground\/30 {
  border-right-color: #eafaff4d;
}

#app .border-r-successBackground\/40 {
  border-right-color: #eafaff66;
}

#app .border-r-successBackground\/50 {
  border-right-color: #eafaff80;
}

#app .border-r-successBackground\/60 {
  border-right-color: #eafaff99;
}

#app .border-r-successBackground\/70 {
  border-right-color: #eafaffb3;
}

#app .border-r-successBackground\/75 {
  border-right-color: #eafaffbf;
}

#app .border-r-successBackground\/80 {
  border-right-color: #eafaffcc;
}

#app .border-r-successBackground\/90 {
  border-right-color: #eafaffe6;
}

#app .border-r-successBackground\/95 {
  border-right-color: #eafafff2;
}

#app .border-r-successBackground\/100 {
  border-right-color: #eafaff;
}

#app .border-r-error\/0 {
  border-right-color: #ea3b2d00;
}

#app .border-r-error\/5 {
  border-right-color: #ea3b2d0d;
}

#app .border-r-error\/10 {
  border-right-color: #ea3b2d1a;
}

#app .border-r-error\/20 {
  border-right-color: #ea3b2d33;
}

#app .border-r-error\/25 {
  border-right-color: #ea3b2d40;
}

#app .border-r-error\/30 {
  border-right-color: #ea3b2d4d;
}

#app .border-r-error\/40 {
  border-right-color: #ea3b2d66;
}

#app .border-r-error\/50 {
  border-right-color: #ea3b2d80;
}

#app .border-r-error\/60 {
  border-right-color: #ea3b2d99;
}

#app .border-r-error\/70 {
  border-right-color: #ea3b2db3;
}

#app .border-r-error\/75 {
  border-right-color: #ea3b2dbf;
}

#app .border-r-error\/80 {
  border-right-color: #ea3b2dcc;
}

#app .border-r-error\/90 {
  border-right-color: #ea3b2de6;
}

#app .border-r-error\/95 {
  border-right-color: #ea3b2df2;
}

#app .border-r-error\/100 {
  border-right-color: #ea3b2d;
}

#app .border-r-errorBackground\/0 {
  border-right-color: #ffd0cd00;
}

#app .border-r-errorBackground\/5 {
  border-right-color: #ffd0cd0d;
}

#app .border-r-errorBackground\/10 {
  border-right-color: #ffd0cd1a;
}

#app .border-r-errorBackground\/20 {
  border-right-color: #ffd0cd33;
}

#app .border-r-errorBackground\/25 {
  border-right-color: #ffd0cd40;
}

#app .border-r-errorBackground\/30 {
  border-right-color: #ffd0cd4d;
}

#app .border-r-errorBackground\/40 {
  border-right-color: #ffd0cd66;
}

#app .border-r-errorBackground\/50 {
  border-right-color: #ffd0cd80;
}

#app .border-r-errorBackground\/60 {
  border-right-color: #ffd0cd99;
}

#app .border-r-errorBackground\/70 {
  border-right-color: #ffd0cdb3;
}

#app .border-r-errorBackground\/75 {
  border-right-color: #ffd0cdbf;
}

#app .border-r-errorBackground\/80 {
  border-right-color: #ffd0cdcc;
}

#app .border-r-errorBackground\/90 {
  border-right-color: #ffd0cde6;
}

#app .border-r-errorBackground\/95 {
  border-right-color: #ffd0cdf2;
}

#app .border-r-errorBackground\/100 {
  border-right-color: #ffd0cd;
}

#app .border-r-info\/0 {
  border-right-color: #2ec7ff00;
}

#app .border-r-info\/5 {
  border-right-color: #2ec7ff0d;
}

#app .border-r-info\/10 {
  border-right-color: #2ec7ff1a;
}

#app .border-r-info\/20 {
  border-right-color: #2ec7ff33;
}

#app .border-r-info\/25 {
  border-right-color: #2ec7ff40;
}

#app .border-r-info\/30 {
  border-right-color: #2ec7ff4d;
}

#app .border-r-info\/40 {
  border-right-color: #2ec7ff66;
}

#app .border-r-info\/50 {
  border-right-color: #2ec7ff80;
}

#app .border-r-info\/60 {
  border-right-color: #2ec7ff99;
}

#app .border-r-info\/70 {
  border-right-color: #2ec7ffb3;
}

#app .border-r-info\/75 {
  border-right-color: #2ec7ffbf;
}

#app .border-r-info\/80 {
  border-right-color: #2ec7ffcc;
}

#app .border-r-info\/90 {
  border-right-color: #2ec7ffe6;
}

#app .border-r-info\/95 {
  border-right-color: #2ec7fff2;
}

#app .border-r-info\/100 {
  border-right-color: #2ec7ff;
}

#app .border-r-infoBackground\/0 {
  border-right-color: #eafaff00;
}

#app .border-r-infoBackground\/5 {
  border-right-color: #eafaff0d;
}

#app .border-r-infoBackground\/10 {
  border-right-color: #eafaff1a;
}

#app .border-r-infoBackground\/20 {
  border-right-color: #eafaff33;
}

#app .border-r-infoBackground\/25 {
  border-right-color: #eafaff40;
}

#app .border-r-infoBackground\/30 {
  border-right-color: #eafaff4d;
}

#app .border-r-infoBackground\/40 {
  border-right-color: #eafaff66;
}

#app .border-r-infoBackground\/50 {
  border-right-color: #eafaff80;
}

#app .border-r-infoBackground\/60 {
  border-right-color: #eafaff99;
}

#app .border-r-infoBackground\/70 {
  border-right-color: #eafaffb3;
}

#app .border-r-infoBackground\/75 {
  border-right-color: #eafaffbf;
}

#app .border-r-infoBackground\/80 {
  border-right-color: #eafaffcc;
}

#app .border-r-infoBackground\/90 {
  border-right-color: #eafaffe6;
}

#app .border-r-infoBackground\/95 {
  border-right-color: #eafafff2;
}

#app .border-r-infoBackground\/100 {
  border-right-color: #eafaff;
}

#app .border-b-success {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-b-successBackground {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-b-error {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-b-errorBackground {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-b-info {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-b-infoBackground {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-b-success\/0 {
  border-bottom-color: #00a35200;
}

#app .border-b-success\/5 {
  border-bottom-color: #00a3520d;
}

#app .border-b-success\/10 {
  border-bottom-color: #00a3521a;
}

#app .border-b-success\/20 {
  border-bottom-color: #00a35233;
}

#app .border-b-success\/25 {
  border-bottom-color: #00a35240;
}

#app .border-b-success\/30 {
  border-bottom-color: #00a3524d;
}

#app .border-b-success\/40 {
  border-bottom-color: #00a35266;
}

#app .border-b-success\/50 {
  border-bottom-color: #00a35280;
}

#app .border-b-success\/60 {
  border-bottom-color: #00a35299;
}

#app .border-b-success\/70 {
  border-bottom-color: #00a352b3;
}

#app .border-b-success\/75 {
  border-bottom-color: #00a352bf;
}

#app .border-b-success\/80 {
  border-bottom-color: #00a352cc;
}

#app .border-b-success\/90 {
  border-bottom-color: #00a352e6;
}

#app .border-b-success\/95 {
  border-bottom-color: #00a352f2;
}

#app .border-b-success\/100 {
  border-bottom-color: #00a352;
}

#app .border-b-successBackground\/0 {
  border-bottom-color: #eafaff00;
}

#app .border-b-successBackground\/5 {
  border-bottom-color: #eafaff0d;
}

#app .border-b-successBackground\/10 {
  border-bottom-color: #eafaff1a;
}

#app .border-b-successBackground\/20 {
  border-bottom-color: #eafaff33;
}

#app .border-b-successBackground\/25 {
  border-bottom-color: #eafaff40;
}

#app .border-b-successBackground\/30 {
  border-bottom-color: #eafaff4d;
}

#app .border-b-successBackground\/40 {
  border-bottom-color: #eafaff66;
}

#app .border-b-successBackground\/50 {
  border-bottom-color: #eafaff80;
}

#app .border-b-successBackground\/60 {
  border-bottom-color: #eafaff99;
}

#app .border-b-successBackground\/70 {
  border-bottom-color: #eafaffb3;
}

#app .border-b-successBackground\/75 {
  border-bottom-color: #eafaffbf;
}

#app .border-b-successBackground\/80 {
  border-bottom-color: #eafaffcc;
}

#app .border-b-successBackground\/90 {
  border-bottom-color: #eafaffe6;
}

#app .border-b-successBackground\/95 {
  border-bottom-color: #eafafff2;
}

#app .border-b-successBackground\/100 {
  border-bottom-color: #eafaff;
}

#app .border-b-error\/0 {
  border-bottom-color: #ea3b2d00;
}

#app .border-b-error\/5 {
  border-bottom-color: #ea3b2d0d;
}

#app .border-b-error\/10 {
  border-bottom-color: #ea3b2d1a;
}

#app .border-b-error\/20 {
  border-bottom-color: #ea3b2d33;
}

#app .border-b-error\/25 {
  border-bottom-color: #ea3b2d40;
}

#app .border-b-error\/30 {
  border-bottom-color: #ea3b2d4d;
}

#app .border-b-error\/40 {
  border-bottom-color: #ea3b2d66;
}

#app .border-b-error\/50 {
  border-bottom-color: #ea3b2d80;
}

#app .border-b-error\/60 {
  border-bottom-color: #ea3b2d99;
}

#app .border-b-error\/70 {
  border-bottom-color: #ea3b2db3;
}

#app .border-b-error\/75 {
  border-bottom-color: #ea3b2dbf;
}

#app .border-b-error\/80 {
  border-bottom-color: #ea3b2dcc;
}

#app .border-b-error\/90 {
  border-bottom-color: #ea3b2de6;
}

#app .border-b-error\/95 {
  border-bottom-color: #ea3b2df2;
}

#app .border-b-error\/100 {
  border-bottom-color: #ea3b2d;
}

#app .border-b-errorBackground\/0 {
  border-bottom-color: #ffd0cd00;
}

#app .border-b-errorBackground\/5 {
  border-bottom-color: #ffd0cd0d;
}

#app .border-b-errorBackground\/10 {
  border-bottom-color: #ffd0cd1a;
}

#app .border-b-errorBackground\/20 {
  border-bottom-color: #ffd0cd33;
}

#app .border-b-errorBackground\/25 {
  border-bottom-color: #ffd0cd40;
}

#app .border-b-errorBackground\/30 {
  border-bottom-color: #ffd0cd4d;
}

#app .border-b-errorBackground\/40 {
  border-bottom-color: #ffd0cd66;
}

#app .border-b-errorBackground\/50 {
  border-bottom-color: #ffd0cd80;
}

#app .border-b-errorBackground\/60 {
  border-bottom-color: #ffd0cd99;
}

#app .border-b-errorBackground\/70 {
  border-bottom-color: #ffd0cdb3;
}

#app .border-b-errorBackground\/75 {
  border-bottom-color: #ffd0cdbf;
}

#app .border-b-errorBackground\/80 {
  border-bottom-color: #ffd0cdcc;
}

#app .border-b-errorBackground\/90 {
  border-bottom-color: #ffd0cde6;
}

#app .border-b-errorBackground\/95 {
  border-bottom-color: #ffd0cdf2;
}

#app .border-b-errorBackground\/100 {
  border-bottom-color: #ffd0cd;
}

#app .border-b-info\/0 {
  border-bottom-color: #2ec7ff00;
}

#app .border-b-info\/5 {
  border-bottom-color: #2ec7ff0d;
}

#app .border-b-info\/10 {
  border-bottom-color: #2ec7ff1a;
}

#app .border-b-info\/20 {
  border-bottom-color: #2ec7ff33;
}

#app .border-b-info\/25 {
  border-bottom-color: #2ec7ff40;
}

#app .border-b-info\/30 {
  border-bottom-color: #2ec7ff4d;
}

#app .border-b-info\/40 {
  border-bottom-color: #2ec7ff66;
}

#app .border-b-info\/50 {
  border-bottom-color: #2ec7ff80;
}

#app .border-b-info\/60 {
  border-bottom-color: #2ec7ff99;
}

#app .border-b-info\/70 {
  border-bottom-color: #2ec7ffb3;
}

#app .border-b-info\/75 {
  border-bottom-color: #2ec7ffbf;
}

#app .border-b-info\/80 {
  border-bottom-color: #2ec7ffcc;
}

#app .border-b-info\/90 {
  border-bottom-color: #2ec7ffe6;
}

#app .border-b-info\/95 {
  border-bottom-color: #2ec7fff2;
}

#app .border-b-info\/100 {
  border-bottom-color: #2ec7ff;
}

#app .border-b-infoBackground\/0 {
  border-bottom-color: #eafaff00;
}

#app .border-b-infoBackground\/5 {
  border-bottom-color: #eafaff0d;
}

#app .border-b-infoBackground\/10 {
  border-bottom-color: #eafaff1a;
}

#app .border-b-infoBackground\/20 {
  border-bottom-color: #eafaff33;
}

#app .border-b-infoBackground\/25 {
  border-bottom-color: #eafaff40;
}

#app .border-b-infoBackground\/30 {
  border-bottom-color: #eafaff4d;
}

#app .border-b-infoBackground\/40 {
  border-bottom-color: #eafaff66;
}

#app .border-b-infoBackground\/50 {
  border-bottom-color: #eafaff80;
}

#app .border-b-infoBackground\/60 {
  border-bottom-color: #eafaff99;
}

#app .border-b-infoBackground\/70 {
  border-bottom-color: #eafaffb3;
}

#app .border-b-infoBackground\/75 {
  border-bottom-color: #eafaffbf;
}

#app .border-b-infoBackground\/80 {
  border-bottom-color: #eafaffcc;
}

#app .border-b-infoBackground\/90 {
  border-bottom-color: #eafaffe6;
}

#app .border-b-infoBackground\/95 {
  border-bottom-color: #eafafff2;
}

#app .border-b-infoBackground\/100 {
  border-bottom-color: #eafaff;
}

#app .border-l-success {
  --tw-border-opacity: 1;
  border-left-color: rgb(0 163 82 / var(--tw-border-opacity));
}

#app .border-l-successBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-l-error {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 59 45 / var(--tw-border-opacity));
}

#app .border-l-errorBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(255 208 205 / var(--tw-border-opacity));
}

#app .border-l-info {
  --tw-border-opacity: 1;
  border-left-color: rgb(46 199 255 / var(--tw-border-opacity));
}

#app .border-l-infoBackground {
  --tw-border-opacity: 1;
  border-left-color: rgb(234 250 255 / var(--tw-border-opacity));
}

#app .border-l-success\/0 {
  border-left-color: #00a35200;
}

#app .border-l-success\/5 {
  border-left-color: #00a3520d;
}

#app .border-l-success\/10 {
  border-left-color: #00a3521a;
}

#app .border-l-success\/20 {
  border-left-color: #00a35233;
}

#app .border-l-success\/25 {
  border-left-color: #00a35240;
}

#app .border-l-success\/30 {
  border-left-color: #00a3524d;
}

#app .border-l-success\/40 {
  border-left-color: #00a35266;
}

#app .border-l-success\/50 {
  border-left-color: #00a35280;
}

#app .border-l-success\/60 {
  border-left-color: #00a35299;
}

#app .border-l-success\/70 {
  border-left-color: #00a352b3;
}

#app .border-l-success\/75 {
  border-left-color: #00a352bf;
}

#app .border-l-success\/80 {
  border-left-color: #00a352cc;
}

#app .border-l-success\/90 {
  border-left-color: #00a352e6;
}

#app .border-l-success\/95 {
  border-left-color: #00a352f2;
}

#app .border-l-success\/100 {
  border-left-color: #00a352;
}

#app .border-l-successBackground\/0 {
  border-left-color: #eafaff00;
}

#app .border-l-successBackground\/5 {
  border-left-color: #eafaff0d;
}

#app .border-l-successBackground\/10 {
  border-left-color: #eafaff1a;
}

#app .border-l-successBackground\/20 {
  border-left-color: #eafaff33;
}

#app .border-l-successBackground\/25 {
  border-left-color: #eafaff40;
}

#app .border-l-successBackground\/30 {
  border-left-color: #eafaff4d;
}

#app .border-l-successBackground\/40 {
  border-left-color: #eafaff66;
}

#app .border-l-successBackground\/50 {
  border-left-color: #eafaff80;
}

#app .border-l-successBackground\/60 {
  border-left-color: #eafaff99;
}

#app .border-l-successBackground\/70 {
  border-left-color: #eafaffb3;
}

#app .border-l-successBackground\/75 {
  border-left-color: #eafaffbf;
}

#app .border-l-successBackground\/80 {
  border-left-color: #eafaffcc;
}

#app .border-l-successBackground\/90 {
  border-left-color: #eafaffe6;
}

#app .border-l-successBackground\/95 {
  border-left-color: #eafafff2;
}

#app .border-l-successBackground\/100 {
  border-left-color: #eafaff;
}

#app .border-l-error\/0 {
  border-left-color: #ea3b2d00;
}

#app .border-l-error\/5 {
  border-left-color: #ea3b2d0d;
}

#app .border-l-error\/10 {
  border-left-color: #ea3b2d1a;
}

#app .border-l-error\/20 {
  border-left-color: #ea3b2d33;
}

#app .border-l-error\/25 {
  border-left-color: #ea3b2d40;
}

#app .border-l-error\/30 {
  border-left-color: #ea3b2d4d;
}

#app .border-l-error\/40 {
  border-left-color: #ea3b2d66;
}

#app .border-l-error\/50 {
  border-left-color: #ea3b2d80;
}

#app .border-l-error\/60 {
  border-left-color: #ea3b2d99;
}

#app .border-l-error\/70 {
  border-left-color: #ea3b2db3;
}

#app .border-l-error\/75 {
  border-left-color: #ea3b2dbf;
}

#app .border-l-error\/80 {
  border-left-color: #ea3b2dcc;
}

#app .border-l-error\/90 {
  border-left-color: #ea3b2de6;
}

#app .border-l-error\/95 {
  border-left-color: #ea3b2df2;
}

#app .border-l-error\/100 {
  border-left-color: #ea3b2d;
}

#app .border-l-errorBackground\/0 {
  border-left-color: #ffd0cd00;
}

#app .border-l-errorBackground\/5 {
  border-left-color: #ffd0cd0d;
}

#app .border-l-errorBackground\/10 {
  border-left-color: #ffd0cd1a;
}

#app .border-l-errorBackground\/20 {
  border-left-color: #ffd0cd33;
}

#app .border-l-errorBackground\/25 {
  border-left-color: #ffd0cd40;
}

#app .border-l-errorBackground\/30 {
  border-left-color: #ffd0cd4d;
}

#app .border-l-errorBackground\/40 {
  border-left-color: #ffd0cd66;
}

#app .border-l-errorBackground\/50 {
  border-left-color: #ffd0cd80;
}

#app .border-l-errorBackground\/60 {
  border-left-color: #ffd0cd99;
}

#app .border-l-errorBackground\/70 {
  border-left-color: #ffd0cdb3;
}

#app .border-l-errorBackground\/75 {
  border-left-color: #ffd0cdbf;
}

#app .border-l-errorBackground\/80 {
  border-left-color: #ffd0cdcc;
}

#app .border-l-errorBackground\/90 {
  border-left-color: #ffd0cde6;
}

#app .border-l-errorBackground\/95 {
  border-left-color: #ffd0cdf2;
}

#app .border-l-errorBackground\/100 {
  border-left-color: #ffd0cd;
}

#app .border-l-info\/0 {
  border-left-color: #2ec7ff00;
}

#app .border-l-info\/5 {
  border-left-color: #2ec7ff0d;
}

#app .border-l-info\/10 {
  border-left-color: #2ec7ff1a;
}

#app .border-l-info\/20 {
  border-left-color: #2ec7ff33;
}

#app .border-l-info\/25 {
  border-left-color: #2ec7ff40;
}

#app .border-l-info\/30 {
  border-left-color: #2ec7ff4d;
}

#app .border-l-info\/40 {
  border-left-color: #2ec7ff66;
}

#app .border-l-info\/50 {
  border-left-color: #2ec7ff80;
}

#app .border-l-info\/60 {
  border-left-color: #2ec7ff99;
}

#app .border-l-info\/70 {
  border-left-color: #2ec7ffb3;
}

#app .border-l-info\/75 {
  border-left-color: #2ec7ffbf;
}

#app .border-l-info\/80 {
  border-left-color: #2ec7ffcc;
}

#app .border-l-info\/90 {
  border-left-color: #2ec7ffe6;
}

#app .border-l-info\/95 {
  border-left-color: #2ec7fff2;
}

#app .border-l-info\/100 {
  border-left-color: #2ec7ff;
}

#app .border-l-infoBackground\/0 {
  border-left-color: #eafaff00;
}

#app .border-l-infoBackground\/5 {
  border-left-color: #eafaff0d;
}

#app .border-l-infoBackground\/10 {
  border-left-color: #eafaff1a;
}

#app .border-l-infoBackground\/20 {
  border-left-color: #eafaff33;
}

#app .border-l-infoBackground\/25 {
  border-left-color: #eafaff40;
}

#app .border-l-infoBackground\/30 {
  border-left-color: #eafaff4d;
}

#app .border-l-infoBackground\/40 {
  border-left-color: #eafaff66;
}

#app .border-l-infoBackground\/50 {
  border-left-color: #eafaff80;
}

#app .border-l-infoBackground\/60 {
  border-left-color: #eafaff99;
}

#app .border-l-infoBackground\/70 {
  border-left-color: #eafaffb3;
}

#app .border-l-infoBackground\/75 {
  border-left-color: #eafaffbf;
}

#app .border-l-infoBackground\/80 {
  border-left-color: #eafaffcc;
}

#app .border-l-infoBackground\/90 {
  border-left-color: #eafaffe6;
}

#app .border-l-infoBackground\/95 {
  border-left-color: #eafafff2;
}

#app .border-l-infoBackground\/100 {
  border-left-color: #eafaff;
}

#app .border-b-borderGray {
  border-bottom-color: #0000001a;
}

#app .border-t-adminAssistantBorder {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
}

#app .bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(0 163 82 / var(--tw-bg-opacity));
}

#app .bg-successBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(234 250 255 / var(--tw-bg-opacity));
}

#app .bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(234 59 45 / var(--tw-bg-opacity));
}

#app .bg-errorBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(255 208 205 / var(--tw-bg-opacity));
}

#app .bg-info {
  --tw-bg-opacity: 1;
  background-color: rgb(46 199 255 / var(--tw-bg-opacity));
}

#app .bg-infoBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(234 250 255 / var(--tw-bg-opacity));
}

#app .bg-success\/0 {
  background-color: #00a35200;
}

#app .bg-success\/5 {
  background-color: #00a3520d;
}

#app .bg-success\/10 {
  background-color: #00a3521a;
}

#app .bg-success\/20 {
  background-color: #00a35233;
}

#app .bg-success\/25 {
  background-color: #00a35240;
}

#app .bg-success\/30 {
  background-color: #00a3524d;
}

#app .bg-success\/40 {
  background-color: #00a35266;
}

#app .bg-success\/50 {
  background-color: #00a35280;
}

#app .bg-success\/60 {
  background-color: #00a35299;
}

#app .bg-success\/70 {
  background-color: #00a352b3;
}

#app .bg-success\/75 {
  background-color: #00a352bf;
}

#app .bg-success\/80 {
  background-color: #00a352cc;
}

#app .bg-success\/90 {
  background-color: #00a352e6;
}

#app .bg-success\/95 {
  background-color: #00a352f2;
}

#app .bg-success\/100 {
  background-color: #00a352;
}

#app .bg-successBackground\/0 {
  background-color: #eafaff00;
}

#app .bg-successBackground\/5 {
  background-color: #eafaff0d;
}

#app .bg-successBackground\/10 {
  background-color: #eafaff1a;
}

#app .bg-successBackground\/20 {
  background-color: #eafaff33;
}

#app .bg-successBackground\/25 {
  background-color: #eafaff40;
}

#app .bg-successBackground\/30 {
  background-color: #eafaff4d;
}

#app .bg-successBackground\/40 {
  background-color: #eafaff66;
}

#app .bg-successBackground\/50 {
  background-color: #eafaff80;
}

#app .bg-successBackground\/60 {
  background-color: #eafaff99;
}

#app .bg-successBackground\/70 {
  background-color: #eafaffb3;
}

#app .bg-successBackground\/75 {
  background-color: #eafaffbf;
}

#app .bg-successBackground\/80 {
  background-color: #eafaffcc;
}

#app .bg-successBackground\/90 {
  background-color: #eafaffe6;
}

#app .bg-successBackground\/95 {
  background-color: #eafafff2;
}

#app .bg-successBackground\/100 {
  background-color: #eafaff;
}

#app .bg-error\/0 {
  background-color: #ea3b2d00;
}

#app .bg-error\/5 {
  background-color: #ea3b2d0d;
}

#app .bg-error\/10 {
  background-color: #ea3b2d1a;
}

#app .bg-error\/20 {
  background-color: #ea3b2d33;
}

#app .bg-error\/25 {
  background-color: #ea3b2d40;
}

#app .bg-error\/30 {
  background-color: #ea3b2d4d;
}

#app .bg-error\/40 {
  background-color: #ea3b2d66;
}

#app .bg-error\/50 {
  background-color: #ea3b2d80;
}

#app .bg-error\/60 {
  background-color: #ea3b2d99;
}

#app .bg-error\/70 {
  background-color: #ea3b2db3;
}

#app .bg-error\/75 {
  background-color: #ea3b2dbf;
}

#app .bg-error\/80 {
  background-color: #ea3b2dcc;
}

#app .bg-error\/90 {
  background-color: #ea3b2de6;
}

#app .bg-error\/95 {
  background-color: #ea3b2df2;
}

#app .bg-error\/100 {
  background-color: #ea3b2d;
}

#app .bg-errorBackground\/0 {
  background-color: #ffd0cd00;
}

#app .bg-errorBackground\/5 {
  background-color: #ffd0cd0d;
}

#app .bg-errorBackground\/10 {
  background-color: #ffd0cd1a;
}

#app .bg-errorBackground\/20 {
  background-color: #ffd0cd33;
}

#app .bg-errorBackground\/25 {
  background-color: #ffd0cd40;
}

#app .bg-errorBackground\/30 {
  background-color: #ffd0cd4d;
}

#app .bg-errorBackground\/40 {
  background-color: #ffd0cd66;
}

#app .bg-errorBackground\/50 {
  background-color: #ffd0cd80;
}

#app .bg-errorBackground\/60 {
  background-color: #ffd0cd99;
}

#app .bg-errorBackground\/70 {
  background-color: #ffd0cdb3;
}

#app .bg-errorBackground\/75 {
  background-color: #ffd0cdbf;
}

#app .bg-errorBackground\/80 {
  background-color: #ffd0cdcc;
}

#app .bg-errorBackground\/90 {
  background-color: #ffd0cde6;
}

#app .bg-errorBackground\/95 {
  background-color: #ffd0cdf2;
}

#app .bg-errorBackground\/100 {
  background-color: #ffd0cd;
}

#app .bg-info\/0 {
  background-color: #2ec7ff00;
}

#app .bg-info\/5 {
  background-color: #2ec7ff0d;
}

#app .bg-info\/10 {
  background-color: #2ec7ff1a;
}

#app .bg-info\/20 {
  background-color: #2ec7ff33;
}

#app .bg-info\/25 {
  background-color: #2ec7ff40;
}

#app .bg-info\/30 {
  background-color: #2ec7ff4d;
}

#app .bg-info\/40 {
  background-color: #2ec7ff66;
}

#app .bg-info\/50 {
  background-color: #2ec7ff80;
}

#app .bg-info\/60 {
  background-color: #2ec7ff99;
}

#app .bg-info\/70 {
  background-color: #2ec7ffb3;
}

#app .bg-info\/75 {
  background-color: #2ec7ffbf;
}

#app .bg-info\/80 {
  background-color: #2ec7ffcc;
}

#app .bg-info\/90 {
  background-color: #2ec7ffe6;
}

#app .bg-info\/95 {
  background-color: #2ec7fff2;
}

#app .bg-info\/100 {
  background-color: #2ec7ff;
}

#app .bg-infoBackground\/0 {
  background-color: #eafaff00;
}

#app .bg-infoBackground\/5 {
  background-color: #eafaff0d;
}

#app .bg-infoBackground\/10 {
  background-color: #eafaff1a;
}

#app .bg-infoBackground\/20 {
  background-color: #eafaff33;
}

#app .bg-infoBackground\/25 {
  background-color: #eafaff40;
}

#app .bg-infoBackground\/30 {
  background-color: #eafaff4d;
}

#app .bg-infoBackground\/40 {
  background-color: #eafaff66;
}

#app .bg-infoBackground\/50 {
  background-color: #eafaff80;
}

#app .bg-infoBackground\/60 {
  background-color: #eafaff99;
}

#app .bg-infoBackground\/70 {
  background-color: #eafaffb3;
}

#app .bg-infoBackground\/75 {
  background-color: #eafaffbf;
}

#app .bg-infoBackground\/80 {
  background-color: #eafaffcc;
}

#app .bg-infoBackground\/90 {
  background-color: #eafaffe6;
}

#app .bg-infoBackground\/95 {
  background-color: #eafafff2;
}

#app .bg-infoBackground\/100 {
  background-color: #eafaff;
}

#app .bg-mainBg {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

#app .bg-buttonBorder {
  --tw-bg-opacity: 1;
  background-color: rgb(128 106 210 / var(--tw-bg-opacity));
}

#app .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#app .bg-button {
  background-color: #0000000a;
}

#app .bg-ping {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

#app .bg-\[\#FAAFB7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 175 183 / var(--tw-bg-opacity));
}

#app .bg-\[\#CE6F7A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(206 111 122 / var(--tw-bg-opacity));
}

#app .bg-\[\#FEFFCE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 255 206 / var(--tw-bg-opacity));
}

#app .bg-\[\#D98892\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 136 146 / var(--tw-bg-opacity));
}

#app .bg-\[\#806AD2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 106 210 / var(--tw-bg-opacity));
}

#app .bg-tableGrey {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

#app .bg-\[\#808080\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

#app .bg-\[\#fdfdfd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 253 / var(--tw-bg-opacity));
}

#app .bg-\[\#EF6775\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 103 117 / var(--tw-bg-opacity));
}

#app .bg-commentBg {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

#app .bg-\[\#F9F9F9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

#app .bg-\[\#E0848D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 132 141 / var(--tw-bg-opacity));
}

#app .bg-\[\#d6d1dab8\] {
  background-color: #d6d1dab8;
}

#app .bg-\[\#6F5ACD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(111 90 205 / var(--tw-bg-opacity));
}

#app .bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

#app .bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#app .bg-\[\#f9fafb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

#app .bg-\[\#f1f1f1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

#app .bg-title {
  --tw-bg-opacity: 1;
  background-color: rgb(221 91 135 / var(--tw-bg-opacity));
}

#app .bg-\[\#00000054\] {
  background-color: #00000054;
}

#app .bg-\[\#806ad2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 106 210 / var(--tw-bg-opacity));
}

#app .bg-primaryButton {
  --tw-bg-opacity: 1;
  background-color: rgb(111 90 205 / var(--tw-bg-opacity));
}

#app .bg-\[\#ffdea0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 222 160 / var(--tw-bg-opacity));
}

#app .bg-nftBg {
  --tw-bg-opacity: 1;
  background-color: rgb(255 159 169 / var(--tw-bg-opacity));
}

#app .bg-onboardingButtonColor {
  --tw-bg-opacity: 1;
  background-color: rgb(224 132 141 / var(--tw-bg-opacity));
}

#app .bg-\[white\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#app .bg-\[\#fb7d8a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 125 138 / var(--tw-bg-opacity));
}

#app .bg-\[\#0000004d\] {
  background-color: #0000004d;
}

#app .bg-darkerBg {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

#app .bg-\[\#8d8d8d81\] {
  background-color: #8d8d8d81;
}

#app .bg-opacity-90 {
  --tw-bg-opacity: .9;
}

#app .bg-none {
  background-image: none;
}

#app .from-success {
  --tw-gradient-from: #00a352;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground {
  --tw-gradient-from: #eafaff;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error {
  --tw-gradient-from: #ea3b2d;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground {
  --tw-gradient-from: #ffd0cd;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info {
  --tw-gradient-from: #2ec7ff;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground {
  --tw-gradient-from: #eafaff;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/0 {
  --tw-gradient-from: #00a35200;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/5 {
  --tw-gradient-from: #00a3520d;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/10 {
  --tw-gradient-from: #00a3521a;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/20 {
  --tw-gradient-from: #00a35233;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/25 {
  --tw-gradient-from: #00a35240;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/30 {
  --tw-gradient-from: #00a3524d;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/40 {
  --tw-gradient-from: #00a35266;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/50 {
  --tw-gradient-from: #00a35280;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/60 {
  --tw-gradient-from: #00a35299;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/70 {
  --tw-gradient-from: #00a352b3;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/75 {
  --tw-gradient-from: #00a352bf;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/80 {
  --tw-gradient-from: #00a352cc;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/90 {
  --tw-gradient-from: #00a352e6;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/95 {
  --tw-gradient-from: #00a352f2;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-success\/100 {
  --tw-gradient-from: #00a352;
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/0 {
  --tw-gradient-from: #eafaff00;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/5 {
  --tw-gradient-from: #eafaff0d;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/10 {
  --tw-gradient-from: #eafaff1a;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/20 {
  --tw-gradient-from: #eafaff33;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/25 {
  --tw-gradient-from: #eafaff40;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/30 {
  --tw-gradient-from: #eafaff4d;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/40 {
  --tw-gradient-from: #eafaff66;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/50 {
  --tw-gradient-from: #eafaff80;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/60 {
  --tw-gradient-from: #eafaff99;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/70 {
  --tw-gradient-from: #eafaffb3;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/75 {
  --tw-gradient-from: #eafaffbf;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/80 {
  --tw-gradient-from: #eafaffcc;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/90 {
  --tw-gradient-from: #eafaffe6;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/95 {
  --tw-gradient-from: #eafafff2;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-successBackground\/100 {
  --tw-gradient-from: #eafaff;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/0 {
  --tw-gradient-from: #ea3b2d00;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/5 {
  --tw-gradient-from: #ea3b2d0d;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/10 {
  --tw-gradient-from: #ea3b2d1a;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/20 {
  --tw-gradient-from: #ea3b2d33;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/25 {
  --tw-gradient-from: #ea3b2d40;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/30 {
  --tw-gradient-from: #ea3b2d4d;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/40 {
  --tw-gradient-from: #ea3b2d66;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/50 {
  --tw-gradient-from: #ea3b2d80;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/60 {
  --tw-gradient-from: #ea3b2d99;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/70 {
  --tw-gradient-from: #ea3b2db3;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/75 {
  --tw-gradient-from: #ea3b2dbf;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/80 {
  --tw-gradient-from: #ea3b2dcc;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/90 {
  --tw-gradient-from: #ea3b2de6;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/95 {
  --tw-gradient-from: #ea3b2df2;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-error\/100 {
  --tw-gradient-from: #ea3b2d;
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/0 {
  --tw-gradient-from: #ffd0cd00;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/5 {
  --tw-gradient-from: #ffd0cd0d;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/10 {
  --tw-gradient-from: #ffd0cd1a;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/20 {
  --tw-gradient-from: #ffd0cd33;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/25 {
  --tw-gradient-from: #ffd0cd40;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/30 {
  --tw-gradient-from: #ffd0cd4d;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/40 {
  --tw-gradient-from: #ffd0cd66;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/50 {
  --tw-gradient-from: #ffd0cd80;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/60 {
  --tw-gradient-from: #ffd0cd99;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/70 {
  --tw-gradient-from: #ffd0cdb3;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/75 {
  --tw-gradient-from: #ffd0cdbf;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/80 {
  --tw-gradient-from: #ffd0cdcc;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/90 {
  --tw-gradient-from: #ffd0cde6;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/95 {
  --tw-gradient-from: #ffd0cdf2;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-errorBackground\/100 {
  --tw-gradient-from: #ffd0cd;
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/0 {
  --tw-gradient-from: #2ec7ff00;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/5 {
  --tw-gradient-from: #2ec7ff0d;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/10 {
  --tw-gradient-from: #2ec7ff1a;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/20 {
  --tw-gradient-from: #2ec7ff33;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/25 {
  --tw-gradient-from: #2ec7ff40;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/30 {
  --tw-gradient-from: #2ec7ff4d;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/40 {
  --tw-gradient-from: #2ec7ff66;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/50 {
  --tw-gradient-from: #2ec7ff80;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/60 {
  --tw-gradient-from: #2ec7ff99;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/70 {
  --tw-gradient-from: #2ec7ffb3;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/75 {
  --tw-gradient-from: #2ec7ffbf;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/80 {
  --tw-gradient-from: #2ec7ffcc;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/90 {
  --tw-gradient-from: #2ec7ffe6;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/95 {
  --tw-gradient-from: #2ec7fff2;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-info\/100 {
  --tw-gradient-from: #2ec7ff;
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/0 {
  --tw-gradient-from: #eafaff00;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/5 {
  --tw-gradient-from: #eafaff0d;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/10 {
  --tw-gradient-from: #eafaff1a;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/20 {
  --tw-gradient-from: #eafaff33;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/25 {
  --tw-gradient-from: #eafaff40;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/30 {
  --tw-gradient-from: #eafaff4d;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/40 {
  --tw-gradient-from: #eafaff66;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/50 {
  --tw-gradient-from: #eafaff80;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/60 {
  --tw-gradient-from: #eafaff99;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/70 {
  --tw-gradient-from: #eafaffb3;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/75 {
  --tw-gradient-from: #eafaffbf;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/80 {
  --tw-gradient-from: #eafaffcc;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/90 {
  --tw-gradient-from: #eafaffe6;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/95 {
  --tw-gradient-from: #eafafff2;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .from-infoBackground\/100 {
  --tw-gradient-from: #eafaff;
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#app .via-success {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352, var(--tw-gradient-to);
}

#app .via-successBackground {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff, var(--tw-gradient-to);
}

#app .via-error {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d, var(--tw-gradient-to);
}

#app .via-errorBackground {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd, var(--tw-gradient-to);
}

#app .via-info {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff, var(--tw-gradient-to);
}

#app .via-infoBackground {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff, var(--tw-gradient-to);
}

#app .via-success\/0 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35200, var(--tw-gradient-to);
}

#app .via-success\/5 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a3520d, var(--tw-gradient-to);
}

#app .via-success\/10 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a3521a, var(--tw-gradient-to);
}

#app .via-success\/20 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35233, var(--tw-gradient-to);
}

#app .via-success\/25 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35240, var(--tw-gradient-to);
}

#app .via-success\/30 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a3524d, var(--tw-gradient-to);
}

#app .via-success\/40 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35266, var(--tw-gradient-to);
}

#app .via-success\/50 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35280, var(--tw-gradient-to);
}

#app .via-success\/60 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a35299, var(--tw-gradient-to);
}

#app .via-success\/70 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352b3, var(--tw-gradient-to);
}

#app .via-success\/75 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352bf, var(--tw-gradient-to);
}

#app .via-success\/80 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352cc, var(--tw-gradient-to);
}

#app .via-success\/90 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352e6, var(--tw-gradient-to);
}

#app .via-success\/95 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352f2, var(--tw-gradient-to);
}

#app .via-success\/100 {
  --tw-gradient-to: #00a35200;
  --tw-gradient-stops: var(--tw-gradient-from), #00a352, var(--tw-gradient-to);
}

#app .via-successBackground\/0 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff00, var(--tw-gradient-to);
}

#app .via-successBackground\/5 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff0d, var(--tw-gradient-to);
}

#app .via-successBackground\/10 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff1a, var(--tw-gradient-to);
}

#app .via-successBackground\/20 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff33, var(--tw-gradient-to);
}

#app .via-successBackground\/25 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff40, var(--tw-gradient-to);
}

#app .via-successBackground\/30 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff4d, var(--tw-gradient-to);
}

#app .via-successBackground\/40 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff66, var(--tw-gradient-to);
}

#app .via-successBackground\/50 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff80, var(--tw-gradient-to);
}

#app .via-successBackground\/60 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff99, var(--tw-gradient-to);
}

#app .via-successBackground\/70 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffb3, var(--tw-gradient-to);
}

#app .via-successBackground\/75 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffbf, var(--tw-gradient-to);
}

#app .via-successBackground\/80 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffcc, var(--tw-gradient-to);
}

#app .via-successBackground\/90 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffe6, var(--tw-gradient-to);
}

#app .via-successBackground\/95 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafafff2, var(--tw-gradient-to);
}

#app .via-successBackground\/100 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff, var(--tw-gradient-to);
}

#app .via-error\/0 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d00, var(--tw-gradient-to);
}

#app .via-error\/5 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d0d, var(--tw-gradient-to);
}

#app .via-error\/10 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d1a, var(--tw-gradient-to);
}

#app .via-error\/20 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d33, var(--tw-gradient-to);
}

#app .via-error\/25 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d40, var(--tw-gradient-to);
}

#app .via-error\/30 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d4d, var(--tw-gradient-to);
}

#app .via-error\/40 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d66, var(--tw-gradient-to);
}

#app .via-error\/50 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d80, var(--tw-gradient-to);
}

#app .via-error\/60 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d99, var(--tw-gradient-to);
}

#app .via-error\/70 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2db3, var(--tw-gradient-to);
}

#app .via-error\/75 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2dbf, var(--tw-gradient-to);
}

#app .via-error\/80 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2dcc, var(--tw-gradient-to);
}

#app .via-error\/90 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2de6, var(--tw-gradient-to);
}

#app .via-error\/95 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2df2, var(--tw-gradient-to);
}

#app .via-error\/100 {
  --tw-gradient-to: #ea3b2d00;
  --tw-gradient-stops: var(--tw-gradient-from), #ea3b2d, var(--tw-gradient-to);
}

#app .via-errorBackground\/0 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd00, var(--tw-gradient-to);
}

#app .via-errorBackground\/5 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd0d, var(--tw-gradient-to);
}

#app .via-errorBackground\/10 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd1a, var(--tw-gradient-to);
}

#app .via-errorBackground\/20 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd33, var(--tw-gradient-to);
}

#app .via-errorBackground\/25 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd40, var(--tw-gradient-to);
}

#app .via-errorBackground\/30 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd4d, var(--tw-gradient-to);
}

#app .via-errorBackground\/40 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd66, var(--tw-gradient-to);
}

#app .via-errorBackground\/50 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd80, var(--tw-gradient-to);
}

#app .via-errorBackground\/60 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd99, var(--tw-gradient-to);
}

#app .via-errorBackground\/70 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cdb3, var(--tw-gradient-to);
}

#app .via-errorBackground\/75 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cdbf, var(--tw-gradient-to);
}

#app .via-errorBackground\/80 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cdcc, var(--tw-gradient-to);
}

#app .via-errorBackground\/90 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cde6, var(--tw-gradient-to);
}

#app .via-errorBackground\/95 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cdf2, var(--tw-gradient-to);
}

#app .via-errorBackground\/100 {
  --tw-gradient-to: #ffd0cd00;
  --tw-gradient-stops: var(--tw-gradient-from), #ffd0cd, var(--tw-gradient-to);
}

#app .via-info\/0 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff00, var(--tw-gradient-to);
}

#app .via-info\/5 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff0d, var(--tw-gradient-to);
}

#app .via-info\/10 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff1a, var(--tw-gradient-to);
}

#app .via-info\/20 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff33, var(--tw-gradient-to);
}

#app .via-info\/25 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff40, var(--tw-gradient-to);
}

#app .via-info\/30 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff4d, var(--tw-gradient-to);
}

#app .via-info\/40 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff66, var(--tw-gradient-to);
}

#app .via-info\/50 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff80, var(--tw-gradient-to);
}

#app .via-info\/60 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff99, var(--tw-gradient-to);
}

#app .via-info\/70 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ffb3, var(--tw-gradient-to);
}

#app .via-info\/75 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ffbf, var(--tw-gradient-to);
}

#app .via-info\/80 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ffcc, var(--tw-gradient-to);
}

#app .via-info\/90 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ffe6, var(--tw-gradient-to);
}

#app .via-info\/95 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7fff2, var(--tw-gradient-to);
}

#app .via-info\/100 {
  --tw-gradient-to: #2ec7ff00;
  --tw-gradient-stops: var(--tw-gradient-from), #2ec7ff, var(--tw-gradient-to);
}

#app .via-infoBackground\/0 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff00, var(--tw-gradient-to);
}

#app .via-infoBackground\/5 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff0d, var(--tw-gradient-to);
}

#app .via-infoBackground\/10 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff1a, var(--tw-gradient-to);
}

#app .via-infoBackground\/20 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff33, var(--tw-gradient-to);
}

#app .via-infoBackground\/25 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff40, var(--tw-gradient-to);
}

#app .via-infoBackground\/30 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff4d, var(--tw-gradient-to);
}

#app .via-infoBackground\/40 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff66, var(--tw-gradient-to);
}

#app .via-infoBackground\/50 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff80, var(--tw-gradient-to);
}

#app .via-infoBackground\/60 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff99, var(--tw-gradient-to);
}

#app .via-infoBackground\/70 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffb3, var(--tw-gradient-to);
}

#app .via-infoBackground\/75 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffbf, var(--tw-gradient-to);
}

#app .via-infoBackground\/80 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffcc, var(--tw-gradient-to);
}

#app .via-infoBackground\/90 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaffe6, var(--tw-gradient-to);
}

#app .via-infoBackground\/95 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafafff2, var(--tw-gradient-to);
}

#app .via-infoBackground\/100 {
  --tw-gradient-to: #eafaff00;
  --tw-gradient-stops: var(--tw-gradient-from), #eafaff, var(--tw-gradient-to);
}

#app .to-success {
  --tw-gradient-to: #00a352;
}

#app .to-successBackground {
  --tw-gradient-to: #eafaff;
}

#app .to-error {
  --tw-gradient-to: #ea3b2d;
}

#app .to-errorBackground {
  --tw-gradient-to: #ffd0cd;
}

#app .to-info {
  --tw-gradient-to: #2ec7ff;
}

#app .to-infoBackground {
  --tw-gradient-to: #eafaff;
}

#app .to-success\/0 {
  --tw-gradient-to: #00a35200;
}

#app .to-success\/5 {
  --tw-gradient-to: #00a3520d;
}

#app .to-success\/10 {
  --tw-gradient-to: #00a3521a;
}

#app .to-success\/20 {
  --tw-gradient-to: #00a35233;
}

#app .to-success\/25 {
  --tw-gradient-to: #00a35240;
}

#app .to-success\/30 {
  --tw-gradient-to: #00a3524d;
}

#app .to-success\/40 {
  --tw-gradient-to: #00a35266;
}

#app .to-success\/50 {
  --tw-gradient-to: #00a35280;
}

#app .to-success\/60 {
  --tw-gradient-to: #00a35299;
}

#app .to-success\/70 {
  --tw-gradient-to: #00a352b3;
}

#app .to-success\/75 {
  --tw-gradient-to: #00a352bf;
}

#app .to-success\/80 {
  --tw-gradient-to: #00a352cc;
}

#app .to-success\/90 {
  --tw-gradient-to: #00a352e6;
}

#app .to-success\/95 {
  --tw-gradient-to: #00a352f2;
}

#app .to-success\/100 {
  --tw-gradient-to: #00a352;
}

#app .to-successBackground\/0 {
  --tw-gradient-to: #eafaff00;
}

#app .to-successBackground\/5 {
  --tw-gradient-to: #eafaff0d;
}

#app .to-successBackground\/10 {
  --tw-gradient-to: #eafaff1a;
}

#app .to-successBackground\/20 {
  --tw-gradient-to: #eafaff33;
}

#app .to-successBackground\/25 {
  --tw-gradient-to: #eafaff40;
}

#app .to-successBackground\/30 {
  --tw-gradient-to: #eafaff4d;
}

#app .to-successBackground\/40 {
  --tw-gradient-to: #eafaff66;
}

#app .to-successBackground\/50 {
  --tw-gradient-to: #eafaff80;
}

#app .to-successBackground\/60 {
  --tw-gradient-to: #eafaff99;
}

#app .to-successBackground\/70 {
  --tw-gradient-to: #eafaffb3;
}

#app .to-successBackground\/75 {
  --tw-gradient-to: #eafaffbf;
}

#app .to-successBackground\/80 {
  --tw-gradient-to: #eafaffcc;
}

#app .to-successBackground\/90 {
  --tw-gradient-to: #eafaffe6;
}

#app .to-successBackground\/95 {
  --tw-gradient-to: #eafafff2;
}

#app .to-successBackground\/100 {
  --tw-gradient-to: #eafaff;
}

#app .to-error\/0 {
  --tw-gradient-to: #ea3b2d00;
}

#app .to-error\/5 {
  --tw-gradient-to: #ea3b2d0d;
}

#app .to-error\/10 {
  --tw-gradient-to: #ea3b2d1a;
}

#app .to-error\/20 {
  --tw-gradient-to: #ea3b2d33;
}

#app .to-error\/25 {
  --tw-gradient-to: #ea3b2d40;
}

#app .to-error\/30 {
  --tw-gradient-to: #ea3b2d4d;
}

#app .to-error\/40 {
  --tw-gradient-to: #ea3b2d66;
}

#app .to-error\/50 {
  --tw-gradient-to: #ea3b2d80;
}

#app .to-error\/60 {
  --tw-gradient-to: #ea3b2d99;
}

#app .to-error\/70 {
  --tw-gradient-to: #ea3b2db3;
}

#app .to-error\/75 {
  --tw-gradient-to: #ea3b2dbf;
}

#app .to-error\/80 {
  --tw-gradient-to: #ea3b2dcc;
}

#app .to-error\/90 {
  --tw-gradient-to: #ea3b2de6;
}

#app .to-error\/95 {
  --tw-gradient-to: #ea3b2df2;
}

#app .to-error\/100 {
  --tw-gradient-to: #ea3b2d;
}

#app .to-errorBackground\/0 {
  --tw-gradient-to: #ffd0cd00;
}

#app .to-errorBackground\/5 {
  --tw-gradient-to: #ffd0cd0d;
}

#app .to-errorBackground\/10 {
  --tw-gradient-to: #ffd0cd1a;
}

#app .to-errorBackground\/20 {
  --tw-gradient-to: #ffd0cd33;
}

#app .to-errorBackground\/25 {
  --tw-gradient-to: #ffd0cd40;
}

#app .to-errorBackground\/30 {
  --tw-gradient-to: #ffd0cd4d;
}

#app .to-errorBackground\/40 {
  --tw-gradient-to: #ffd0cd66;
}

#app .to-errorBackground\/50 {
  --tw-gradient-to: #ffd0cd80;
}

#app .to-errorBackground\/60 {
  --tw-gradient-to: #ffd0cd99;
}

#app .to-errorBackground\/70 {
  --tw-gradient-to: #ffd0cdb3;
}

#app .to-errorBackground\/75 {
  --tw-gradient-to: #ffd0cdbf;
}

#app .to-errorBackground\/80 {
  --tw-gradient-to: #ffd0cdcc;
}

#app .to-errorBackground\/90 {
  --tw-gradient-to: #ffd0cde6;
}

#app .to-errorBackground\/95 {
  --tw-gradient-to: #ffd0cdf2;
}

#app .to-errorBackground\/100 {
  --tw-gradient-to: #ffd0cd;
}

#app .to-info\/0 {
  --tw-gradient-to: #2ec7ff00;
}

#app .to-info\/5 {
  --tw-gradient-to: #2ec7ff0d;
}

#app .to-info\/10 {
  --tw-gradient-to: #2ec7ff1a;
}

#app .to-info\/20 {
  --tw-gradient-to: #2ec7ff33;
}

#app .to-info\/25 {
  --tw-gradient-to: #2ec7ff40;
}

#app .to-info\/30 {
  --tw-gradient-to: #2ec7ff4d;
}

#app .to-info\/40 {
  --tw-gradient-to: #2ec7ff66;
}

#app .to-info\/50 {
  --tw-gradient-to: #2ec7ff80;
}

#app .to-info\/60 {
  --tw-gradient-to: #2ec7ff99;
}

#app .to-info\/70 {
  --tw-gradient-to: #2ec7ffb3;
}

#app .to-info\/75 {
  --tw-gradient-to: #2ec7ffbf;
}

#app .to-info\/80 {
  --tw-gradient-to: #2ec7ffcc;
}

#app .to-info\/90 {
  --tw-gradient-to: #2ec7ffe6;
}

#app .to-info\/95 {
  --tw-gradient-to: #2ec7fff2;
}

#app .to-info\/100 {
  --tw-gradient-to: #2ec7ff;
}

#app .to-infoBackground\/0 {
  --tw-gradient-to: #eafaff00;
}

#app .to-infoBackground\/5 {
  --tw-gradient-to: #eafaff0d;
}

#app .to-infoBackground\/10 {
  --tw-gradient-to: #eafaff1a;
}

#app .to-infoBackground\/20 {
  --tw-gradient-to: #eafaff33;
}

#app .to-infoBackground\/25 {
  --tw-gradient-to: #eafaff40;
}

#app .to-infoBackground\/30 {
  --tw-gradient-to: #eafaff4d;
}

#app .to-infoBackground\/40 {
  --tw-gradient-to: #eafaff66;
}

#app .to-infoBackground\/50 {
  --tw-gradient-to: #eafaff80;
}

#app .to-infoBackground\/60 {
  --tw-gradient-to: #eafaff99;
}

#app .to-infoBackground\/70 {
  --tw-gradient-to: #eafaffb3;
}

#app .to-infoBackground\/75 {
  --tw-gradient-to: #eafaffbf;
}

#app .to-infoBackground\/80 {
  --tw-gradient-to: #eafaffcc;
}

#app .to-infoBackground\/90 {
  --tw-gradient-to: #eafaffe6;
}

#app .to-infoBackground\/95 {
  --tw-gradient-to: #eafafff2;
}

#app .to-infoBackground\/100 {
  --tw-gradient-to: #eafaff;
}

#app .fill-success {
  fill: #00a352;
}

#app .fill-successBackground {
  fill: #eafaff;
}

#app .fill-error {
  fill: #ea3b2d;
}

#app .fill-errorBackground {
  fill: #ffd0cd;
}

#app .fill-info {
  fill: #2ec7ff;
}

#app .fill-infoBackground {
  fill: #eafaff;
}

#app .fill-success\/0 {
  fill: #00a35200;
}

#app .fill-success\/5 {
  fill: #00a3520d;
}

#app .fill-success\/10 {
  fill: #00a3521a;
}

#app .fill-success\/20 {
  fill: #00a35233;
}

#app .fill-success\/25 {
  fill: #00a35240;
}

#app .fill-success\/30 {
  fill: #00a3524d;
}

#app .fill-success\/40 {
  fill: #00a35266;
}

#app .fill-success\/50 {
  fill: #00a35280;
}

#app .fill-success\/60 {
  fill: #00a35299;
}

#app .fill-success\/70 {
  fill: #00a352b3;
}

#app .fill-success\/75 {
  fill: #00a352bf;
}

#app .fill-success\/80 {
  fill: #00a352cc;
}

#app .fill-success\/90 {
  fill: #00a352e6;
}

#app .fill-success\/95 {
  fill: #00a352f2;
}

#app .fill-success\/100 {
  fill: #00a352;
}

#app .fill-successBackground\/0 {
  fill: #eafaff00;
}

#app .fill-successBackground\/5 {
  fill: #eafaff0d;
}

#app .fill-successBackground\/10 {
  fill: #eafaff1a;
}

#app .fill-successBackground\/20 {
  fill: #eafaff33;
}

#app .fill-successBackground\/25 {
  fill: #eafaff40;
}

#app .fill-successBackground\/30 {
  fill: #eafaff4d;
}

#app .fill-successBackground\/40 {
  fill: #eafaff66;
}

#app .fill-successBackground\/50 {
  fill: #eafaff80;
}

#app .fill-successBackground\/60 {
  fill: #eafaff99;
}

#app .fill-successBackground\/70 {
  fill: #eafaffb3;
}

#app .fill-successBackground\/75 {
  fill: #eafaffbf;
}

#app .fill-successBackground\/80 {
  fill: #eafaffcc;
}

#app .fill-successBackground\/90 {
  fill: #eafaffe6;
}

#app .fill-successBackground\/95 {
  fill: #eafafff2;
}

#app .fill-successBackground\/100 {
  fill: #eafaff;
}

#app .fill-error\/0 {
  fill: #ea3b2d00;
}

#app .fill-error\/5 {
  fill: #ea3b2d0d;
}

#app .fill-error\/10 {
  fill: #ea3b2d1a;
}

#app .fill-error\/20 {
  fill: #ea3b2d33;
}

#app .fill-error\/25 {
  fill: #ea3b2d40;
}

#app .fill-error\/30 {
  fill: #ea3b2d4d;
}

#app .fill-error\/40 {
  fill: #ea3b2d66;
}

#app .fill-error\/50 {
  fill: #ea3b2d80;
}

#app .fill-error\/60 {
  fill: #ea3b2d99;
}

#app .fill-error\/70 {
  fill: #ea3b2db3;
}

#app .fill-error\/75 {
  fill: #ea3b2dbf;
}

#app .fill-error\/80 {
  fill: #ea3b2dcc;
}

#app .fill-error\/90 {
  fill: #ea3b2de6;
}

#app .fill-error\/95 {
  fill: #ea3b2df2;
}

#app .fill-error\/100 {
  fill: #ea3b2d;
}

#app .fill-errorBackground\/0 {
  fill: #ffd0cd00;
}

#app .fill-errorBackground\/5 {
  fill: #ffd0cd0d;
}

#app .fill-errorBackground\/10 {
  fill: #ffd0cd1a;
}

#app .fill-errorBackground\/20 {
  fill: #ffd0cd33;
}

#app .fill-errorBackground\/25 {
  fill: #ffd0cd40;
}

#app .fill-errorBackground\/30 {
  fill: #ffd0cd4d;
}

#app .fill-errorBackground\/40 {
  fill: #ffd0cd66;
}

#app .fill-errorBackground\/50 {
  fill: #ffd0cd80;
}

#app .fill-errorBackground\/60 {
  fill: #ffd0cd99;
}

#app .fill-errorBackground\/70 {
  fill: #ffd0cdb3;
}

#app .fill-errorBackground\/75 {
  fill: #ffd0cdbf;
}

#app .fill-errorBackground\/80 {
  fill: #ffd0cdcc;
}

#app .fill-errorBackground\/90 {
  fill: #ffd0cde6;
}

#app .fill-errorBackground\/95 {
  fill: #ffd0cdf2;
}

#app .fill-errorBackground\/100 {
  fill: #ffd0cd;
}

#app .fill-info\/0 {
  fill: #2ec7ff00;
}

#app .fill-info\/5 {
  fill: #2ec7ff0d;
}

#app .fill-info\/10 {
  fill: #2ec7ff1a;
}

#app .fill-info\/20 {
  fill: #2ec7ff33;
}

#app .fill-info\/25 {
  fill: #2ec7ff40;
}

#app .fill-info\/30 {
  fill: #2ec7ff4d;
}

#app .fill-info\/40 {
  fill: #2ec7ff66;
}

#app .fill-info\/50 {
  fill: #2ec7ff80;
}

#app .fill-info\/60 {
  fill: #2ec7ff99;
}

#app .fill-info\/70 {
  fill: #2ec7ffb3;
}

#app .fill-info\/75 {
  fill: #2ec7ffbf;
}

#app .fill-info\/80 {
  fill: #2ec7ffcc;
}

#app .fill-info\/90 {
  fill: #2ec7ffe6;
}

#app .fill-info\/95 {
  fill: #2ec7fff2;
}

#app .fill-info\/100 {
  fill: #2ec7ff;
}

#app .fill-infoBackground\/0 {
  fill: #eafaff00;
}

#app .fill-infoBackground\/5 {
  fill: #eafaff0d;
}

#app .fill-infoBackground\/10 {
  fill: #eafaff1a;
}

#app .fill-infoBackground\/20 {
  fill: #eafaff33;
}

#app .fill-infoBackground\/25 {
  fill: #eafaff40;
}

#app .fill-infoBackground\/30 {
  fill: #eafaff4d;
}

#app .fill-infoBackground\/40 {
  fill: #eafaff66;
}

#app .fill-infoBackground\/50 {
  fill: #eafaff80;
}

#app .fill-infoBackground\/60 {
  fill: #eafaff99;
}

#app .fill-infoBackground\/70 {
  fill: #eafaffb3;
}

#app .fill-infoBackground\/75 {
  fill: #eafaffbf;
}

#app .fill-infoBackground\/80 {
  fill: #eafaffcc;
}

#app .fill-infoBackground\/90 {
  fill: #eafaffe6;
}

#app .fill-infoBackground\/95 {
  fill: #eafafff2;
}

#app .fill-infoBackground\/100 {
  fill: #eafaff;
}

#app .stroke-success {
  stroke: #00a352;
}

#app .stroke-successBackground {
  stroke: #eafaff;
}

#app .stroke-error {
  stroke: #ea3b2d;
}

#app .stroke-errorBackground {
  stroke: #ffd0cd;
}

#app .stroke-info {
  stroke: #2ec7ff;
}

#app .stroke-infoBackground {
  stroke: #eafaff;
}

#app .stroke-success\/0 {
  stroke: #00a35200;
}

#app .stroke-success\/5 {
  stroke: #00a3520d;
}

#app .stroke-success\/10 {
  stroke: #00a3521a;
}

#app .stroke-success\/20 {
  stroke: #00a35233;
}

#app .stroke-success\/25 {
  stroke: #00a35240;
}

#app .stroke-success\/30 {
  stroke: #00a3524d;
}

#app .stroke-success\/40 {
  stroke: #00a35266;
}

#app .stroke-success\/50 {
  stroke: #00a35280;
}

#app .stroke-success\/60 {
  stroke: #00a35299;
}

#app .stroke-success\/70 {
  stroke: #00a352b3;
}

#app .stroke-success\/75 {
  stroke: #00a352bf;
}

#app .stroke-success\/80 {
  stroke: #00a352cc;
}

#app .stroke-success\/90 {
  stroke: #00a352e6;
}

#app .stroke-success\/95 {
  stroke: #00a352f2;
}

#app .stroke-success\/100 {
  stroke: #00a352;
}

#app .stroke-successBackground\/0 {
  stroke: #eafaff00;
}

#app .stroke-successBackground\/5 {
  stroke: #eafaff0d;
}

#app .stroke-successBackground\/10 {
  stroke: #eafaff1a;
}

#app .stroke-successBackground\/20 {
  stroke: #eafaff33;
}

#app .stroke-successBackground\/25 {
  stroke: #eafaff40;
}

#app .stroke-successBackground\/30 {
  stroke: #eafaff4d;
}

#app .stroke-successBackground\/40 {
  stroke: #eafaff66;
}

#app .stroke-successBackground\/50 {
  stroke: #eafaff80;
}

#app .stroke-successBackground\/60 {
  stroke: #eafaff99;
}

#app .stroke-successBackground\/70 {
  stroke: #eafaffb3;
}

#app .stroke-successBackground\/75 {
  stroke: #eafaffbf;
}

#app .stroke-successBackground\/80 {
  stroke: #eafaffcc;
}

#app .stroke-successBackground\/90 {
  stroke: #eafaffe6;
}

#app .stroke-successBackground\/95 {
  stroke: #eafafff2;
}

#app .stroke-successBackground\/100 {
  stroke: #eafaff;
}

#app .stroke-error\/0 {
  stroke: #ea3b2d00;
}

#app .stroke-error\/5 {
  stroke: #ea3b2d0d;
}

#app .stroke-error\/10 {
  stroke: #ea3b2d1a;
}

#app .stroke-error\/20 {
  stroke: #ea3b2d33;
}

#app .stroke-error\/25 {
  stroke: #ea3b2d40;
}

#app .stroke-error\/30 {
  stroke: #ea3b2d4d;
}

#app .stroke-error\/40 {
  stroke: #ea3b2d66;
}

#app .stroke-error\/50 {
  stroke: #ea3b2d80;
}

#app .stroke-error\/60 {
  stroke: #ea3b2d99;
}

#app .stroke-error\/70 {
  stroke: #ea3b2db3;
}

#app .stroke-error\/75 {
  stroke: #ea3b2dbf;
}

#app .stroke-error\/80 {
  stroke: #ea3b2dcc;
}

#app .stroke-error\/90 {
  stroke: #ea3b2de6;
}

#app .stroke-error\/95 {
  stroke: #ea3b2df2;
}

#app .stroke-error\/100 {
  stroke: #ea3b2d;
}

#app .stroke-errorBackground\/0 {
  stroke: #ffd0cd00;
}

#app .stroke-errorBackground\/5 {
  stroke: #ffd0cd0d;
}

#app .stroke-errorBackground\/10 {
  stroke: #ffd0cd1a;
}

#app .stroke-errorBackground\/20 {
  stroke: #ffd0cd33;
}

#app .stroke-errorBackground\/25 {
  stroke: #ffd0cd40;
}

#app .stroke-errorBackground\/30 {
  stroke: #ffd0cd4d;
}

#app .stroke-errorBackground\/40 {
  stroke: #ffd0cd66;
}

#app .stroke-errorBackground\/50 {
  stroke: #ffd0cd80;
}

#app .stroke-errorBackground\/60 {
  stroke: #ffd0cd99;
}

#app .stroke-errorBackground\/70 {
  stroke: #ffd0cdb3;
}

#app .stroke-errorBackground\/75 {
  stroke: #ffd0cdbf;
}

#app .stroke-errorBackground\/80 {
  stroke: #ffd0cdcc;
}

#app .stroke-errorBackground\/90 {
  stroke: #ffd0cde6;
}

#app .stroke-errorBackground\/95 {
  stroke: #ffd0cdf2;
}

#app .stroke-errorBackground\/100 {
  stroke: #ffd0cd;
}

#app .stroke-info\/0 {
  stroke: #2ec7ff00;
}

#app .stroke-info\/5 {
  stroke: #2ec7ff0d;
}

#app .stroke-info\/10 {
  stroke: #2ec7ff1a;
}

#app .stroke-info\/20 {
  stroke: #2ec7ff33;
}

#app .stroke-info\/25 {
  stroke: #2ec7ff40;
}

#app .stroke-info\/30 {
  stroke: #2ec7ff4d;
}

#app .stroke-info\/40 {
  stroke: #2ec7ff66;
}

#app .stroke-info\/50 {
  stroke: #2ec7ff80;
}

#app .stroke-info\/60 {
  stroke: #2ec7ff99;
}

#app .stroke-info\/70 {
  stroke: #2ec7ffb3;
}

#app .stroke-info\/75 {
  stroke: #2ec7ffbf;
}

#app .stroke-info\/80 {
  stroke: #2ec7ffcc;
}

#app .stroke-info\/90 {
  stroke: #2ec7ffe6;
}

#app .stroke-info\/95 {
  stroke: #2ec7fff2;
}

#app .stroke-info\/100 {
  stroke: #2ec7ff;
}

#app .stroke-infoBackground\/0 {
  stroke: #eafaff00;
}

#app .stroke-infoBackground\/5 {
  stroke: #eafaff0d;
}

#app .stroke-infoBackground\/10 {
  stroke: #eafaff1a;
}

#app .stroke-infoBackground\/20 {
  stroke: #eafaff33;
}

#app .stroke-infoBackground\/25 {
  stroke: #eafaff40;
}

#app .stroke-infoBackground\/30 {
  stroke: #eafaff4d;
}

#app .stroke-infoBackground\/40 {
  stroke: #eafaff66;
}

#app .stroke-infoBackground\/50 {
  stroke: #eafaff80;
}

#app .stroke-infoBackground\/60 {
  stroke: #eafaff99;
}

#app .stroke-infoBackground\/70 {
  stroke: #eafaffb3;
}

#app .stroke-infoBackground\/75 {
  stroke: #eafaffbf;
}

#app .stroke-infoBackground\/80 {
  stroke: #eafaffcc;
}

#app .stroke-infoBackground\/90 {
  stroke: #eafaffe6;
}

#app .stroke-infoBackground\/95 {
  stroke: #eafafff2;
}

#app .stroke-infoBackground\/100 {
  stroke: #eafaff;
}

#app .object-contain {
  object-fit: contain;
}

#app .object-cover {
  object-fit: cover;
}

#app .p-2 {
  padding: .5rem;
}

#app .p-5 {
  padding: 1.25rem;
}

#app .p-3 {
  padding: .75rem;
}

#app .p-4 {
  padding: 1rem;
}

#app .p-6 {
  padding: 1.5rem;
}

#app .p-\[9\] {
  padding: 9px;
}

#app .p-\[1\.5px\] {
  padding: 1.5px;
}

#app .p-\[5px\] {
  padding: 5px;
}

#app .p-8 {
  padding: 2rem;
}

#app .p-1 {
  padding: .25rem;
}

#app .p-1\.5 {
  padding: .375rem;
}

#app .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

#app .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

#app .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

#app .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

#app .px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

#app .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#app .px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

#app .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#app .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#app .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

#app .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

#app .px-\[15\%\] {
  padding-left: 15%;
  padding-right: 15%;
}

#app .px-\[10\%\] {
  padding-left: 10%;
  padding-right: 10%;
}

#app .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#app .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#app .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

#app .pt-12 {
  padding-top: 3rem;
}

#app .pl-\[1\%\] {
  padding-left: 1%;
}

#app .pl-1 {
  padding-left: .25rem;
}

#app .pl-2 {
  padding-left: .5rem;
}

#app .pr-1 {
  padding-right: .25rem;
}

#app .pr-5 {
  padding-right: 1.25rem;
}

#app .pt-4 {
  padding-top: 1rem;
}

#app .pb-4 {
  padding-bottom: 1rem;
}

#app .pt-2 {
  padding-top: .5rem;
}

#app .pt-\[3\.75rem\] {
  padding-top: 3.75rem;
}

#app .pl-\[20\] {
  padding-left: 20px;
}

#app .pr-\[20\] {
  padding-right: 20px;
}

#app .pt-\[5\] {
  padding-top: 5px;
}

#app .pl-\[30\] {
  padding-left: 30px;
}

#app .pl-\[40px\] {
  padding-left: 40px;
}

#app .pr-\[40px\] {
  padding-right: 40px;
}

#app .pt-\[16\%\] {
  padding-top: 16%;
}

#app .pb-6 {
  padding-bottom: 1.5rem;
}

#app .pt-\[20\] {
  padding-top: 20px;
}

#app .pb-3 {
  padding-bottom: .75rem;
}

#app .pb-5 {
  padding-bottom: 1.25rem;
}

#app .pt-5 {
  padding-top: 1.25rem;
}

#app .pl-\[16px\] {
  padding-left: 16px;
}

#app .pl-0 {
  padding-left: 0;
}

#app .pl-\[12px\] {
  padding-left: 12px;
}

#app .pr-2 {
  padding-right: .5rem;
}

#app .pr-8 {
  padding-right: 2rem;
}

#app .pl-8 {
  padding-left: 2rem;
}

#app .pl-3 {
  padding-left: .75rem;
}

#app .pt-1 {
  padding-top: .25rem;
}

#app .pb-2 {
  padding-bottom: .5rem;
}

#app .pb-11 {
  padding-bottom: 2.75rem;
}

#app .pl-\[10px\] {
  padding-left: 10px;
}

#app .pt-\[10\] {
  padding-top: 10px;
}

#app .pb-\[30\] {
  padding-bottom: 30px;
}

#app .pl-\[24px\] {
  padding-left: 24px;
}

#app .pl-\[4px\] {
  padding-left: 4px;
}

#app .pb-1 {
  padding-bottom: .25rem;
}

#app .pl-\[2px\] {
  padding-left: 2px;
}

#app .text-left {
  text-align: left;
}

#app .text-center {
  text-align: center;
}

#app .text-right {
  text-align: right;
}

#app .text-start {
  text-align: start;
}

#app .align-top {
  vertical-align: top;
}

#app .align-middle {
  vertical-align: middle;
}

#app .font-gilroy {
  font-family: Gilroy, sans-serif;
}

#app .font-inter {
  font-family: Inter, sans-serif;
}

#app .font-bungee {
  font-family: Bungee, sans-serif;
}

#app .text-\[30px\] {
  font-size: 30px;
}

#app .text-5xl {
  font-size: 3rem;
  line-height: 1;
}

#app .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

#app .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

#app .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#app .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

#app .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

#app .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

#app .text-\[16px\] {
  font-size: 16px;
}

#app .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

#app .text-\[26px\] {
  font-size: 26px;
}

#app .text-\[20px\] {
  font-size: 20px;
}

#app .text-\[15px\] {
  font-size: 15px;
}

#app .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

#app .text-\[0\.7rem\] {
  font-size: .7rem;
}

#app .text-\[12px\] {
  font-size: 12px;
}

#app .text-\[14px\] {
  font-size: 14px;
}

#app .text-\[48px\] {
  font-size: 48px;
}

#app .text-\[40px\] {
  font-size: 40px;
}

#app .text-\[42px\] {
  font-size: 42px;
}

#app .text-\[36px\] {
  font-size: 36px;
}

#app .text-\[11px\] {
  font-size: 11px;
}

#app .font-semibold {
  font-weight: 600;
}

#app .font-bold {
  font-weight: 700;
}

#app .font-medium {
  font-weight: 500;
}

#app .font-extrabold {
  font-weight: 800;
}

#app .font-normal {
  font-weight: 400;
}

#app .font-\[1000\] {
  font-weight: 1000;
}

#app .font-\[500\] {
  font-weight: 500;
}

#app .uppercase {
  text-transform: uppercase;
}

#app .normal-case {
  text-transform: none;
}

#app .italic {
  font-style: italic;
}

#app .not-italic {
  font-style: normal;
}

#app .leading-\[20px\] {
  line-height: 20px;
}

#app .leading-tight {
  line-height: 1.25;
}

#app .leading-none {
  line-height: 1;
}

#app .leading-7 {
  line-height: 1.75rem;
}

#app .leading-\[0\.7\] {
  line-height: .7;
}

#app .leading-\[26px\] {
  line-height: 26px;
}

#app .leading-\[16px\] {
  line-height: 16px;
}

#app .leading-5 {
  line-height: 1.25rem;
}

#app .leading-\[38\.73px\] {
  line-height: 38.73px;
}

#app .tracking-wide {
  letter-spacing: .025em;
}

#app .tracking-tight {
  letter-spacing: -.025em;
}

#app .tracking-tighter {
  letter-spacing: -.05em;
}

#app .tracking-\[0\.15px\] {
  letter-spacing: .15px;
}

#app .tracking-\[0\.46px\] {
  letter-spacing: .46px;
}

#app .tracking-wider {
  letter-spacing: .05em;
}

#app .text-success {
  --tw-text-opacity: 1;
  color: rgb(0 163 82 / var(--tw-text-opacity));
}

#app .text-successBackground {
  --tw-text-opacity: 1;
  color: rgb(234 250 255 / var(--tw-text-opacity));
}

#app .text-error {
  --tw-text-opacity: 1;
  color: rgb(234 59 45 / var(--tw-text-opacity));
}

#app .text-errorBackground {
  --tw-text-opacity: 1;
  color: rgb(255 208 205 / var(--tw-text-opacity));
}

#app .text-info {
  --tw-text-opacity: 1;
  color: rgb(46 199 255 / var(--tw-text-opacity));
}

#app .text-infoBackground {
  --tw-text-opacity: 1;
  color: rgb(234 250 255 / var(--tw-text-opacity));
}

#app .text-success\/0 {
  color: #00a35200;
}

#app .text-success\/5 {
  color: #00a3520d;
}

#app .text-success\/10 {
  color: #00a3521a;
}

#app .text-success\/20 {
  color: #00a35233;
}

#app .text-success\/25 {
  color: #00a35240;
}

#app .text-success\/30 {
  color: #00a3524d;
}

#app .text-success\/40 {
  color: #00a35266;
}

#app .text-success\/50 {
  color: #00a35280;
}

#app .text-success\/60 {
  color: #00a35299;
}

#app .text-success\/70 {
  color: #00a352b3;
}

#app .text-success\/75 {
  color: #00a352bf;
}

#app .text-success\/80 {
  color: #00a352cc;
}

#app .text-success\/90 {
  color: #00a352e6;
}

#app .text-success\/95 {
  color: #00a352f2;
}

#app .text-success\/100 {
  color: #00a352;
}

#app .text-successBackground\/0 {
  color: #eafaff00;
}

#app .text-successBackground\/5 {
  color: #eafaff0d;
}

#app .text-successBackground\/10 {
  color: #eafaff1a;
}

#app .text-successBackground\/20 {
  color: #eafaff33;
}

#app .text-successBackground\/25 {
  color: #eafaff40;
}

#app .text-successBackground\/30 {
  color: #eafaff4d;
}

#app .text-successBackground\/40 {
  color: #eafaff66;
}

#app .text-successBackground\/50 {
  color: #eafaff80;
}

#app .text-successBackground\/60 {
  color: #eafaff99;
}

#app .text-successBackground\/70 {
  color: #eafaffb3;
}

#app .text-successBackground\/75 {
  color: #eafaffbf;
}

#app .text-successBackground\/80 {
  color: #eafaffcc;
}

#app .text-successBackground\/90 {
  color: #eafaffe6;
}

#app .text-successBackground\/95 {
  color: #eafafff2;
}

#app .text-successBackground\/100 {
  color: #eafaff;
}

#app .text-error\/0 {
  color: #ea3b2d00;
}

#app .text-error\/5 {
  color: #ea3b2d0d;
}

#app .text-error\/10 {
  color: #ea3b2d1a;
}

#app .text-error\/20 {
  color: #ea3b2d33;
}

#app .text-error\/25 {
  color: #ea3b2d40;
}

#app .text-error\/30 {
  color: #ea3b2d4d;
}

#app .text-error\/40 {
  color: #ea3b2d66;
}

#app .text-error\/50 {
  color: #ea3b2d80;
}

#app .text-error\/60 {
  color: #ea3b2d99;
}

#app .text-error\/70 {
  color: #ea3b2db3;
}

#app .text-error\/75 {
  color: #ea3b2dbf;
}

#app .text-error\/80 {
  color: #ea3b2dcc;
}

#app .text-error\/90 {
  color: #ea3b2de6;
}

#app .text-error\/95 {
  color: #ea3b2df2;
}

#app .text-error\/100 {
  color: #ea3b2d;
}

#app .text-errorBackground\/0 {
  color: #ffd0cd00;
}

#app .text-errorBackground\/5 {
  color: #ffd0cd0d;
}

#app .text-errorBackground\/10 {
  color: #ffd0cd1a;
}

#app .text-errorBackground\/20 {
  color: #ffd0cd33;
}

#app .text-errorBackground\/25 {
  color: #ffd0cd40;
}

#app .text-errorBackground\/30 {
  color: #ffd0cd4d;
}

#app .text-errorBackground\/40 {
  color: #ffd0cd66;
}

#app .text-errorBackground\/50 {
  color: #ffd0cd80;
}

#app .text-errorBackground\/60 {
  color: #ffd0cd99;
}

#app .text-errorBackground\/70 {
  color: #ffd0cdb3;
}

#app .text-errorBackground\/75 {
  color: #ffd0cdbf;
}

#app .text-errorBackground\/80 {
  color: #ffd0cdcc;
}

#app .text-errorBackground\/90 {
  color: #ffd0cde6;
}

#app .text-errorBackground\/95 {
  color: #ffd0cdf2;
}

#app .text-errorBackground\/100 {
  color: #ffd0cd;
}

#app .text-info\/0 {
  color: #2ec7ff00;
}

#app .text-info\/5 {
  color: #2ec7ff0d;
}

#app .text-info\/10 {
  color: #2ec7ff1a;
}

#app .text-info\/20 {
  color: #2ec7ff33;
}

#app .text-info\/25 {
  color: #2ec7ff40;
}

#app .text-info\/30 {
  color: #2ec7ff4d;
}

#app .text-info\/40 {
  color: #2ec7ff66;
}

#app .text-info\/50 {
  color: #2ec7ff80;
}

#app .text-info\/60 {
  color: #2ec7ff99;
}

#app .text-info\/70 {
  color: #2ec7ffb3;
}

#app .text-info\/75 {
  color: #2ec7ffbf;
}

#app .text-info\/80 {
  color: #2ec7ffcc;
}

#app .text-info\/90 {
  color: #2ec7ffe6;
}

#app .text-info\/95 {
  color: #2ec7fff2;
}

#app .text-info\/100 {
  color: #2ec7ff;
}

#app .text-infoBackground\/0 {
  color: #eafaff00;
}

#app .text-infoBackground\/5 {
  color: #eafaff0d;
}

#app .text-infoBackground\/10 {
  color: #eafaff1a;
}

#app .text-infoBackground\/20 {
  color: #eafaff33;
}

#app .text-infoBackground\/25 {
  color: #eafaff40;
}

#app .text-infoBackground\/30 {
  color: #eafaff4d;
}

#app .text-infoBackground\/40 {
  color: #eafaff66;
}

#app .text-infoBackground\/50 {
  color: #eafaff80;
}

#app .text-infoBackground\/60 {
  color: #eafaff99;
}

#app .text-infoBackground\/70 {
  color: #eafaffb3;
}

#app .text-infoBackground\/75 {
  color: #eafaffbf;
}

#app .text-infoBackground\/80 {
  color: #eafaffcc;
}

#app .text-infoBackground\/90 {
  color: #eafaffe6;
}

#app .text-infoBackground\/95 {
  color: #eafafff2;
}

#app .text-infoBackground\/100 {
  color: #eafaff;
}

#app .text-\[\#e8735a\] {
  --tw-text-opacity: 1;
  color: rgb(232 115 90 / var(--tw-text-opacity));
}

#app .text-\[\#3b3e45\] {
  --tw-text-opacity: 1;
  color: rgb(59 62 69 / var(--tw-text-opacity));
}

#app .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#app .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

#app .text-headerText {
  color: #000000de;
}

#app .text-secondary {
  --tw-text-opacity: 1;
  color: rgb(156 39 176 / var(--tw-text-opacity));
}

#app .text-title {
  --tw-text-opacity: 1;
  color: rgb(221 91 135 / var(--tw-text-opacity));
}

#app .text-adminTableHead {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

#app .text-adminTableBody {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

#app .text-\[\#4f46e5\] {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

#app .text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#app .text-buttonBorder {
  --tw-text-opacity: 1;
  color: rgb(128 106 210 / var(--tw-text-opacity));
}

#app .text-onboardingButtonColor {
  --tw-text-opacity: 1;
  color: rgb(224 132 141 / var(--tw-text-opacity));
}

#app .text-\[black\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#app .underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

#app .no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

#app .decoration-success {
  -webkit-text-decoration-color: #00a352;
  text-decoration-color: #00a352;
}

#app .decoration-successBackground {
  -webkit-text-decoration-color: #eafaff;
  text-decoration-color: #eafaff;
}

#app .decoration-error {
  -webkit-text-decoration-color: #ea3b2d;
  text-decoration-color: #ea3b2d;
}

#app .decoration-errorBackground {
  -webkit-text-decoration-color: #ffd0cd;
  text-decoration-color: #ffd0cd;
}

#app .decoration-info {
  -webkit-text-decoration-color: #2ec7ff;
  text-decoration-color: #2ec7ff;
}

#app .decoration-infoBackground {
  -webkit-text-decoration-color: #eafaff;
  text-decoration-color: #eafaff;
}

#app .decoration-success\/0 {
  -webkit-text-decoration-color: #00a35200;
  text-decoration-color: #00a35200;
}

#app .decoration-success\/5 {
  -webkit-text-decoration-color: #00a3520d;
  text-decoration-color: #00a3520d;
}

#app .decoration-success\/10 {
  -webkit-text-decoration-color: #00a3521a;
  text-decoration-color: #00a3521a;
}

#app .decoration-success\/20 {
  -webkit-text-decoration-color: #00a35233;
  text-decoration-color: #00a35233;
}

#app .decoration-success\/25 {
  -webkit-text-decoration-color: #00a35240;
  text-decoration-color: #00a35240;
}

#app .decoration-success\/30 {
  -webkit-text-decoration-color: #00a3524d;
  text-decoration-color: #00a3524d;
}

#app .decoration-success\/40 {
  -webkit-text-decoration-color: #00a35266;
  text-decoration-color: #00a35266;
}

#app .decoration-success\/50 {
  -webkit-text-decoration-color: #00a35280;
  text-decoration-color: #00a35280;
}

#app .decoration-success\/60 {
  -webkit-text-decoration-color: #00a35299;
  text-decoration-color: #00a35299;
}

#app .decoration-success\/70 {
  -webkit-text-decoration-color: #00a352b3;
  text-decoration-color: #00a352b3;
}

#app .decoration-success\/75 {
  -webkit-text-decoration-color: #00a352bf;
  text-decoration-color: #00a352bf;
}

#app .decoration-success\/80 {
  -webkit-text-decoration-color: #00a352cc;
  text-decoration-color: #00a352cc;
}

#app .decoration-success\/90 {
  -webkit-text-decoration-color: #00a352e6;
  text-decoration-color: #00a352e6;
}

#app .decoration-success\/95 {
  -webkit-text-decoration-color: #00a352f2;
  text-decoration-color: #00a352f2;
}

#app .decoration-success\/100 {
  -webkit-text-decoration-color: #00a352;
  text-decoration-color: #00a352;
}

#app .decoration-successBackground\/0 {
  -webkit-text-decoration-color: #eafaff00;
  text-decoration-color: #eafaff00;
}

#app .decoration-successBackground\/5 {
  -webkit-text-decoration-color: #eafaff0d;
  text-decoration-color: #eafaff0d;
}

#app .decoration-successBackground\/10 {
  -webkit-text-decoration-color: #eafaff1a;
  text-decoration-color: #eafaff1a;
}

#app .decoration-successBackground\/20 {
  -webkit-text-decoration-color: #eafaff33;
  text-decoration-color: #eafaff33;
}

#app .decoration-successBackground\/25 {
  -webkit-text-decoration-color: #eafaff40;
  text-decoration-color: #eafaff40;
}

#app .decoration-successBackground\/30 {
  -webkit-text-decoration-color: #eafaff4d;
  text-decoration-color: #eafaff4d;
}

#app .decoration-successBackground\/40 {
  -webkit-text-decoration-color: #eafaff66;
  text-decoration-color: #eafaff66;
}

#app .decoration-successBackground\/50 {
  -webkit-text-decoration-color: #eafaff80;
  text-decoration-color: #eafaff80;
}

#app .decoration-successBackground\/60 {
  -webkit-text-decoration-color: #eafaff99;
  text-decoration-color: #eafaff99;
}

#app .decoration-successBackground\/70 {
  -webkit-text-decoration-color: #eafaffb3;
  text-decoration-color: #eafaffb3;
}

#app .decoration-successBackground\/75 {
  -webkit-text-decoration-color: #eafaffbf;
  text-decoration-color: #eafaffbf;
}

#app .decoration-successBackground\/80 {
  -webkit-text-decoration-color: #eafaffcc;
  text-decoration-color: #eafaffcc;
}

#app .decoration-successBackground\/90 {
  -webkit-text-decoration-color: #eafaffe6;
  text-decoration-color: #eafaffe6;
}

#app .decoration-successBackground\/95 {
  -webkit-text-decoration-color: #eafafff2;
  text-decoration-color: #eafafff2;
}

#app .decoration-successBackground\/100 {
  -webkit-text-decoration-color: #eafaff;
  text-decoration-color: #eafaff;
}

#app .decoration-error\/0 {
  -webkit-text-decoration-color: #ea3b2d00;
  text-decoration-color: #ea3b2d00;
}

#app .decoration-error\/5 {
  -webkit-text-decoration-color: #ea3b2d0d;
  text-decoration-color: #ea3b2d0d;
}

#app .decoration-error\/10 {
  -webkit-text-decoration-color: #ea3b2d1a;
  text-decoration-color: #ea3b2d1a;
}

#app .decoration-error\/20 {
  -webkit-text-decoration-color: #ea3b2d33;
  text-decoration-color: #ea3b2d33;
}

#app .decoration-error\/25 {
  -webkit-text-decoration-color: #ea3b2d40;
  text-decoration-color: #ea3b2d40;
}

#app .decoration-error\/30 {
  -webkit-text-decoration-color: #ea3b2d4d;
  text-decoration-color: #ea3b2d4d;
}

#app .decoration-error\/40 {
  -webkit-text-decoration-color: #ea3b2d66;
  text-decoration-color: #ea3b2d66;
}

#app .decoration-error\/50 {
  -webkit-text-decoration-color: #ea3b2d80;
  text-decoration-color: #ea3b2d80;
}

#app .decoration-error\/60 {
  -webkit-text-decoration-color: #ea3b2d99;
  text-decoration-color: #ea3b2d99;
}

#app .decoration-error\/70 {
  -webkit-text-decoration-color: #ea3b2db3;
  text-decoration-color: #ea3b2db3;
}

#app .decoration-error\/75 {
  -webkit-text-decoration-color: #ea3b2dbf;
  text-decoration-color: #ea3b2dbf;
}

#app .decoration-error\/80 {
  -webkit-text-decoration-color: #ea3b2dcc;
  text-decoration-color: #ea3b2dcc;
}

#app .decoration-error\/90 {
  -webkit-text-decoration-color: #ea3b2de6;
  text-decoration-color: #ea3b2de6;
}

#app .decoration-error\/95 {
  -webkit-text-decoration-color: #ea3b2df2;
  text-decoration-color: #ea3b2df2;
}

#app .decoration-error\/100 {
  -webkit-text-decoration-color: #ea3b2d;
  text-decoration-color: #ea3b2d;
}

#app .decoration-errorBackground\/0 {
  -webkit-text-decoration-color: #ffd0cd00;
  text-decoration-color: #ffd0cd00;
}

#app .decoration-errorBackground\/5 {
  -webkit-text-decoration-color: #ffd0cd0d;
  text-decoration-color: #ffd0cd0d;
}

#app .decoration-errorBackground\/10 {
  -webkit-text-decoration-color: #ffd0cd1a;
  text-decoration-color: #ffd0cd1a;
}

#app .decoration-errorBackground\/20 {
  -webkit-text-decoration-color: #ffd0cd33;
  text-decoration-color: #ffd0cd33;
}

#app .decoration-errorBackground\/25 {
  -webkit-text-decoration-color: #ffd0cd40;
  text-decoration-color: #ffd0cd40;
}

#app .decoration-errorBackground\/30 {
  -webkit-text-decoration-color: #ffd0cd4d;
  text-decoration-color: #ffd0cd4d;
}

#app .decoration-errorBackground\/40 {
  -webkit-text-decoration-color: #ffd0cd66;
  text-decoration-color: #ffd0cd66;
}

#app .decoration-errorBackground\/50 {
  -webkit-text-decoration-color: #ffd0cd80;
  text-decoration-color: #ffd0cd80;
}

#app .decoration-errorBackground\/60 {
  -webkit-text-decoration-color: #ffd0cd99;
  text-decoration-color: #ffd0cd99;
}

#app .decoration-errorBackground\/70 {
  -webkit-text-decoration-color: #ffd0cdb3;
  text-decoration-color: #ffd0cdb3;
}

#app .decoration-errorBackground\/75 {
  -webkit-text-decoration-color: #ffd0cdbf;
  text-decoration-color: #ffd0cdbf;
}

#app .decoration-errorBackground\/80 {
  -webkit-text-decoration-color: #ffd0cdcc;
  text-decoration-color: #ffd0cdcc;
}

#app .decoration-errorBackground\/90 {
  -webkit-text-decoration-color: #ffd0cde6;
  text-decoration-color: #ffd0cde6;
}

#app .decoration-errorBackground\/95 {
  -webkit-text-decoration-color: #ffd0cdf2;
  text-decoration-color: #ffd0cdf2;
}

#app .decoration-errorBackground\/100 {
  -webkit-text-decoration-color: #ffd0cd;
  text-decoration-color: #ffd0cd;
}

#app .decoration-info\/0 {
  -webkit-text-decoration-color: #2ec7ff00;
  text-decoration-color: #2ec7ff00;
}

#app .decoration-info\/5 {
  -webkit-text-decoration-color: #2ec7ff0d;
  text-decoration-color: #2ec7ff0d;
}

#app .decoration-info\/10 {
  -webkit-text-decoration-color: #2ec7ff1a;
  text-decoration-color: #2ec7ff1a;
}

#app .decoration-info\/20 {
  -webkit-text-decoration-color: #2ec7ff33;
  text-decoration-color: #2ec7ff33;
}

#app .decoration-info\/25 {
  -webkit-text-decoration-color: #2ec7ff40;
  text-decoration-color: #2ec7ff40;
}

#app .decoration-info\/30 {
  -webkit-text-decoration-color: #2ec7ff4d;
  text-decoration-color: #2ec7ff4d;
}

#app .decoration-info\/40 {
  -webkit-text-decoration-color: #2ec7ff66;
  text-decoration-color: #2ec7ff66;
}

#app .decoration-info\/50 {
  -webkit-text-decoration-color: #2ec7ff80;
  text-decoration-color: #2ec7ff80;
}

#app .decoration-info\/60 {
  -webkit-text-decoration-color: #2ec7ff99;
  text-decoration-color: #2ec7ff99;
}

#app .decoration-info\/70 {
  -webkit-text-decoration-color: #2ec7ffb3;
  text-decoration-color: #2ec7ffb3;
}

#app .decoration-info\/75 {
  -webkit-text-decoration-color: #2ec7ffbf;
  text-decoration-color: #2ec7ffbf;
}

#app .decoration-info\/80 {
  -webkit-text-decoration-color: #2ec7ffcc;
  text-decoration-color: #2ec7ffcc;
}

#app .decoration-info\/90 {
  -webkit-text-decoration-color: #2ec7ffe6;
  text-decoration-color: #2ec7ffe6;
}

#app .decoration-info\/95 {
  -webkit-text-decoration-color: #2ec7fff2;
  text-decoration-color: #2ec7fff2;
}

#app .decoration-info\/100 {
  -webkit-text-decoration-color: #2ec7ff;
  text-decoration-color: #2ec7ff;
}

#app .decoration-infoBackground\/0 {
  -webkit-text-decoration-color: #eafaff00;
  text-decoration-color: #eafaff00;
}

#app .decoration-infoBackground\/5 {
  -webkit-text-decoration-color: #eafaff0d;
  text-decoration-color: #eafaff0d;
}

#app .decoration-infoBackground\/10 {
  -webkit-text-decoration-color: #eafaff1a;
  text-decoration-color: #eafaff1a;
}

#app .decoration-infoBackground\/20 {
  -webkit-text-decoration-color: #eafaff33;
  text-decoration-color: #eafaff33;
}

#app .decoration-infoBackground\/25 {
  -webkit-text-decoration-color: #eafaff40;
  text-decoration-color: #eafaff40;
}

#app .decoration-infoBackground\/30 {
  -webkit-text-decoration-color: #eafaff4d;
  text-decoration-color: #eafaff4d;
}

#app .decoration-infoBackground\/40 {
  -webkit-text-decoration-color: #eafaff66;
  text-decoration-color: #eafaff66;
}

#app .decoration-infoBackground\/50 {
  -webkit-text-decoration-color: #eafaff80;
  text-decoration-color: #eafaff80;
}

#app .decoration-infoBackground\/60 {
  -webkit-text-decoration-color: #eafaff99;
  text-decoration-color: #eafaff99;
}

#app .decoration-infoBackground\/70 {
  -webkit-text-decoration-color: #eafaffb3;
  text-decoration-color: #eafaffb3;
}

#app .decoration-infoBackground\/75 {
  -webkit-text-decoration-color: #eafaffbf;
  text-decoration-color: #eafaffbf;
}

#app .decoration-infoBackground\/80 {
  -webkit-text-decoration-color: #eafaffcc;
  text-decoration-color: #eafaffcc;
}

#app .decoration-infoBackground\/90 {
  -webkit-text-decoration-color: #eafaffe6;
  text-decoration-color: #eafaffe6;
}

#app .decoration-infoBackground\/95 {
  -webkit-text-decoration-color: #eafafff2;
  text-decoration-color: #eafafff2;
}

#app .decoration-infoBackground\/100 {
  -webkit-text-decoration-color: #eafaff;
  text-decoration-color: #eafaff;
}

#app .antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app .placeholder-success::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(0 163 82 / var(--tw-placeholder-opacity));
}

#app .placeholder-successBackground::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(234 250 255 / var(--tw-placeholder-opacity));
}

#app .placeholder-error::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(234 59 45 / var(--tw-placeholder-opacity));
}

#app .placeholder-errorBackground::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 208 205 / var(--tw-placeholder-opacity));
}

#app .placeholder-info::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(46 199 255 / var(--tw-placeholder-opacity));
}

#app .placeholder-infoBackground::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(234 250 255 / var(--tw-placeholder-opacity));
}

#app .placeholder-success\/0::placeholder {
  color: #00a35200;
}

#app .placeholder-success\/5::placeholder {
  color: #00a3520d;
}

#app .placeholder-success\/10::placeholder {
  color: #00a3521a;
}

#app .placeholder-success\/20::placeholder {
  color: #00a35233;
}

#app .placeholder-success\/25::placeholder {
  color: #00a35240;
}

#app .placeholder-success\/30::placeholder {
  color: #00a3524d;
}

#app .placeholder-success\/40::placeholder {
  color: #00a35266;
}

#app .placeholder-success\/50::placeholder {
  color: #00a35280;
}

#app .placeholder-success\/60::placeholder {
  color: #00a35299;
}

#app .placeholder-success\/70::placeholder {
  color: #00a352b3;
}

#app .placeholder-success\/75::placeholder {
  color: #00a352bf;
}

#app .placeholder-success\/80::placeholder {
  color: #00a352cc;
}

#app .placeholder-success\/90::placeholder {
  color: #00a352e6;
}

#app .placeholder-success\/95::placeholder {
  color: #00a352f2;
}

#app .placeholder-success\/100::placeholder {
  color: #00a352;
}

#app .placeholder-successBackground\/0::placeholder {
  color: #eafaff00;
}

#app .placeholder-successBackground\/5::placeholder {
  color: #eafaff0d;
}

#app .placeholder-successBackground\/10::placeholder {
  color: #eafaff1a;
}

#app .placeholder-successBackground\/20::placeholder {
  color: #eafaff33;
}

#app .placeholder-successBackground\/25::placeholder {
  color: #eafaff40;
}

#app .placeholder-successBackground\/30::placeholder {
  color: #eafaff4d;
}

#app .placeholder-successBackground\/40::placeholder {
  color: #eafaff66;
}

#app .placeholder-successBackground\/50::placeholder {
  color: #eafaff80;
}

#app .placeholder-successBackground\/60::placeholder {
  color: #eafaff99;
}

#app .placeholder-successBackground\/70::placeholder {
  color: #eafaffb3;
}

#app .placeholder-successBackground\/75::placeholder {
  color: #eafaffbf;
}

#app .placeholder-successBackground\/80::placeholder {
  color: #eafaffcc;
}

#app .placeholder-successBackground\/90::placeholder {
  color: #eafaffe6;
}

#app .placeholder-successBackground\/95::placeholder {
  color: #eafafff2;
}

#app .placeholder-successBackground\/100::placeholder {
  color: #eafaff;
}

#app .placeholder-error\/0::placeholder {
  color: #ea3b2d00;
}

#app .placeholder-error\/5::placeholder {
  color: #ea3b2d0d;
}

#app .placeholder-error\/10::placeholder {
  color: #ea3b2d1a;
}

#app .placeholder-error\/20::placeholder {
  color: #ea3b2d33;
}

#app .placeholder-error\/25::placeholder {
  color: #ea3b2d40;
}

#app .placeholder-error\/30::placeholder {
  color: #ea3b2d4d;
}

#app .placeholder-error\/40::placeholder {
  color: #ea3b2d66;
}

#app .placeholder-error\/50::placeholder {
  color: #ea3b2d80;
}

#app .placeholder-error\/60::placeholder {
  color: #ea3b2d99;
}

#app .placeholder-error\/70::placeholder {
  color: #ea3b2db3;
}

#app .placeholder-error\/75::placeholder {
  color: #ea3b2dbf;
}

#app .placeholder-error\/80::placeholder {
  color: #ea3b2dcc;
}

#app .placeholder-error\/90::placeholder {
  color: #ea3b2de6;
}

#app .placeholder-error\/95::placeholder {
  color: #ea3b2df2;
}

#app .placeholder-error\/100::placeholder {
  color: #ea3b2d;
}

#app .placeholder-errorBackground\/0::placeholder {
  color: #ffd0cd00;
}

#app .placeholder-errorBackground\/5::placeholder {
  color: #ffd0cd0d;
}

#app .placeholder-errorBackground\/10::placeholder {
  color: #ffd0cd1a;
}

#app .placeholder-errorBackground\/20::placeholder {
  color: #ffd0cd33;
}

#app .placeholder-errorBackground\/25::placeholder {
  color: #ffd0cd40;
}

#app .placeholder-errorBackground\/30::placeholder {
  color: #ffd0cd4d;
}

#app .placeholder-errorBackground\/40::placeholder {
  color: #ffd0cd66;
}

#app .placeholder-errorBackground\/50::placeholder {
  color: #ffd0cd80;
}

#app .placeholder-errorBackground\/60::placeholder {
  color: #ffd0cd99;
}

#app .placeholder-errorBackground\/70::placeholder {
  color: #ffd0cdb3;
}

#app .placeholder-errorBackground\/75::placeholder {
  color: #ffd0cdbf;
}

#app .placeholder-errorBackground\/80::placeholder {
  color: #ffd0cdcc;
}

#app .placeholder-errorBackground\/90::placeholder {
  color: #ffd0cde6;
}

#app .placeholder-errorBackground\/95::placeholder {
  color: #ffd0cdf2;
}

#app .placeholder-errorBackground\/100::placeholder {
  color: #ffd0cd;
}

#app .placeholder-info\/0::placeholder {
  color: #2ec7ff00;
}

#app .placeholder-info\/5::placeholder {
  color: #2ec7ff0d;
}

#app .placeholder-info\/10::placeholder {
  color: #2ec7ff1a;
}

#app .placeholder-info\/20::placeholder {
  color: #2ec7ff33;
}

#app .placeholder-info\/25::placeholder {
  color: #2ec7ff40;
}

#app .placeholder-info\/30::placeholder {
  color: #2ec7ff4d;
}

#app .placeholder-info\/40::placeholder {
  color: #2ec7ff66;
}

#app .placeholder-info\/50::placeholder {
  color: #2ec7ff80;
}

#app .placeholder-info\/60::placeholder {
  color: #2ec7ff99;
}

#app .placeholder-info\/70::placeholder {
  color: #2ec7ffb3;
}

#app .placeholder-info\/75::placeholder {
  color: #2ec7ffbf;
}

#app .placeholder-info\/80::placeholder {
  color: #2ec7ffcc;
}

#app .placeholder-info\/90::placeholder {
  color: #2ec7ffe6;
}

#app .placeholder-info\/95::placeholder {
  color: #2ec7fff2;
}

#app .placeholder-info\/100::placeholder {
  color: #2ec7ff;
}

#app .placeholder-infoBackground\/0::placeholder {
  color: #eafaff00;
}

#app .placeholder-infoBackground\/5::placeholder {
  color: #eafaff0d;
}

#app .placeholder-infoBackground\/10::placeholder {
  color: #eafaff1a;
}

#app .placeholder-infoBackground\/20::placeholder {
  color: #eafaff33;
}

#app .placeholder-infoBackground\/25::placeholder {
  color: #eafaff40;
}

#app .placeholder-infoBackground\/30::placeholder {
  color: #eafaff4d;
}

#app .placeholder-infoBackground\/40::placeholder {
  color: #eafaff66;
}

#app .placeholder-infoBackground\/50::placeholder {
  color: #eafaff80;
}

#app .placeholder-infoBackground\/60::placeholder {
  color: #eafaff99;
}

#app .placeholder-infoBackground\/70::placeholder {
  color: #eafaffb3;
}

#app .placeholder-infoBackground\/75::placeholder {
  color: #eafaffbf;
}

#app .placeholder-infoBackground\/80::placeholder {
  color: #eafaffcc;
}

#app .placeholder-infoBackground\/90::placeholder {
  color: #eafaffe6;
}

#app .placeholder-infoBackground\/95::placeholder {
  color: #eafafff2;
}

#app .placeholder-infoBackground\/100::placeholder {
  color: #eafaff;
}

#app .caret-success {
  caret-color: #00a352;
}

#app .caret-successBackground {
  caret-color: #eafaff;
}

#app .caret-error {
  caret-color: #ea3b2d;
}

#app .caret-errorBackground {
  caret-color: #ffd0cd;
}

#app .caret-info {
  caret-color: #2ec7ff;
}

#app .caret-infoBackground {
  caret-color: #eafaff;
}

#app .caret-success\/0 {
  caret-color: #00a35200;
}

#app .caret-success\/5 {
  caret-color: #00a3520d;
}

#app .caret-success\/10 {
  caret-color: #00a3521a;
}

#app .caret-success\/20 {
  caret-color: #00a35233;
}

#app .caret-success\/25 {
  caret-color: #00a35240;
}

#app .caret-success\/30 {
  caret-color: #00a3524d;
}

#app .caret-success\/40 {
  caret-color: #00a35266;
}

#app .caret-success\/50 {
  caret-color: #00a35280;
}

#app .caret-success\/60 {
  caret-color: #00a35299;
}

#app .caret-success\/70 {
  caret-color: #00a352b3;
}

#app .caret-success\/75 {
  caret-color: #00a352bf;
}

#app .caret-success\/80 {
  caret-color: #00a352cc;
}

#app .caret-success\/90 {
  caret-color: #00a352e6;
}

#app .caret-success\/95 {
  caret-color: #00a352f2;
}

#app .caret-success\/100 {
  caret-color: #00a352;
}

#app .caret-successBackground\/0 {
  caret-color: #eafaff00;
}

#app .caret-successBackground\/5 {
  caret-color: #eafaff0d;
}

#app .caret-successBackground\/10 {
  caret-color: #eafaff1a;
}

#app .caret-successBackground\/20 {
  caret-color: #eafaff33;
}

#app .caret-successBackground\/25 {
  caret-color: #eafaff40;
}

#app .caret-successBackground\/30 {
  caret-color: #eafaff4d;
}

#app .caret-successBackground\/40 {
  caret-color: #eafaff66;
}

#app .caret-successBackground\/50 {
  caret-color: #eafaff80;
}

#app .caret-successBackground\/60 {
  caret-color: #eafaff99;
}

#app .caret-successBackground\/70 {
  caret-color: #eafaffb3;
}

#app .caret-successBackground\/75 {
  caret-color: #eafaffbf;
}

#app .caret-successBackground\/80 {
  caret-color: #eafaffcc;
}

#app .caret-successBackground\/90 {
  caret-color: #eafaffe6;
}

#app .caret-successBackground\/95 {
  caret-color: #eafafff2;
}

#app .caret-successBackground\/100 {
  caret-color: #eafaff;
}

#app .caret-error\/0 {
  caret-color: #ea3b2d00;
}

#app .caret-error\/5 {
  caret-color: #ea3b2d0d;
}

#app .caret-error\/10 {
  caret-color: #ea3b2d1a;
}

#app .caret-error\/20 {
  caret-color: #ea3b2d33;
}

#app .caret-error\/25 {
  caret-color: #ea3b2d40;
}

#app .caret-error\/30 {
  caret-color: #ea3b2d4d;
}

#app .caret-error\/40 {
  caret-color: #ea3b2d66;
}

#app .caret-error\/50 {
  caret-color: #ea3b2d80;
}

#app .caret-error\/60 {
  caret-color: #ea3b2d99;
}

#app .caret-error\/70 {
  caret-color: #ea3b2db3;
}

#app .caret-error\/75 {
  caret-color: #ea3b2dbf;
}

#app .caret-error\/80 {
  caret-color: #ea3b2dcc;
}

#app .caret-error\/90 {
  caret-color: #ea3b2de6;
}

#app .caret-error\/95 {
  caret-color: #ea3b2df2;
}

#app .caret-error\/100 {
  caret-color: #ea3b2d;
}

#app .caret-errorBackground\/0 {
  caret-color: #ffd0cd00;
}

#app .caret-errorBackground\/5 {
  caret-color: #ffd0cd0d;
}

#app .caret-errorBackground\/10 {
  caret-color: #ffd0cd1a;
}

#app .caret-errorBackground\/20 {
  caret-color: #ffd0cd33;
}

#app .caret-errorBackground\/25 {
  caret-color: #ffd0cd40;
}

#app .caret-errorBackground\/30 {
  caret-color: #ffd0cd4d;
}

#app .caret-errorBackground\/40 {
  caret-color: #ffd0cd66;
}

#app .caret-errorBackground\/50 {
  caret-color: #ffd0cd80;
}

#app .caret-errorBackground\/60 {
  caret-color: #ffd0cd99;
}

#app .caret-errorBackground\/70 {
  caret-color: #ffd0cdb3;
}

#app .caret-errorBackground\/75 {
  caret-color: #ffd0cdbf;
}

#app .caret-errorBackground\/80 {
  caret-color: #ffd0cdcc;
}

#app .caret-errorBackground\/90 {
  caret-color: #ffd0cde6;
}

#app .caret-errorBackground\/95 {
  caret-color: #ffd0cdf2;
}

#app .caret-errorBackground\/100 {
  caret-color: #ffd0cd;
}

#app .caret-info\/0 {
  caret-color: #2ec7ff00;
}

#app .caret-info\/5 {
  caret-color: #2ec7ff0d;
}

#app .caret-info\/10 {
  caret-color: #2ec7ff1a;
}

#app .caret-info\/20 {
  caret-color: #2ec7ff33;
}

#app .caret-info\/25 {
  caret-color: #2ec7ff40;
}

#app .caret-info\/30 {
  caret-color: #2ec7ff4d;
}

#app .caret-info\/40 {
  caret-color: #2ec7ff66;
}

#app .caret-info\/50 {
  caret-color: #2ec7ff80;
}

#app .caret-info\/60 {
  caret-color: #2ec7ff99;
}

#app .caret-info\/70 {
  caret-color: #2ec7ffb3;
}

#app .caret-info\/75 {
  caret-color: #2ec7ffbf;
}

#app .caret-info\/80 {
  caret-color: #2ec7ffcc;
}

#app .caret-info\/90 {
  caret-color: #2ec7ffe6;
}

#app .caret-info\/95 {
  caret-color: #2ec7fff2;
}

#app .caret-info\/100 {
  caret-color: #2ec7ff;
}

#app .caret-infoBackground\/0 {
  caret-color: #eafaff00;
}

#app .caret-infoBackground\/5 {
  caret-color: #eafaff0d;
}

#app .caret-infoBackground\/10 {
  caret-color: #eafaff1a;
}

#app .caret-infoBackground\/20 {
  caret-color: #eafaff33;
}

#app .caret-infoBackground\/25 {
  caret-color: #eafaff40;
}

#app .caret-infoBackground\/30 {
  caret-color: #eafaff4d;
}

#app .caret-infoBackground\/40 {
  caret-color: #eafaff66;
}

#app .caret-infoBackground\/50 {
  caret-color: #eafaff80;
}

#app .caret-infoBackground\/60 {
  caret-color: #eafaff99;
}

#app .caret-infoBackground\/70 {
  caret-color: #eafaffb3;
}

#app .caret-infoBackground\/75 {
  caret-color: #eafaffbf;
}

#app .caret-infoBackground\/80 {
  caret-color: #eafaffcc;
}

#app .caret-infoBackground\/90 {
  caret-color: #eafaffe6;
}

#app .caret-infoBackground\/95 {
  caret-color: #eafafff2;
}

#app .caret-infoBackground\/100 {
  caret-color: #eafaff;
}

#app .accent-success {
  accent-color: #00a352;
}

#app .accent-successBackground {
  accent-color: #eafaff;
}

#app .accent-error {
  accent-color: #ea3b2d;
}

#app .accent-errorBackground {
  accent-color: #ffd0cd;
}

#app .accent-info {
  accent-color: #2ec7ff;
}

#app .accent-infoBackground {
  accent-color: #eafaff;
}

#app .accent-success\/0 {
  accent-color: #00a35200;
}

#app .accent-success\/5 {
  accent-color: #00a3520d;
}

#app .accent-success\/10 {
  accent-color: #00a3521a;
}

#app .accent-success\/20 {
  accent-color: #00a35233;
}

#app .accent-success\/25 {
  accent-color: #00a35240;
}

#app .accent-success\/30 {
  accent-color: #00a3524d;
}

#app .accent-success\/40 {
  accent-color: #00a35266;
}

#app .accent-success\/50 {
  accent-color: #00a35280;
}

#app .accent-success\/60 {
  accent-color: #00a35299;
}

#app .accent-success\/70 {
  accent-color: #00a352b3;
}

#app .accent-success\/75 {
  accent-color: #00a352bf;
}

#app .accent-success\/80 {
  accent-color: #00a352cc;
}

#app .accent-success\/90 {
  accent-color: #00a352e6;
}

#app .accent-success\/95 {
  accent-color: #00a352f2;
}

#app .accent-success\/100 {
  accent-color: #00a352;
}

#app .accent-successBackground\/0 {
  accent-color: #eafaff00;
}

#app .accent-successBackground\/5 {
  accent-color: #eafaff0d;
}

#app .accent-successBackground\/10 {
  accent-color: #eafaff1a;
}

#app .accent-successBackground\/20 {
  accent-color: #eafaff33;
}

#app .accent-successBackground\/25 {
  accent-color: #eafaff40;
}

#app .accent-successBackground\/30 {
  accent-color: #eafaff4d;
}

#app .accent-successBackground\/40 {
  accent-color: #eafaff66;
}

#app .accent-successBackground\/50 {
  accent-color: #eafaff80;
}

#app .accent-successBackground\/60 {
  accent-color: #eafaff99;
}

#app .accent-successBackground\/70 {
  accent-color: #eafaffb3;
}

#app .accent-successBackground\/75 {
  accent-color: #eafaffbf;
}

#app .accent-successBackground\/80 {
  accent-color: #eafaffcc;
}

#app .accent-successBackground\/90 {
  accent-color: #eafaffe6;
}

#app .accent-successBackground\/95 {
  accent-color: #eafafff2;
}

#app .accent-successBackground\/100 {
  accent-color: #eafaff;
}

#app .accent-error\/0 {
  accent-color: #ea3b2d00;
}

#app .accent-error\/5 {
  accent-color: #ea3b2d0d;
}

#app .accent-error\/10 {
  accent-color: #ea3b2d1a;
}

#app .accent-error\/20 {
  accent-color: #ea3b2d33;
}

#app .accent-error\/25 {
  accent-color: #ea3b2d40;
}

#app .accent-error\/30 {
  accent-color: #ea3b2d4d;
}

#app .accent-error\/40 {
  accent-color: #ea3b2d66;
}

#app .accent-error\/50 {
  accent-color: #ea3b2d80;
}

#app .accent-error\/60 {
  accent-color: #ea3b2d99;
}

#app .accent-error\/70 {
  accent-color: #ea3b2db3;
}

#app .accent-error\/75 {
  accent-color: #ea3b2dbf;
}

#app .accent-error\/80 {
  accent-color: #ea3b2dcc;
}

#app .accent-error\/90 {
  accent-color: #ea3b2de6;
}

#app .accent-error\/95 {
  accent-color: #ea3b2df2;
}

#app .accent-error\/100 {
  accent-color: #ea3b2d;
}

#app .accent-errorBackground\/0 {
  accent-color: #ffd0cd00;
}

#app .accent-errorBackground\/5 {
  accent-color: #ffd0cd0d;
}

#app .accent-errorBackground\/10 {
  accent-color: #ffd0cd1a;
}

#app .accent-errorBackground\/20 {
  accent-color: #ffd0cd33;
}

#app .accent-errorBackground\/25 {
  accent-color: #ffd0cd40;
}

#app .accent-errorBackground\/30 {
  accent-color: #ffd0cd4d;
}

#app .accent-errorBackground\/40 {
  accent-color: #ffd0cd66;
}

#app .accent-errorBackground\/50 {
  accent-color: #ffd0cd80;
}

#app .accent-errorBackground\/60 {
  accent-color: #ffd0cd99;
}

#app .accent-errorBackground\/70 {
  accent-color: #ffd0cdb3;
}

#app .accent-errorBackground\/75 {
  accent-color: #ffd0cdbf;
}

#app .accent-errorBackground\/80 {
  accent-color: #ffd0cdcc;
}

#app .accent-errorBackground\/90 {
  accent-color: #ffd0cde6;
}

#app .accent-errorBackground\/95 {
  accent-color: #ffd0cdf2;
}

#app .accent-errorBackground\/100 {
  accent-color: #ffd0cd;
}

#app .accent-info\/0 {
  accent-color: #2ec7ff00;
}

#app .accent-info\/5 {
  accent-color: #2ec7ff0d;
}

#app .accent-info\/10 {
  accent-color: #2ec7ff1a;
}

#app .accent-info\/20 {
  accent-color: #2ec7ff33;
}

#app .accent-info\/25 {
  accent-color: #2ec7ff40;
}

#app .accent-info\/30 {
  accent-color: #2ec7ff4d;
}

#app .accent-info\/40 {
  accent-color: #2ec7ff66;
}

#app .accent-info\/50 {
  accent-color: #2ec7ff80;
}

#app .accent-info\/60 {
  accent-color: #2ec7ff99;
}

#app .accent-info\/70 {
  accent-color: #2ec7ffb3;
}

#app .accent-info\/75 {
  accent-color: #2ec7ffbf;
}

#app .accent-info\/80 {
  accent-color: #2ec7ffcc;
}

#app .accent-info\/90 {
  accent-color: #2ec7ffe6;
}

#app .accent-info\/95 {
  accent-color: #2ec7fff2;
}

#app .accent-info\/100 {
  accent-color: #2ec7ff;
}

#app .accent-infoBackground\/0 {
  accent-color: #eafaff00;
}

#app .accent-infoBackground\/5 {
  accent-color: #eafaff0d;
}

#app .accent-infoBackground\/10 {
  accent-color: #eafaff1a;
}

#app .accent-infoBackground\/20 {
  accent-color: #eafaff33;
}

#app .accent-infoBackground\/25 {
  accent-color: #eafaff40;
}

#app .accent-infoBackground\/30 {
  accent-color: #eafaff4d;
}

#app .accent-infoBackground\/40 {
  accent-color: #eafaff66;
}

#app .accent-infoBackground\/50 {
  accent-color: #eafaff80;
}

#app .accent-infoBackground\/60 {
  accent-color: #eafaff99;
}

#app .accent-infoBackground\/70 {
  accent-color: #eafaffb3;
}

#app .accent-infoBackground\/75 {
  accent-color: #eafaffbf;
}

#app .accent-infoBackground\/80 {
  accent-color: #eafaffcc;
}

#app .accent-infoBackground\/90 {
  accent-color: #eafaffe6;
}

#app .accent-infoBackground\/95 {
  accent-color: #eafafff2;
}

#app .accent-infoBackground\/100 {
  accent-color: #eafaff;
}

#app .opacity-25 {
  opacity: .25;
}

#app .opacity-75 {
  opacity: .75;
}

#app .opacity-50 {
  opacity: .5;
}

#app .opacity-100 {
  opacity: 1;
}

#app .opacity-0 {
  opacity: 0;
}

#app .opacity-70 {
  opacity: .7;
}

#app .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .shadow-main {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title {
  --tw-shadow-color: #dd5b87;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg {
  --tw-shadow-color: #f5f5f5;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary {
  --tw-shadow-color: #9c27b0;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg {
  --tw-shadow-color: #d9d9d9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button {
  --tw-shadow-color: #0000000a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline {
  --tw-shadow-color: #1976d280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton {
  --tw-shadow-color: #6f5acd;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder {
  --tw-shadow-color: #806ad2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText {
  --tw-shadow-color: #000000de;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success {
  --tw-shadow-color: #00a352;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground {
  --tw-shadow-color: #eafaff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error {
  --tw-shadow-color: #ea3b2d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground {
  --tw-shadow-color: #ffd0cd;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning {
  --tw-shadow-color: #ffb72e;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground {
  --tw-shadow-color: #fff9ed;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info {
  --tw-shadow-color: #2ec7ff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground {
  --tw-shadow-color: #eafaff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey {
  --tw-shadow-color: #eee;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg {
  --tw-shadow-color: #ff9fa9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg {
  --tw-shadow-color: #f1f1f1;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton {
  --tw-shadow-color: #e8735a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider {
  --tw-shadow-color: #00000036;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping {
  --tw-shadow-color: #f97316;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect {
  --tw-shadow-color: #fb923c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor {
  --tw-shadow-color: #e0848d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead {
  --tw-shadow-color: #6b7280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody {
  --tw-shadow-color: #111827;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/0 {
  --tw-shadow-color: #fff0;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/5 {
  --tw-shadow-color: #ffffff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/10 {
  --tw-shadow-color: #ffffff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/20 {
  --tw-shadow-color: #fff3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/25 {
  --tw-shadow-color: #ffffff40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/30 {
  --tw-shadow-color: #ffffff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/40 {
  --tw-shadow-color: #fff6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/50 {
  --tw-shadow-color: #ffffff80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/60 {
  --tw-shadow-color: #fff9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/70 {
  --tw-shadow-color: #ffffffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/75 {
  --tw-shadow-color: #ffffffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/80 {
  --tw-shadow-color: #fffc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/90 {
  --tw-shadow-color: #ffffffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/95 {
  --tw-shadow-color: #fffffff2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-main\/100 {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/0 {
  --tw-shadow-color: #dd5b8700;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/5 {
  --tw-shadow-color: #dd5b870d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/10 {
  --tw-shadow-color: #dd5b871a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/20 {
  --tw-shadow-color: #dd5b8733;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/25 {
  --tw-shadow-color: #dd5b8740;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/30 {
  --tw-shadow-color: #dd5b874d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/40 {
  --tw-shadow-color: #dd5b8766;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/50 {
  --tw-shadow-color: #dd5b8780;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/60 {
  --tw-shadow-color: #dd5b8799;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/70 {
  --tw-shadow-color: #dd5b87b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/75 {
  --tw-shadow-color: #dd5b87bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/80 {
  --tw-shadow-color: #dd5b87cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/90 {
  --tw-shadow-color: #dd5b87e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/95 {
  --tw-shadow-color: #dd5b87f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-title\/100 {
  --tw-shadow-color: #dd5b87;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/0 {
  --tw-shadow-color: #f5f5f500;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/5 {
  --tw-shadow-color: #f5f5f50d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/10 {
  --tw-shadow-color: #f5f5f51a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/20 {
  --tw-shadow-color: #f5f5f533;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/25 {
  --tw-shadow-color: #f5f5f540;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/30 {
  --tw-shadow-color: #f5f5f54d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/40 {
  --tw-shadow-color: #f5f5f566;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/50 {
  --tw-shadow-color: #f5f5f580;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/60 {
  --tw-shadow-color: #f5f5f599;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/70 {
  --tw-shadow-color: #f5f5f5b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/75 {
  --tw-shadow-color: #f5f5f5bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/80 {
  --tw-shadow-color: #f5f5f5cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/90 {
  --tw-shadow-color: #f5f5f5e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/95 {
  --tw-shadow-color: #f5f5f5f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-mainBg\/100 {
  --tw-shadow-color: #f5f5f5;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/0 {
  --tw-shadow-color: #9c27b000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/5 {
  --tw-shadow-color: #9c27b00d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/10 {
  --tw-shadow-color: #9c27b01a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/20 {
  --tw-shadow-color: #9c27b033;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/25 {
  --tw-shadow-color: #9c27b040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/30 {
  --tw-shadow-color: #9c27b04d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/40 {
  --tw-shadow-color: #9c27b066;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/50 {
  --tw-shadow-color: #9c27b080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/60 {
  --tw-shadow-color: #9c27b099;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/70 {
  --tw-shadow-color: #9c27b0b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/75 {
  --tw-shadow-color: #9c27b0bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/80 {
  --tw-shadow-color: #9c27b0cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/90 {
  --tw-shadow-color: #9c27b0e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/95 {
  --tw-shadow-color: #9c27b0f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-secondary\/100 {
  --tw-shadow-color: #9c27b0;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-black\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/0 {
  --tw-shadow-color: #fff0;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/5 {
  --tw-shadow-color: #ffffff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/10 {
  --tw-shadow-color: #ffffff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/20 {
  --tw-shadow-color: #fff3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/25 {
  --tw-shadow-color: #ffffff40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/30 {
  --tw-shadow-color: #ffffff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/40 {
  --tw-shadow-color: #fff6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/50 {
  --tw-shadow-color: #ffffff80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/60 {
  --tw-shadow-color: #fff9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/70 {
  --tw-shadow-color: #ffffffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/75 {
  --tw-shadow-color: #ffffffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/80 {
  --tw-shadow-color: #fffc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/90 {
  --tw-shadow-color: #ffffffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/95 {
  --tw-shadow-color: #fffffff2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-white\/100 {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/0 {
  --tw-shadow-color: #d9d9d900;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/5 {
  --tw-shadow-color: #d9d9d90d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/10 {
  --tw-shadow-color: #d9d9d91a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/20 {
  --tw-shadow-color: #d9d9d933;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/25 {
  --tw-shadow-color: #d9d9d940;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/30 {
  --tw-shadow-color: #d9d9d94d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/40 {
  --tw-shadow-color: #d9d9d966;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/50 {
  --tw-shadow-color: #d9d9d980;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/60 {
  --tw-shadow-color: #d9d9d999;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/70 {
  --tw-shadow-color: #d9d9d9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/75 {
  --tw-shadow-color: #d9d9d9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/80 {
  --tw-shadow-color: #d9d9d9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/90 {
  --tw-shadow-color: #d9d9d9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/95 {
  --tw-shadow-color: #d9d9d9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-darkerBg\/100 {
  --tw-shadow-color: #d9d9d9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-button\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/0 {
  --tw-shadow-color: #1976d200;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/5 {
  --tw-shadow-color: #1976d20d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/10 {
  --tw-shadow-color: #1976d21a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/20 {
  --tw-shadow-color: #1976d233;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/25 {
  --tw-shadow-color: #1976d240;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/30 {
  --tw-shadow-color: #1976d24d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/40 {
  --tw-shadow-color: #1976d266;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/50 {
  --tw-shadow-color: #1976d280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/60 {
  --tw-shadow-color: #1976d299;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/70 {
  --tw-shadow-color: #1976d2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/75 {
  --tw-shadow-color: #1976d2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/80 {
  --tw-shadow-color: #1976d2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/90 {
  --tw-shadow-color: #1976d2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/95 {
  --tw-shadow-color: #1976d2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-moonButtonOutline\/100 {
  --tw-shadow-color: #1976d2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/0 {
  --tw-shadow-color: #6f5acd00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/5 {
  --tw-shadow-color: #6f5acd0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/10 {
  --tw-shadow-color: #6f5acd1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/20 {
  --tw-shadow-color: #6f5acd33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/25 {
  --tw-shadow-color: #6f5acd40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/30 {
  --tw-shadow-color: #6f5acd4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/40 {
  --tw-shadow-color: #6f5acd66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/50 {
  --tw-shadow-color: #6f5acd80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/60 {
  --tw-shadow-color: #6f5acd99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/70 {
  --tw-shadow-color: #6f5acdb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/75 {
  --tw-shadow-color: #6f5acdbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/80 {
  --tw-shadow-color: #6f5acdcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/90 {
  --tw-shadow-color: #6f5acde6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/95 {
  --tw-shadow-color: #6f5acdf2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-primaryButton\/100 {
  --tw-shadow-color: #6f5acd;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/0 {
  --tw-shadow-color: #806ad200;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/5 {
  --tw-shadow-color: #806ad20d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/10 {
  --tw-shadow-color: #806ad21a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/20 {
  --tw-shadow-color: #806ad233;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/25 {
  --tw-shadow-color: #806ad240;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/30 {
  --tw-shadow-color: #806ad24d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/40 {
  --tw-shadow-color: #806ad266;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/50 {
  --tw-shadow-color: #806ad280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/60 {
  --tw-shadow-color: #806ad299;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/70 {
  --tw-shadow-color: #806ad2b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/75 {
  --tw-shadow-color: #806ad2bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/80 {
  --tw-shadow-color: #806ad2cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/90 {
  --tw-shadow-color: #806ad2e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/95 {
  --tw-shadow-color: #806ad2f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-buttonBorder\/100 {
  --tw-shadow-color: #806ad2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-headerText\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-borderGray\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/0 {
  --tw-shadow-color: #e5e7eb00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/5 {
  --tw-shadow-color: #e5e7eb0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/10 {
  --tw-shadow-color: #e5e7eb1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/20 {
  --tw-shadow-color: #e5e7eb33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/25 {
  --tw-shadow-color: #e5e7eb40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/30 {
  --tw-shadow-color: #e5e7eb4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/40 {
  --tw-shadow-color: #e5e7eb66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/50 {
  --tw-shadow-color: #e5e7eb80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/60 {
  --tw-shadow-color: #e5e7eb99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/70 {
  --tw-shadow-color: #e5e7ebb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/75 {
  --tw-shadow-color: #e5e7ebbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/80 {
  --tw-shadow-color: #e5e7ebcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/90 {
  --tw-shadow-color: #e5e7ebe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/95 {
  --tw-shadow-color: #e5e7ebf2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminAssistantBorder\/100 {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/0 {
  --tw-shadow-color: #00a35200;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/5 {
  --tw-shadow-color: #00a3520d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/10 {
  --tw-shadow-color: #00a3521a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/20 {
  --tw-shadow-color: #00a35233;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/25 {
  --tw-shadow-color: #00a35240;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/30 {
  --tw-shadow-color: #00a3524d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/40 {
  --tw-shadow-color: #00a35266;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/50 {
  --tw-shadow-color: #00a35280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/60 {
  --tw-shadow-color: #00a35299;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/70 {
  --tw-shadow-color: #00a352b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/75 {
  --tw-shadow-color: #00a352bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/80 {
  --tw-shadow-color: #00a352cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/90 {
  --tw-shadow-color: #00a352e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/95 {
  --tw-shadow-color: #00a352f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-success\/100 {
  --tw-shadow-color: #00a352;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/0 {
  --tw-shadow-color: #eafaff00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/5 {
  --tw-shadow-color: #eafaff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/10 {
  --tw-shadow-color: #eafaff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/20 {
  --tw-shadow-color: #eafaff33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/25 {
  --tw-shadow-color: #eafaff40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/30 {
  --tw-shadow-color: #eafaff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/40 {
  --tw-shadow-color: #eafaff66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/50 {
  --tw-shadow-color: #eafaff80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/60 {
  --tw-shadow-color: #eafaff99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/70 {
  --tw-shadow-color: #eafaffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/75 {
  --tw-shadow-color: #eafaffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/80 {
  --tw-shadow-color: #eafaffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/90 {
  --tw-shadow-color: #eafaffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/95 {
  --tw-shadow-color: #eafafff2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-successBackground\/100 {
  --tw-shadow-color: #eafaff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/0 {
  --tw-shadow-color: #ea3b2d00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/5 {
  --tw-shadow-color: #ea3b2d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/10 {
  --tw-shadow-color: #ea3b2d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/20 {
  --tw-shadow-color: #ea3b2d33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/25 {
  --tw-shadow-color: #ea3b2d40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/30 {
  --tw-shadow-color: #ea3b2d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/40 {
  --tw-shadow-color: #ea3b2d66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/50 {
  --tw-shadow-color: #ea3b2d80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/60 {
  --tw-shadow-color: #ea3b2d99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/70 {
  --tw-shadow-color: #ea3b2db3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/75 {
  --tw-shadow-color: #ea3b2dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/80 {
  --tw-shadow-color: #ea3b2dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/90 {
  --tw-shadow-color: #ea3b2de6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/95 {
  --tw-shadow-color: #ea3b2df2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-error\/100 {
  --tw-shadow-color: #ea3b2d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/0 {
  --tw-shadow-color: #ffd0cd00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/5 {
  --tw-shadow-color: #ffd0cd0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/10 {
  --tw-shadow-color: #ffd0cd1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/20 {
  --tw-shadow-color: #ffd0cd33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/25 {
  --tw-shadow-color: #ffd0cd40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/30 {
  --tw-shadow-color: #ffd0cd4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/40 {
  --tw-shadow-color: #ffd0cd66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/50 {
  --tw-shadow-color: #ffd0cd80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/60 {
  --tw-shadow-color: #ffd0cd99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/70 {
  --tw-shadow-color: #ffd0cdb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/75 {
  --tw-shadow-color: #ffd0cdbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/80 {
  --tw-shadow-color: #ffd0cdcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/90 {
  --tw-shadow-color: #ffd0cde6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/95 {
  --tw-shadow-color: #ffd0cdf2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-errorBackground\/100 {
  --tw-shadow-color: #ffd0cd;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/0 {
  --tw-shadow-color: #ffb72e00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/5 {
  --tw-shadow-color: #ffb72e0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/10 {
  --tw-shadow-color: #ffb72e1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/20 {
  --tw-shadow-color: #ffb72e33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/25 {
  --tw-shadow-color: #ffb72e40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/30 {
  --tw-shadow-color: #ffb72e4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/40 {
  --tw-shadow-color: #ffb72e66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/50 {
  --tw-shadow-color: #ffb72e80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/60 {
  --tw-shadow-color: #ffb72e99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/70 {
  --tw-shadow-color: #ffb72eb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/75 {
  --tw-shadow-color: #ffb72ebf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/80 {
  --tw-shadow-color: #ffb72ecc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/90 {
  --tw-shadow-color: #ffb72ee6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/95 {
  --tw-shadow-color: #ffb72ef2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warning\/100 {
  --tw-shadow-color: #ffb72e;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/0 {
  --tw-shadow-color: #fff9ed00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/5 {
  --tw-shadow-color: #fff9ed0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/10 {
  --tw-shadow-color: #fff9ed1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/20 {
  --tw-shadow-color: #fff9ed33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/25 {
  --tw-shadow-color: #fff9ed40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/30 {
  --tw-shadow-color: #fff9ed4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/40 {
  --tw-shadow-color: #fff9ed66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/50 {
  --tw-shadow-color: #fff9ed80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/60 {
  --tw-shadow-color: #fff9ed99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/70 {
  --tw-shadow-color: #fff9edb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/75 {
  --tw-shadow-color: #fff9edbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/80 {
  --tw-shadow-color: #fff9edcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/90 {
  --tw-shadow-color: #fff9ede6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/95 {
  --tw-shadow-color: #fff9edf2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-warningBackground\/100 {
  --tw-shadow-color: #fff9ed;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/0 {
  --tw-shadow-color: #2ec7ff00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/5 {
  --tw-shadow-color: #2ec7ff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/10 {
  --tw-shadow-color: #2ec7ff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/20 {
  --tw-shadow-color: #2ec7ff33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/25 {
  --tw-shadow-color: #2ec7ff40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/30 {
  --tw-shadow-color: #2ec7ff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/40 {
  --tw-shadow-color: #2ec7ff66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/50 {
  --tw-shadow-color: #2ec7ff80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/60 {
  --tw-shadow-color: #2ec7ff99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/70 {
  --tw-shadow-color: #2ec7ffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/75 {
  --tw-shadow-color: #2ec7ffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/80 {
  --tw-shadow-color: #2ec7ffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/90 {
  --tw-shadow-color: #2ec7ffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/95 {
  --tw-shadow-color: #2ec7fff2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-info\/100 {
  --tw-shadow-color: #2ec7ff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/0 {
  --tw-shadow-color: #eafaff00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/5 {
  --tw-shadow-color: #eafaff0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/10 {
  --tw-shadow-color: #eafaff1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/20 {
  --tw-shadow-color: #eafaff33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/25 {
  --tw-shadow-color: #eafaff40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/30 {
  --tw-shadow-color: #eafaff4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/40 {
  --tw-shadow-color: #eafaff66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/50 {
  --tw-shadow-color: #eafaff80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/60 {
  --tw-shadow-color: #eafaff99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/70 {
  --tw-shadow-color: #eafaffb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/75 {
  --tw-shadow-color: #eafaffbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/80 {
  --tw-shadow-color: #eafaffcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/90 {
  --tw-shadow-color: #eafaffe6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/95 {
  --tw-shadow-color: #eafafff2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-infoBackground\/100 {
  --tw-shadow-color: #eafaff;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/0 {
  --tw-shadow-color: #eee0;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/5 {
  --tw-shadow-color: #eeeeee0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/10 {
  --tw-shadow-color: #eeeeee1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/20 {
  --tw-shadow-color: #eee3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/25 {
  --tw-shadow-color: #eeeeee40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/30 {
  --tw-shadow-color: #eeeeee4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/40 {
  --tw-shadow-color: #eee6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/50 {
  --tw-shadow-color: #eeeeee80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/60 {
  --tw-shadow-color: #eee9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/70 {
  --tw-shadow-color: #eeeeeeb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/75 {
  --tw-shadow-color: #eeeeeebf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/80 {
  --tw-shadow-color: #eeec;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/90 {
  --tw-shadow-color: #eeeeeee6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/95 {
  --tw-shadow-color: #eeeeeef2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-tableGrey\/100 {
  --tw-shadow-color: #eee;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/0 {
  --tw-shadow-color: #ff9fa900;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/5 {
  --tw-shadow-color: #ff9fa90d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/10 {
  --tw-shadow-color: #ff9fa91a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/20 {
  --tw-shadow-color: #ff9fa933;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/25 {
  --tw-shadow-color: #ff9fa940;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/30 {
  --tw-shadow-color: #ff9fa94d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/40 {
  --tw-shadow-color: #ff9fa966;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/50 {
  --tw-shadow-color: #ff9fa980;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/60 {
  --tw-shadow-color: #ff9fa999;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/70 {
  --tw-shadow-color: #ff9fa9b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/75 {
  --tw-shadow-color: #ff9fa9bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/80 {
  --tw-shadow-color: #ff9fa9cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/90 {
  --tw-shadow-color: #ff9fa9e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/95 {
  --tw-shadow-color: #ff9fa9f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-nftBg\/100 {
  --tw-shadow-color: #ff9fa9;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/0 {
  --tw-shadow-color: #f1f1f100;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/5 {
  --tw-shadow-color: #f1f1f10d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/10 {
  --tw-shadow-color: #f1f1f11a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/20 {
  --tw-shadow-color: #f1f1f133;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/25 {
  --tw-shadow-color: #f1f1f140;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/30 {
  --tw-shadow-color: #f1f1f14d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/40 {
  --tw-shadow-color: #f1f1f166;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/50 {
  --tw-shadow-color: #f1f1f180;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/60 {
  --tw-shadow-color: #f1f1f199;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/70 {
  --tw-shadow-color: #f1f1f1b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/75 {
  --tw-shadow-color: #f1f1f1bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/80 {
  --tw-shadow-color: #f1f1f1cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/90 {
  --tw-shadow-color: #f1f1f1e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/95 {
  --tw-shadow-color: #f1f1f1f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-commentBg\/100 {
  --tw-shadow-color: #f1f1f1;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/0 {
  --tw-shadow-color: #e8735a00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/5 {
  --tw-shadow-color: #e8735a0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/10 {
  --tw-shadow-color: #e8735a1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/20 {
  --tw-shadow-color: #e8735a33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/25 {
  --tw-shadow-color: #e8735a40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/30 {
  --tw-shadow-color: #e8735a4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/40 {
  --tw-shadow-color: #e8735a66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/50 {
  --tw-shadow-color: #e8735a80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/60 {
  --tw-shadow-color: #e8735a99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/70 {
  --tw-shadow-color: #e8735ab3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/75 {
  --tw-shadow-color: #e8735abf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/80 {
  --tw-shadow-color: #e8735acc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/90 {
  --tw-shadow-color: #e8735ae6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/95 {
  --tw-shadow-color: #e8735af2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-confirmButton\/100 {
  --tw-shadow-color: #e8735a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/0 {
  --tw-shadow-color: #0000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/5 {
  --tw-shadow-color: #0000000d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/10 {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/20 {
  --tw-shadow-color: #0003;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/25 {
  --tw-shadow-color: #00000040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/30 {
  --tw-shadow-color: #0000004d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/40 {
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/50 {
  --tw-shadow-color: #00000080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/60 {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/70 {
  --tw-shadow-color: #000000b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/75 {
  --tw-shadow-color: #000000bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/80 {
  --tw-shadow-color: #000c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/90 {
  --tw-shadow-color: #000000e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/95 {
  --tw-shadow-color: #000000f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-taskDivider\/100 {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/0 {
  --tw-shadow-color: #f9731600;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/5 {
  --tw-shadow-color: #f973160d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/10 {
  --tw-shadow-color: #f973161a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/20 {
  --tw-shadow-color: #f9731633;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/25 {
  --tw-shadow-color: #f9731640;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/30 {
  --tw-shadow-color: #f973164d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/40 {
  --tw-shadow-color: #f9731666;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/50 {
  --tw-shadow-color: #f9731680;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/60 {
  --tw-shadow-color: #f9731699;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/70 {
  --tw-shadow-color: #f97316b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/75 {
  --tw-shadow-color: #f97316bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/80 {
  --tw-shadow-color: #f97316cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/90 {
  --tw-shadow-color: #f97316e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/95 {
  --tw-shadow-color: #f97316f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-ping\/100 {
  --tw-shadow-color: #f97316;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/0 {
  --tw-shadow-color: #fb923c00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/5 {
  --tw-shadow-color: #fb923c0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/10 {
  --tw-shadow-color: #fb923c1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/20 {
  --tw-shadow-color: #fb923c33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/25 {
  --tw-shadow-color: #fb923c40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/30 {
  --tw-shadow-color: #fb923c4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/40 {
  --tw-shadow-color: #fb923c66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/50 {
  --tw-shadow-color: #fb923c80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/60 {
  --tw-shadow-color: #fb923c99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/70 {
  --tw-shadow-color: #fb923cb3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/75 {
  --tw-shadow-color: #fb923cbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/80 {
  --tw-shadow-color: #fb923ccc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/90 {
  --tw-shadow-color: #fb923ce6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/95 {
  --tw-shadow-color: #fb923cf2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-pingEffect\/100 {
  --tw-shadow-color: #fb923c;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/0 {
  --tw-shadow-color: #e0848d00;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/5 {
  --tw-shadow-color: #e0848d0d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/10 {
  --tw-shadow-color: #e0848d1a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/20 {
  --tw-shadow-color: #e0848d33;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/25 {
  --tw-shadow-color: #e0848d40;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/30 {
  --tw-shadow-color: #e0848d4d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/40 {
  --tw-shadow-color: #e0848d66;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/50 {
  --tw-shadow-color: #e0848d80;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/60 {
  --tw-shadow-color: #e0848d99;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/70 {
  --tw-shadow-color: #e0848db3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/75 {
  --tw-shadow-color: #e0848dbf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/80 {
  --tw-shadow-color: #e0848dcc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/90 {
  --tw-shadow-color: #e0848de6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/95 {
  --tw-shadow-color: #e0848df2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-onboardingButtonColor\/100 {
  --tw-shadow-color: #e0848d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/0 {
  --tw-shadow-color: #6b728000;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/5 {
  --tw-shadow-color: #6b72800d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/10 {
  --tw-shadow-color: #6b72801a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/20 {
  --tw-shadow-color: #6b728033;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/25 {
  --tw-shadow-color: #6b728040;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/30 {
  --tw-shadow-color: #6b72804d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/40 {
  --tw-shadow-color: #6b728066;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/50 {
  --tw-shadow-color: #6b728080;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/60 {
  --tw-shadow-color: #6b728099;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/70 {
  --tw-shadow-color: #6b7280b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/75 {
  --tw-shadow-color: #6b7280bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/80 {
  --tw-shadow-color: #6b7280cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/90 {
  --tw-shadow-color: #6b7280e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/95 {
  --tw-shadow-color: #6b7280f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableHead\/100 {
  --tw-shadow-color: #6b7280;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/0 {
  --tw-shadow-color: #11182700;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/5 {
  --tw-shadow-color: #1118270d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/10 {
  --tw-shadow-color: #1118271a;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/20 {
  --tw-shadow-color: #11182733;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/25 {
  --tw-shadow-color: #11182740;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/30 {
  --tw-shadow-color: #1118274d;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/40 {
  --tw-shadow-color: #11182766;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/50 {
  --tw-shadow-color: #11182780;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/60 {
  --tw-shadow-color: #11182799;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/70 {
  --tw-shadow-color: #111827b3;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/75 {
  --tw-shadow-color: #111827bf;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/80 {
  --tw-shadow-color: #111827cc;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/90 {
  --tw-shadow-color: #111827e6;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/95 {
  --tw-shadow-color: #111827f2;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .shadow-adminTableBody\/100 {
  --tw-shadow-color: #111827;
  --tw-shadow: var(--tw-shadow-colored);
}

#app .outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

#app .outline {
  outline-style: solid;
}

#app .outline-success {
  outline-color: #00a352;
}

#app .outline-successBackground {
  outline-color: #eafaff;
}

#app .outline-error {
  outline-color: #ea3b2d;
}

#app .outline-errorBackground {
  outline-color: #ffd0cd;
}

#app .outline-info {
  outline-color: #2ec7ff;
}

#app .outline-infoBackground {
  outline-color: #eafaff;
}

#app .outline-success\/0 {
  outline-color: #00a35200;
}

#app .outline-success\/5 {
  outline-color: #00a3520d;
}

#app .outline-success\/10 {
  outline-color: #00a3521a;
}

#app .outline-success\/20 {
  outline-color: #00a35233;
}

#app .outline-success\/25 {
  outline-color: #00a35240;
}

#app .outline-success\/30 {
  outline-color: #00a3524d;
}

#app .outline-success\/40 {
  outline-color: #00a35266;
}

#app .outline-success\/50 {
  outline-color: #00a35280;
}

#app .outline-success\/60 {
  outline-color: #00a35299;
}

#app .outline-success\/70 {
  outline-color: #00a352b3;
}

#app .outline-success\/75 {
  outline-color: #00a352bf;
}

#app .outline-success\/80 {
  outline-color: #00a352cc;
}

#app .outline-success\/90 {
  outline-color: #00a352e6;
}

#app .outline-success\/95 {
  outline-color: #00a352f2;
}

#app .outline-success\/100 {
  outline-color: #00a352;
}

#app .outline-successBackground\/0 {
  outline-color: #eafaff00;
}

#app .outline-successBackground\/5 {
  outline-color: #eafaff0d;
}

#app .outline-successBackground\/10 {
  outline-color: #eafaff1a;
}

#app .outline-successBackground\/20 {
  outline-color: #eafaff33;
}

#app .outline-successBackground\/25 {
  outline-color: #eafaff40;
}

#app .outline-successBackground\/30 {
  outline-color: #eafaff4d;
}

#app .outline-successBackground\/40 {
  outline-color: #eafaff66;
}

#app .outline-successBackground\/50 {
  outline-color: #eafaff80;
}

#app .outline-successBackground\/60 {
  outline-color: #eafaff99;
}

#app .outline-successBackground\/70 {
  outline-color: #eafaffb3;
}

#app .outline-successBackground\/75 {
  outline-color: #eafaffbf;
}

#app .outline-successBackground\/80 {
  outline-color: #eafaffcc;
}

#app .outline-successBackground\/90 {
  outline-color: #eafaffe6;
}

#app .outline-successBackground\/95 {
  outline-color: #eafafff2;
}

#app .outline-successBackground\/100 {
  outline-color: #eafaff;
}

#app .outline-error\/0 {
  outline-color: #ea3b2d00;
}

#app .outline-error\/5 {
  outline-color: #ea3b2d0d;
}

#app .outline-error\/10 {
  outline-color: #ea3b2d1a;
}

#app .outline-error\/20 {
  outline-color: #ea3b2d33;
}

#app .outline-error\/25 {
  outline-color: #ea3b2d40;
}

#app .outline-error\/30 {
  outline-color: #ea3b2d4d;
}

#app .outline-error\/40 {
  outline-color: #ea3b2d66;
}

#app .outline-error\/50 {
  outline-color: #ea3b2d80;
}

#app .outline-error\/60 {
  outline-color: #ea3b2d99;
}

#app .outline-error\/70 {
  outline-color: #ea3b2db3;
}

#app .outline-error\/75 {
  outline-color: #ea3b2dbf;
}

#app .outline-error\/80 {
  outline-color: #ea3b2dcc;
}

#app .outline-error\/90 {
  outline-color: #ea3b2de6;
}

#app .outline-error\/95 {
  outline-color: #ea3b2df2;
}

#app .outline-error\/100 {
  outline-color: #ea3b2d;
}

#app .outline-errorBackground\/0 {
  outline-color: #ffd0cd00;
}

#app .outline-errorBackground\/5 {
  outline-color: #ffd0cd0d;
}

#app .outline-errorBackground\/10 {
  outline-color: #ffd0cd1a;
}

#app .outline-errorBackground\/20 {
  outline-color: #ffd0cd33;
}

#app .outline-errorBackground\/25 {
  outline-color: #ffd0cd40;
}

#app .outline-errorBackground\/30 {
  outline-color: #ffd0cd4d;
}

#app .outline-errorBackground\/40 {
  outline-color: #ffd0cd66;
}

#app .outline-errorBackground\/50 {
  outline-color: #ffd0cd80;
}

#app .outline-errorBackground\/60 {
  outline-color: #ffd0cd99;
}

#app .outline-errorBackground\/70 {
  outline-color: #ffd0cdb3;
}

#app .outline-errorBackground\/75 {
  outline-color: #ffd0cdbf;
}

#app .outline-errorBackground\/80 {
  outline-color: #ffd0cdcc;
}

#app .outline-errorBackground\/90 {
  outline-color: #ffd0cde6;
}

#app .outline-errorBackground\/95 {
  outline-color: #ffd0cdf2;
}

#app .outline-errorBackground\/100 {
  outline-color: #ffd0cd;
}

#app .outline-info\/0 {
  outline-color: #2ec7ff00;
}

#app .outline-info\/5 {
  outline-color: #2ec7ff0d;
}

#app .outline-info\/10 {
  outline-color: #2ec7ff1a;
}

#app .outline-info\/20 {
  outline-color: #2ec7ff33;
}

#app .outline-info\/25 {
  outline-color: #2ec7ff40;
}

#app .outline-info\/30 {
  outline-color: #2ec7ff4d;
}

#app .outline-info\/40 {
  outline-color: #2ec7ff66;
}

#app .outline-info\/50 {
  outline-color: #2ec7ff80;
}

#app .outline-info\/60 {
  outline-color: #2ec7ff99;
}

#app .outline-info\/70 {
  outline-color: #2ec7ffb3;
}

#app .outline-info\/75 {
  outline-color: #2ec7ffbf;
}

#app .outline-info\/80 {
  outline-color: #2ec7ffcc;
}

#app .outline-info\/90 {
  outline-color: #2ec7ffe6;
}

#app .outline-info\/95 {
  outline-color: #2ec7fff2;
}

#app .outline-info\/100 {
  outline-color: #2ec7ff;
}

#app .outline-infoBackground\/0 {
  outline-color: #eafaff00;
}

#app .outline-infoBackground\/5 {
  outline-color: #eafaff0d;
}

#app .outline-infoBackground\/10 {
  outline-color: #eafaff1a;
}

#app .outline-infoBackground\/20 {
  outline-color: #eafaff33;
}

#app .outline-infoBackground\/25 {
  outline-color: #eafaff40;
}

#app .outline-infoBackground\/30 {
  outline-color: #eafaff4d;
}

#app .outline-infoBackground\/40 {
  outline-color: #eafaff66;
}

#app .outline-infoBackground\/50 {
  outline-color: #eafaff80;
}

#app .outline-infoBackground\/60 {
  outline-color: #eafaff99;
}

#app .outline-infoBackground\/70 {
  outline-color: #eafaffb3;
}

#app .outline-infoBackground\/75 {
  outline-color: #eafaffbf;
}

#app .outline-infoBackground\/80 {
  outline-color: #eafaffcc;
}

#app .outline-infoBackground\/90 {
  outline-color: #eafaffe6;
}

#app .outline-infoBackground\/95 {
  outline-color: #eafafff2;
}

#app .outline-infoBackground\/100 {
  outline-color: #eafaff;
}

#app .ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .ring-success {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 163 82 / var(--tw-ring-opacity));
}

#app .ring-successBackground {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 250 255 / var(--tw-ring-opacity));
}

#app .ring-error {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 59 45 / var(--tw-ring-opacity));
}

#app .ring-errorBackground {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 208 205 / var(--tw-ring-opacity));
}

#app .ring-info {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(46 199 255 / var(--tw-ring-opacity));
}

#app .ring-infoBackground {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 250 255 / var(--tw-ring-opacity));
}

#app .ring-success\/0 {
  --tw-ring-color: #00a35200;
}

#app .ring-success\/5 {
  --tw-ring-color: #00a3520d;
}

#app .ring-success\/10 {
  --tw-ring-color: #00a3521a;
}

#app .ring-success\/20 {
  --tw-ring-color: #00a35233;
}

#app .ring-success\/25 {
  --tw-ring-color: #00a35240;
}

#app .ring-success\/30 {
  --tw-ring-color: #00a3524d;
}

#app .ring-success\/40 {
  --tw-ring-color: #00a35266;
}

#app .ring-success\/50 {
  --tw-ring-color: #00a35280;
}

#app .ring-success\/60 {
  --tw-ring-color: #00a35299;
}

#app .ring-success\/70 {
  --tw-ring-color: #00a352b3;
}

#app .ring-success\/75 {
  --tw-ring-color: #00a352bf;
}

#app .ring-success\/80 {
  --tw-ring-color: #00a352cc;
}

#app .ring-success\/90 {
  --tw-ring-color: #00a352e6;
}

#app .ring-success\/95 {
  --tw-ring-color: #00a352f2;
}

#app .ring-success\/100 {
  --tw-ring-color: #00a352;
}

#app .ring-successBackground\/0 {
  --tw-ring-color: #eafaff00;
}

#app .ring-successBackground\/5 {
  --tw-ring-color: #eafaff0d;
}

#app .ring-successBackground\/10 {
  --tw-ring-color: #eafaff1a;
}

#app .ring-successBackground\/20 {
  --tw-ring-color: #eafaff33;
}

#app .ring-successBackground\/25 {
  --tw-ring-color: #eafaff40;
}

#app .ring-successBackground\/30 {
  --tw-ring-color: #eafaff4d;
}

#app .ring-successBackground\/40 {
  --tw-ring-color: #eafaff66;
}

#app .ring-successBackground\/50 {
  --tw-ring-color: #eafaff80;
}

#app .ring-successBackground\/60 {
  --tw-ring-color: #eafaff99;
}

#app .ring-successBackground\/70 {
  --tw-ring-color: #eafaffb3;
}

#app .ring-successBackground\/75 {
  --tw-ring-color: #eafaffbf;
}

#app .ring-successBackground\/80 {
  --tw-ring-color: #eafaffcc;
}

#app .ring-successBackground\/90 {
  --tw-ring-color: #eafaffe6;
}

#app .ring-successBackground\/95 {
  --tw-ring-color: #eafafff2;
}

#app .ring-successBackground\/100 {
  --tw-ring-color: #eafaff;
}

#app .ring-error\/0 {
  --tw-ring-color: #ea3b2d00;
}

#app .ring-error\/5 {
  --tw-ring-color: #ea3b2d0d;
}

#app .ring-error\/10 {
  --tw-ring-color: #ea3b2d1a;
}

#app .ring-error\/20 {
  --tw-ring-color: #ea3b2d33;
}

#app .ring-error\/25 {
  --tw-ring-color: #ea3b2d40;
}

#app .ring-error\/30 {
  --tw-ring-color: #ea3b2d4d;
}

#app .ring-error\/40 {
  --tw-ring-color: #ea3b2d66;
}

#app .ring-error\/50 {
  --tw-ring-color: #ea3b2d80;
}

#app .ring-error\/60 {
  --tw-ring-color: #ea3b2d99;
}

#app .ring-error\/70 {
  --tw-ring-color: #ea3b2db3;
}

#app .ring-error\/75 {
  --tw-ring-color: #ea3b2dbf;
}

#app .ring-error\/80 {
  --tw-ring-color: #ea3b2dcc;
}

#app .ring-error\/90 {
  --tw-ring-color: #ea3b2de6;
}

#app .ring-error\/95 {
  --tw-ring-color: #ea3b2df2;
}

#app .ring-error\/100 {
  --tw-ring-color: #ea3b2d;
}

#app .ring-errorBackground\/0 {
  --tw-ring-color: #ffd0cd00;
}

#app .ring-errorBackground\/5 {
  --tw-ring-color: #ffd0cd0d;
}

#app .ring-errorBackground\/10 {
  --tw-ring-color: #ffd0cd1a;
}

#app .ring-errorBackground\/20 {
  --tw-ring-color: #ffd0cd33;
}

#app .ring-errorBackground\/25 {
  --tw-ring-color: #ffd0cd40;
}

#app .ring-errorBackground\/30 {
  --tw-ring-color: #ffd0cd4d;
}

#app .ring-errorBackground\/40 {
  --tw-ring-color: #ffd0cd66;
}

#app .ring-errorBackground\/50 {
  --tw-ring-color: #ffd0cd80;
}

#app .ring-errorBackground\/60 {
  --tw-ring-color: #ffd0cd99;
}

#app .ring-errorBackground\/70 {
  --tw-ring-color: #ffd0cdb3;
}

#app .ring-errorBackground\/75 {
  --tw-ring-color: #ffd0cdbf;
}

#app .ring-errorBackground\/80 {
  --tw-ring-color: #ffd0cdcc;
}

#app .ring-errorBackground\/90 {
  --tw-ring-color: #ffd0cde6;
}

#app .ring-errorBackground\/95 {
  --tw-ring-color: #ffd0cdf2;
}

#app .ring-errorBackground\/100 {
  --tw-ring-color: #ffd0cd;
}

#app .ring-info\/0 {
  --tw-ring-color: #2ec7ff00;
}

#app .ring-info\/5 {
  --tw-ring-color: #2ec7ff0d;
}

#app .ring-info\/10 {
  --tw-ring-color: #2ec7ff1a;
}

#app .ring-info\/20 {
  --tw-ring-color: #2ec7ff33;
}

#app .ring-info\/25 {
  --tw-ring-color: #2ec7ff40;
}

#app .ring-info\/30 {
  --tw-ring-color: #2ec7ff4d;
}

#app .ring-info\/40 {
  --tw-ring-color: #2ec7ff66;
}

#app .ring-info\/50 {
  --tw-ring-color: #2ec7ff80;
}

#app .ring-info\/60 {
  --tw-ring-color: #2ec7ff99;
}

#app .ring-info\/70 {
  --tw-ring-color: #2ec7ffb3;
}

#app .ring-info\/75 {
  --tw-ring-color: #2ec7ffbf;
}

#app .ring-info\/80 {
  --tw-ring-color: #2ec7ffcc;
}

#app .ring-info\/90 {
  --tw-ring-color: #2ec7ffe6;
}

#app .ring-info\/95 {
  --tw-ring-color: #2ec7fff2;
}

#app .ring-info\/100 {
  --tw-ring-color: #2ec7ff;
}

#app .ring-infoBackground\/0 {
  --tw-ring-color: #eafaff00;
}

#app .ring-infoBackground\/5 {
  --tw-ring-color: #eafaff0d;
}

#app .ring-infoBackground\/10 {
  --tw-ring-color: #eafaff1a;
}

#app .ring-infoBackground\/20 {
  --tw-ring-color: #eafaff33;
}

#app .ring-infoBackground\/25 {
  --tw-ring-color: #eafaff40;
}

#app .ring-infoBackground\/30 {
  --tw-ring-color: #eafaff4d;
}

#app .ring-infoBackground\/40 {
  --tw-ring-color: #eafaff66;
}

#app .ring-infoBackground\/50 {
  --tw-ring-color: #eafaff80;
}

#app .ring-infoBackground\/60 {
  --tw-ring-color: #eafaff99;
}

#app .ring-infoBackground\/70 {
  --tw-ring-color: #eafaffb3;
}

#app .ring-infoBackground\/75 {
  --tw-ring-color: #eafaffbf;
}

#app .ring-infoBackground\/80 {
  --tw-ring-color: #eafaffcc;
}

#app .ring-infoBackground\/90 {
  --tw-ring-color: #eafaffe6;
}

#app .ring-infoBackground\/95 {
  --tw-ring-color: #eafafff2;
}

#app .ring-infoBackground\/100 {
  --tw-ring-color: #eafaff;
}

#app .ring-pingEffect {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}

#app .ring-offset-success {
  --tw-ring-offset-color: #00a352;
}

#app .ring-offset-successBackground {
  --tw-ring-offset-color: #eafaff;
}

#app .ring-offset-error {
  --tw-ring-offset-color: #ea3b2d;
}

#app .ring-offset-errorBackground {
  --tw-ring-offset-color: #ffd0cd;
}

#app .ring-offset-info {
  --tw-ring-offset-color: #2ec7ff;
}

#app .ring-offset-infoBackground {
  --tw-ring-offset-color: #eafaff;
}

#app .ring-offset-success\/0 {
  --tw-ring-offset-color: #00a35200;
}

#app .ring-offset-success\/5 {
  --tw-ring-offset-color: #00a3520d;
}

#app .ring-offset-success\/10 {
  --tw-ring-offset-color: #00a3521a;
}

#app .ring-offset-success\/20 {
  --tw-ring-offset-color: #00a35233;
}

#app .ring-offset-success\/25 {
  --tw-ring-offset-color: #00a35240;
}

#app .ring-offset-success\/30 {
  --tw-ring-offset-color: #00a3524d;
}

#app .ring-offset-success\/40 {
  --tw-ring-offset-color: #00a35266;
}

#app .ring-offset-success\/50 {
  --tw-ring-offset-color: #00a35280;
}

#app .ring-offset-success\/60 {
  --tw-ring-offset-color: #00a35299;
}

#app .ring-offset-success\/70 {
  --tw-ring-offset-color: #00a352b3;
}

#app .ring-offset-success\/75 {
  --tw-ring-offset-color: #00a352bf;
}

#app .ring-offset-success\/80 {
  --tw-ring-offset-color: #00a352cc;
}

#app .ring-offset-success\/90 {
  --tw-ring-offset-color: #00a352e6;
}

#app .ring-offset-success\/95 {
  --tw-ring-offset-color: #00a352f2;
}

#app .ring-offset-success\/100 {
  --tw-ring-offset-color: #00a352;
}

#app .ring-offset-successBackground\/0 {
  --tw-ring-offset-color: #eafaff00;
}

#app .ring-offset-successBackground\/5 {
  --tw-ring-offset-color: #eafaff0d;
}

#app .ring-offset-successBackground\/10 {
  --tw-ring-offset-color: #eafaff1a;
}

#app .ring-offset-successBackground\/20 {
  --tw-ring-offset-color: #eafaff33;
}

#app .ring-offset-successBackground\/25 {
  --tw-ring-offset-color: #eafaff40;
}

#app .ring-offset-successBackground\/30 {
  --tw-ring-offset-color: #eafaff4d;
}

#app .ring-offset-successBackground\/40 {
  --tw-ring-offset-color: #eafaff66;
}

#app .ring-offset-successBackground\/50 {
  --tw-ring-offset-color: #eafaff80;
}

#app .ring-offset-successBackground\/60 {
  --tw-ring-offset-color: #eafaff99;
}

#app .ring-offset-successBackground\/70 {
  --tw-ring-offset-color: #eafaffb3;
}

#app .ring-offset-successBackground\/75 {
  --tw-ring-offset-color: #eafaffbf;
}

#app .ring-offset-successBackground\/80 {
  --tw-ring-offset-color: #eafaffcc;
}

#app .ring-offset-successBackground\/90 {
  --tw-ring-offset-color: #eafaffe6;
}

#app .ring-offset-successBackground\/95 {
  --tw-ring-offset-color: #eafafff2;
}

#app .ring-offset-successBackground\/100 {
  --tw-ring-offset-color: #eafaff;
}

#app .ring-offset-error\/0 {
  --tw-ring-offset-color: #ea3b2d00;
}

#app .ring-offset-error\/5 {
  --tw-ring-offset-color: #ea3b2d0d;
}

#app .ring-offset-error\/10 {
  --tw-ring-offset-color: #ea3b2d1a;
}

#app .ring-offset-error\/20 {
  --tw-ring-offset-color: #ea3b2d33;
}

#app .ring-offset-error\/25 {
  --tw-ring-offset-color: #ea3b2d40;
}

#app .ring-offset-error\/30 {
  --tw-ring-offset-color: #ea3b2d4d;
}

#app .ring-offset-error\/40 {
  --tw-ring-offset-color: #ea3b2d66;
}

#app .ring-offset-error\/50 {
  --tw-ring-offset-color: #ea3b2d80;
}

#app .ring-offset-error\/60 {
  --tw-ring-offset-color: #ea3b2d99;
}

#app .ring-offset-error\/70 {
  --tw-ring-offset-color: #ea3b2db3;
}

#app .ring-offset-error\/75 {
  --tw-ring-offset-color: #ea3b2dbf;
}

#app .ring-offset-error\/80 {
  --tw-ring-offset-color: #ea3b2dcc;
}

#app .ring-offset-error\/90 {
  --tw-ring-offset-color: #ea3b2de6;
}

#app .ring-offset-error\/95 {
  --tw-ring-offset-color: #ea3b2df2;
}

#app .ring-offset-error\/100 {
  --tw-ring-offset-color: #ea3b2d;
}

#app .ring-offset-errorBackground\/0 {
  --tw-ring-offset-color: #ffd0cd00;
}

#app .ring-offset-errorBackground\/5 {
  --tw-ring-offset-color: #ffd0cd0d;
}

#app .ring-offset-errorBackground\/10 {
  --tw-ring-offset-color: #ffd0cd1a;
}

#app .ring-offset-errorBackground\/20 {
  --tw-ring-offset-color: #ffd0cd33;
}

#app .ring-offset-errorBackground\/25 {
  --tw-ring-offset-color: #ffd0cd40;
}

#app .ring-offset-errorBackground\/30 {
  --tw-ring-offset-color: #ffd0cd4d;
}

#app .ring-offset-errorBackground\/40 {
  --tw-ring-offset-color: #ffd0cd66;
}

#app .ring-offset-errorBackground\/50 {
  --tw-ring-offset-color: #ffd0cd80;
}

#app .ring-offset-errorBackground\/60 {
  --tw-ring-offset-color: #ffd0cd99;
}

#app .ring-offset-errorBackground\/70 {
  --tw-ring-offset-color: #ffd0cdb3;
}

#app .ring-offset-errorBackground\/75 {
  --tw-ring-offset-color: #ffd0cdbf;
}

#app .ring-offset-errorBackground\/80 {
  --tw-ring-offset-color: #ffd0cdcc;
}

#app .ring-offset-errorBackground\/90 {
  --tw-ring-offset-color: #ffd0cde6;
}

#app .ring-offset-errorBackground\/95 {
  --tw-ring-offset-color: #ffd0cdf2;
}

#app .ring-offset-errorBackground\/100 {
  --tw-ring-offset-color: #ffd0cd;
}

#app .ring-offset-info\/0 {
  --tw-ring-offset-color: #2ec7ff00;
}

#app .ring-offset-info\/5 {
  --tw-ring-offset-color: #2ec7ff0d;
}

#app .ring-offset-info\/10 {
  --tw-ring-offset-color: #2ec7ff1a;
}

#app .ring-offset-info\/20 {
  --tw-ring-offset-color: #2ec7ff33;
}

#app .ring-offset-info\/25 {
  --tw-ring-offset-color: #2ec7ff40;
}

#app .ring-offset-info\/30 {
  --tw-ring-offset-color: #2ec7ff4d;
}

#app .ring-offset-info\/40 {
  --tw-ring-offset-color: #2ec7ff66;
}

#app .ring-offset-info\/50 {
  --tw-ring-offset-color: #2ec7ff80;
}

#app .ring-offset-info\/60 {
  --tw-ring-offset-color: #2ec7ff99;
}

#app .ring-offset-info\/70 {
  --tw-ring-offset-color: #2ec7ffb3;
}

#app .ring-offset-info\/75 {
  --tw-ring-offset-color: #2ec7ffbf;
}

#app .ring-offset-info\/80 {
  --tw-ring-offset-color: #2ec7ffcc;
}

#app .ring-offset-info\/90 {
  --tw-ring-offset-color: #2ec7ffe6;
}

#app .ring-offset-info\/95 {
  --tw-ring-offset-color: #2ec7fff2;
}

#app .ring-offset-info\/100 {
  --tw-ring-offset-color: #2ec7ff;
}

#app .ring-offset-infoBackground\/0 {
  --tw-ring-offset-color: #eafaff00;
}

#app .ring-offset-infoBackground\/5 {
  --tw-ring-offset-color: #eafaff0d;
}

#app .ring-offset-infoBackground\/10 {
  --tw-ring-offset-color: #eafaff1a;
}

#app .ring-offset-infoBackground\/20 {
  --tw-ring-offset-color: #eafaff33;
}

#app .ring-offset-infoBackground\/25 {
  --tw-ring-offset-color: #eafaff40;
}

#app .ring-offset-infoBackground\/30 {
  --tw-ring-offset-color: #eafaff4d;
}

#app .ring-offset-infoBackground\/40 {
  --tw-ring-offset-color: #eafaff66;
}

#app .ring-offset-infoBackground\/50 {
  --tw-ring-offset-color: #eafaff80;
}

#app .ring-offset-infoBackground\/60 {
  --tw-ring-offset-color: #eafaff99;
}

#app .ring-offset-infoBackground\/70 {
  --tw-ring-offset-color: #eafaffb3;
}

#app .ring-offset-infoBackground\/75 {
  --tw-ring-offset-color: #eafaffbf;
}

#app .ring-offset-infoBackground\/80 {
  --tw-ring-offset-color: #eafaffcc;
}

#app .ring-offset-infoBackground\/90 {
  --tw-ring-offset-color: #eafaffe6;
}

#app .ring-offset-infoBackground\/95 {
  --tw-ring-offset-color: #eafafff2;
}

#app .ring-offset-infoBackground\/100 {
  --tw-ring-offset-color: #eafaff;
}

#app .drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px #00000026);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow-\[0_4px_4px_rgba\(0\,0\,0\,0\.25\)\] {
  --tw-drop-shadow: drop-shadow(0 4px 4px #00000040);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#app .transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#app .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#app .transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#app .duration-300 {
  transition-duration: .3s;
}

#app .duration-700 {
  transition-duration: .7s;
}

#app .ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Gilroy;
  src: url("Gilroy-Light.9d6c3395.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url("Gilroy-ExtraBold.93f97143.otf");
  font-weight: bold;
  font-style: normal;
}

body {
  height: 100vh;
  background-color: #f5f5f5 !important;
  font-family: Inter, sans-serif !important;
}

html, #app {
  background-color: #f5f5f5 !important;
}

#app .hover\:rotate-6:hover {
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-\[1\.05\]:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-\[1\.02\]:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-\[1\.3\]:hover {
  --tw-scale-x: 1.3;
  --tw-scale-y: 1.3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-\[1\.15\]:hover {
  --tw-scale-x: 1.15;
  --tw-scale-y: 1.15;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:scale-\[1\.1\]:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#app .hover\:cursor-pointer:hover {
  cursor: pointer;
}

#app .hover\:rounded-t-\[5px\]:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#app .hover\:bg-\[\#dddddd\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

#app .hover\:text-secondary:hover {
  --tw-text-opacity: 1;
  color: rgb(156 39 176 / var(--tw-text-opacity));
}

#app .hover\:text-primaryButton:hover {
  --tw-text-opacity: 1;
  color: rgb(111 90 205 / var(--tw-text-opacity));
}

#app .hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

#app .hover\:opacity-90:hover {
  opacity: .9;
}

#app .hover\:opacity-80:hover {
  opacity: .8;
}

#app .hover\:opacity-50:hover {
  opacity: .5;
}

#app .hover\:opacity-70:hover {
  opacity: .7;
}

#app .hover\:opacity-75:hover {
  opacity: .75;
}

#app .hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#app .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

#app .focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#app .disabled\:cursor-default:disabled {
  cursor: default;
}

#app .disabled\:bg-\[grey\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

#app .disabled\:opacity-80:disabled {
  opacity: .8;
}

#app .group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

@media (prefers-color-scheme: dark) {
  #app .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (min-width: 480px) {
  #app .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  #app .sm\:mb-0 {
    margin-bottom: 0;
  }

  #app .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  #app .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  #app .sm\:ml-3 {
    margin-left: .75rem;
  }

  #app .sm\:mt-\[12px\] {
    margin-top: 12px;
  }

  #app .sm\:ml-0 {
    margin-left: 0;
  }

  #app .sm\:w-\[90\%\] {
    width: 90%;
  }

  #app .sm\:max-w-xl {
    max-width: 36rem;
  }

  #app .sm\:flex-row {
    flex-direction: row;
  }

  #app .sm\:self-start {
    align-self: flex-start;
  }

  #app .sm\:self-center {
    align-self: center;
  }

  #app .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #app .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  #app .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #app .sm\:pl-\[24px\] {
    padding-left: 24px;
  }

  #app .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #app .sm\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  #app .sm\:text-\[14px\] {
    font-size: 14px;
  }

  #app .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #app .sm\:text-\[12px\] {
    font-size: 12px;
  }

  #app .sm\:leading-\[3\.5rem\] {
    line-height: 3.5rem;
  }
}

@media (min-width: 600px) {
  #app .ax\:mb-0 {
    margin-bottom: 0;
  }

  #app .ax\:h-\[55px\] {
    height: 55px;
  }

  #app .ax\:w-28 {
    width: 7rem;
  }

  #app .ax\:w-32 {
    width: 8rem;
  }
}

@media (min-width: 768px) {
  #app .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  #app .md\:mr-6 {
    margin-right: 1.5rem;
  }

  #app .md\:mt-6 {
    margin-top: 1.5rem;
  }

  #app .md\:ml-2 {
    margin-left: .5rem;
  }

  #app .md\:flex {
    display: flex;
  }

  #app .md\:hidden {
    display: none;
  }

  #app .md\:h-\[45\] {
    height: 45px;
  }

  #app .md\:h-\[18\] {
    height: 18px;
  }

  #app .md\:h-\[180\] {
    height: 180px;
  }

  #app .md\:h-\[140\] {
    height: 140px;
  }

  #app .md\:w-\[64\%\] {
    width: 64%;
  }

  #app .md\:w-1\/5 {
    width: 20%;
  }

  #app .md\:w-20 {
    width: 5rem;
  }

  #app .md\:w-\[45\] {
    width: 45px;
  }

  #app .md\:w-\[18\] {
    width: 18px;
  }

  #app .md\:w-\[344\] {
    width: 344px;
  }

  #app .md\:w-\[200\] {
    width: 200px;
  }

  #app .md\:max-w-full {
    max-width: 100%;
  }

  #app .md\:max-w-sm {
    max-width: 24rem;
  }

  #app .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  #app .md\:flex-row {
    flex-direction: row;
  }

  #app .md\:items-center {
    align-items: center;
  }

  #app .md\:justify-start {
    justify-content: flex-start;
  }

  #app .md\:justify-end {
    justify-content: flex-end;
  }

  #app .md\:justify-center {
    justify-content: center;
  }

  #app .md\:gap-8 {
    gap: 2rem;
  }

  #app .md\:gap-6 {
    gap: 1.5rem;
  }

  #app .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  #app .md\:pl-\[24px\] {
    padding-left: 24px;
  }

  #app .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  #app .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #app .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (max-width: 768px) {
  #app .mdMax\:hidden {
    display: none;
  }

  #app .mdMax\:w-full {
    width: 100%;
  }

  #app .mdMax\:w-\[90\%\] {
    width: 90%;
  }

  #app .mdMax\:flex-col {
    flex-direction: column;
  }
}

@media (min-width: 900px) {
  #app .ac\:mb-16 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 976px) {
  #app .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  #app .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  #app .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  #app .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  #app .lg\:mt-0 {
    margin-top: 0;
  }

  #app .lg\:ml-20 {
    margin-left: 5rem;
  }

  #app .lg\:flex {
    display: flex;
  }

  #app .lg\:hidden {
    display: none;
  }

  #app .lg\:h-\[75px\] {
    height: 75px;
  }

  #app .lg\:w-full {
    width: 100%;
  }

  #app .lg\:w-\[850px\] {
    width: 850px;
  }

  #app .lg\:w-\[846px\] {
    width: 846px;
  }

  #app .lg\:max-w-screen-xl {
    max-width: 1440px;
  }

  #app .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #app .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  #app .lg\:gap-4 {
    gap: 1rem;
  }

  #app .lg\:gap-8 {
    gap: 2rem;
  }

  #app .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #app .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  #app .lg\:text-left {
    text-align: left;
  }

  #app .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  #app .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  #app .lg\:text-\[32px\] {
    font-size: 32px;
  }

  #app .lg\:text-\[14px\] {
    font-size: 14px;
  }
}

@media (min-width: 1080px) {
  #app .od\:order-5 {
    order: 5;
  }
}

@media (max-width: 1080px) {
  #app .odMax\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  #app .odMax\:hidden {
    display: none;
  }

  #app .odMax\:w-full {
    width: 100%;
  }

  #app .odMax\:min-w-\[100\%\] {
    min-width: 100%;
  }

  #app .odMax\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  #app .odMax\:px-\[2\%\] {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (min-width: 1440px) {
  #app .xl\:gap-0 {
    gap: 0;
  }

  #app .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  #app .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1564px) {
  #app .xxl\:bottom-1 {
    bottom: .25rem;
  }

  #app .xxlMax\:bottom-2 {
    bottom: .5rem;
  }
}

/*# sourceMappingURL=index.2e1905b4.css.map */
